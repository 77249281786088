import React from "react";

function AddProductUploadImg(props) {
  return (
    <svg
      width="344.6px"
      height="280px"
      viewBox="0 0 560 334"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <filter
          x="-19.6%"
          y="-55.4%"
          width="139.2%"
          height="210.7%"
          filterUnits="objectBoundingBox"
          id="filter-1"
        >
          <feGaussianBlur
            stdDeviation="25.168952"
            in="SourceGraphic"
          ></feGaussianBlur>
        </filter>
        <polyline id="path-2" points="18 16.8 0 16.8 0 0"></polyline>
      </defs>
      <g id="Blog" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Description-Copy-2" transform="translate(81.896126, 27.000000)">
          <g
            id="Group-189"
            transform="translate(27.406221, 0.000000)"
            fill="#E9F0F4"
          >
            <ellipse
              id="Oval-Copy"
              transform="translate(66.039863, 213.843164) rotate(11.000000) translate(-66.039863, -213.843164) "
              cx="66.0398634"
              cy="213.843164"
              rx="56.3306789"
              ry="56.4231828"
            ></ellipse>
            <ellipse
              id="Oval"
              transform="translate(215.939449, 128.422093) rotate(11.000000) translate(-215.939449, -128.422093) "
              cx="215.939449"
              cy="128.422093"
              rx="109.347788"
              ry="109.527355"
            ></ellipse>
          </g>
          <g
            id="Upload-Product-Images"
            transform="translate(198.103874, 139.887399) rotate(-5.000000) translate(-198.103874, -139.887399) translate(5.445119, 69.000000)"
          >
            <rect
              id="Rectangle-Copy-17"
              fill="#000000"
              opacity="0.057274"
              filter="url(#filter-1)"
              x="1.8189894e-12"
              y="2.66924043"
              width="385.31751"
              height="136.404541"
              rx="10.0675808"
            ></rect>
            <rect
              id="Rectangle-Copy-15"
              fill="#FFFFFF"
              x="16.1445884"
              y="-7.27595761e-12"
              width="353.028333"
              height="141.774799"
              rx="10.0675808"
            ></rect>
            <g id="Group-7-Copy" transform="translate(34.887228, 13.175463)">
              <g id="Group-2-Copy" transform="translate(0.000000, 36.000000)">
                <rect
                  id="Rectangle-11-Copy-5"
                  stroke="#EAEFEF"
                  fill="#FFFFFF"
                  x="0.5"
                  y="0.5"
                  width="89"
                  height="79"
                  rx="8"
                ></rect>
                <g
                  id="drag-n-drop-(1)-copy"
                  transform="translate(33.000000, 28.000000)"
                >
                  <rect
                    id="Rectangle-12"
                    x="0"
                    y="0"
                    width="24"
                    height="24"
                  ></rect>
                  <g
                    id="Rectangle-11"
                    strokeDasharray="0.9000000357627869,0.9000000357627869,0.9000000357627869,0.9000000357627869"
                    transform="translate(10.800000, 12.600000) rotate(0.000000) translate(-10.800000, -12.600000) translate(1.800000, 4.200000)"
                  >
                    <g id="path-1-Clipped">
                      <mask id="mask-3" fill="white">
                        <use xlinkHref="#path-2"></use>
                      </mask>
                      <g id="path-1"></g>
                      <path
                        d="M18,16.8 L0,16.8 L0,0"
                        id="path-1"
                        stroke="#787872"
                        strokeWidth="0.9"
                        fill="#FFFFFF"
                        fillRule="nonzero"
                        mask="url(#mask-3)"
                      ></path>
                    </g>
                  </g>
                  <g
                    id="Group-19"
                    transform="translate(11.700000, 11.948804) rotate(0.000000) translate(-11.700000, -11.948804) translate(3.600000, 4.800000)"
                  >
                    <g id="Rectangle">
                      <g id="path-3-link" fill="#FFFFFF">
                        <rect
                          id="path-3"
                          x="0"
                          y="0"
                          width="16.2"
                          height="14.2976083"
                        ></rect>
                      </g>
                      <rect
                        id="Rectangle-path"
                        stroke="#787872"
                        strokeWidth="0.5"
                        x="0.3"
                        y="0.3"
                        width="15.6"
                        height="13.6976083"
                      ></rect>
                    </g>
                    <path
                      d="M0.290480248,9.13458306 L3.98941595,4.74627467 L3.98941595,4.74627467 C4.20108814,4.49515302 4.57532448,4.4608836 4.82908508,4.66938483 L15.9954435,13.8441721"
                      id="Path-5"
                      stroke="#787872"
                      strokeWidth="0.5"
                    ></path>
                    <path
                      d="M8.47964766,7.94311572 L12.1535434,5.77310237 L12.1535434,5.77310237 C12.3701093,5.64518616 12.6434721,5.66592287 12.8382639,5.8250438 L15.9647509,8.37899838"
                      id="Path-6"
                      stroke="#787872"
                      strokeWidth="0.5"
                    ></path>
                    <circle
                      id="Oval-2"
                      fill="#4AB819"
                      fillRule="nonzero"
                      cx="8.1"
                      cy="3.9"
                      r="1"
                    ></circle>
                  </g>
                  <g
                    id="Group"
                    transform="translate(15.000000, 1.800000)"
                    fillRule="nonzero"
                  >
                    <circle
                      id="Oval-7"
                      stroke="#FFFFFF"
                      strokeWidth="0.5"
                      fill="#4AB819"
                      cx="3.9"
                      cy="3.9"
                      r="3.9"
                    ></circle>
                    <polygon
                      id="+"
                      fill="#FFFFFF"
                      points="3.46993958 4.33943662 1.95 4.33943662 1.95 3.48018109 3.46993958 3.48018109 3.46993958 1.95 4.33006042 1.95 4.33006042 3.48018109 5.85 3.48018109 5.85 4.33943662 4.33006042 4.33943662 4.33006042 5.85 3.46993958 5.85"
                    ></polygon>
                  </g>
                </g>
              </g>
              <text
                id="Upload-Product-Image"
                fontFamily="OpenSans-SemiBold, Open Sans"
                fontSize="14"
                fontWeight="500"
                letterSpacing="-0.186666662"
                fill="#181912"
              >
                <tspan x="0" y="15">
                  Upload Product Images
                </tspan>
              </text>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default AddProductUploadImg;
