import React from "react";

function AddProductName(props) {
  return (
    <svg
      width="344.6px"
      height="280px"
      viewBox="0 0 557 321"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <filter
          x="-20.2%"
          y="-57.3%"
          width="140.4%"
          height="214.5%"
          filterUnits="objectBoundingBox"
          id="filter-1"
        >
          <feGaussianBlur stdDeviation="26" in="SourceGraphic"></feGaussianBlur>
        </filter>
      </defs>
      <g id="Blog" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group-22" transform="translate(82.000000, 40.000000)">
          <g
            id="Group-189"
            transform="translate(24.571915, 0.000000)"
            fill="#E9F0F4"
          >
            <ellipse
              id="Oval-Copy"
              transform="translate(66.129175, 213.843164) rotate(11.000000) translate(-66.129175, -213.843164) "
              cx="66.129175"
              cy="213.843164"
              rx="56.4040784"
              ry="56.4259531"
            ></ellipse>
            <ellipse
              id="Oval"
              transform="translate(216.231483, 128.422093) rotate(11.000000) translate(-216.231483, -128.422093) "
              cx="216.231483"
              cy="128.422093"
              rx="109.49027"
              ry="109.532732"
            ></ellipse>
          </g>
          <g
            id="Group-21"
            transform="translate(196.500000, 125.500000) rotate(3.000000) translate(-196.500000, -125.500000) translate(3.580695, 52.000000)"
          >
            <rect
              id="Rectangle-Copy-10"
              fill="#000000"
              opacity="0.057274"
              filter="url(#filter-1)"
              x="-3.40917912e-13"
              y="3.63797881e-12"
              width="385.83861"
              height="136.234768"
              rx="10.4"
            ></rect>
            <rect
              id="Rectangle-Copy-10"
              fill="#FFFFFF"
              x="16.1664222"
              y="10.7652317"
              width="353.505765"
              height="136.234768"
              rx="10.4"
            ></rect>
            <path
              d="M348.268004,87.4479762 C349.273162,87.4479762 350.183162,87.855397 350.841872,88.5141075 C351.500583,89.1728179 351.908004,90.0828179 351.908004,91.0879762 L351.908004,91.0879762 L351.908004,125.676565 C351.908004,126.681723 351.500583,127.591723 350.841872,128.250433 C350.183162,128.909144 349.273162,129.316565 348.268004,129.316565 L348.268004,129.316565 L37.5706059,129.316565 C36.5654476,129.316565 35.6554476,128.909144 34.9967372,128.250433 C34.3380267,127.591723 33.9306059,126.681723 33.9306059,125.676565 L33.9306059,125.676565 L33.9306059,91.0879762 C33.9306059,90.0828179 34.3380267,89.1728179 34.9967372,88.5141075 C35.6554476,87.855397 36.5654476,87.4479762 37.5706059,87.4479762 L37.5706059,87.4479762 Z"
              id="Rectangle-7-Copy-8"
              stroke="#4AB819"
              strokeWidth="1.04"
              fill="#FFFFFF"
            ></path>
            <text
              id="Men’s-Running-Shoes"
              fontFamily="Cabin-Regular, Cabin"
              fontSize="12.48"
              fontWeight="normal"
              fill="#181912"
            >
              <tspan x="51.6302985" y="110.951871">
                Men’s Running Shoes
              </tspan>
            </text>
            <text
              id="Name-Copy-2"
              fontFamily="OpenSans-SemiBold, Open Sans"
              fontSize="11.44"
              fontWeight="500"
              letterSpacing="-0.152533329"
              fill="#B8B8B5"
            >
              <tspan x="34.0241504" y="77.7197967">
                NAME
              </tspan>
            </text>
            <text
              id="Product-Details-Copy-2"
              fontFamily="OpenSans-SemiBold, Open Sans"
              fontSize="14.56"
              fontWeight="500"
              letterSpacing="-0.194133326"
              fill="#181912"
            >
              <tspan x="35.3222509" y="44.2347387">
                Product Details
              </tspan>
            </text>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default AddProductName;
