import React from "react";

function AddProductCategory(props) {
  return (
    <svg
      width="344.6px"
      height="280px"
      viewBox="0 0 547 339"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <filter
          x="-20.3%"
          y="-48.7%"
          width="140.5%"
          height="197.4%"
          filterUnits="objectBoundingBox"
          id="filter-1"
        >
          <feGaussianBlur
            stdDeviation="25.4969784"
            in="SourceGraphic"
          ></feGaussianBlur>
        </filter>
      </defs>
      <g id="Blog" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group-25" transform="translate(81.000000, 29.000000)">
          <g
            id="Group-189"
            transform="translate(24.804339, 0.000000)"
            fill="#E9F0F4"
          >
            <ellipse
              id="Oval-Copy"
              transform="translate(66.036990, 213.843164) rotate(11.000000) translate(-66.036990, -213.843164) "
              cx="66.03699"
              cy="213.843164"
              rx="56.3283175"
              ry="56.4230937"
            ></ellipse>
            <ellipse
              id="Oval"
              transform="translate(215.930054, 128.422093) rotate(11.000000) translate(-215.930054, -128.422093) "
              cx="215.930054"
              cy="128.422093"
              rx="109.343204"
              ry="109.527182"
            ></ellipse>
          </g>
          <g
            id="Group-22"
            transform="translate(192.500000, 140.500000) rotate(-3.000000) translate(-192.500000, -140.500000) translate(3.842381, 62.000000)"
          >
            <rect
              id="Rectangle-Copy-16"
              fill="#000000"
              opacity="0.057274"
              filter="url(#filter-1)"
              x="0"
              y="0"
              width="377.315237"
              height="157"
              rx="10.1987914"
            ></rect>
            <rect
              id="Rectangle-Copy-14"
              fill="#FFFFFF"
              x="14.7553445"
              y="11.5183861"
              width="345.696642"
              height="133.885253"
              rx="10.1987914"
            ></rect>
            <path
              d="M339.509219,49.980051 C340.494931,49.980051 341.387325,50.3795895 342.033291,51.0255559 C342.679258,51.6715223 343.078796,52.5639165 343.078796,53.549628 L343.078796,53.549628 L343.078796,87.5591786 C343.078796,88.5448901 342.679258,89.4372844 342.033291,90.0832507 C341.387325,90.7292171 340.494931,91.1287556 339.509219,91.1287556 L339.509219,91.1287556 L35.6981119,91.1287556 C34.7124004,91.1287556 33.8200061,90.7292171 33.1740398,90.0832507 C32.5280734,89.4372844 32.1285349,88.5448901 32.1285349,87.5591786 L32.1285349,87.5591786 L32.1285349,53.549628 C32.1285349,52.5639165 32.5280734,51.6715223 33.1740398,51.0255559 C33.8200061,50.3795895 34.7124004,49.980051 35.6981119,49.980051 L35.6981119,49.980051 Z"
              id="Rectangle-7-Copy-7"
              stroke="#DADEE0"
              strokeWidth="1.01987914"
              fill="#FFFFFF"
            ></path>
            <text
              id="Search"
              fontFamily=".SFNS-Regular, .AppleSystemUIFont"
              fontSize="12.2385496"
              fontWeight="normal"
              fill="#181912"
            >
              <tspan x="48.1320607" y="73.3023437">
                Search
              </tspan>
            </text>
            <text
              id="CATEGORIES-Copy"
              fontFamily="OpenSans-SemiBold, Open Sans"
              fontSize="11.2186705"
              fontWeight="500"
              letterSpacing="-0.14958227"
              fill="#B8B8B5"
            >
              <tspan x="32.7263813" y="40.6433918">
                CATEGORIES
              </tspan>
            </text>
            <rect
              id="Rectangle"
              stroke="#EAEFEF"
              strokeWidth="1.01987914"
              fill="#FFFFFF"
              x="32.1285349"
              y="102.690781"
              width="74.8647496"
              height="25.3354857"
              rx="4.07951655"
            ></rect>
            <text
              id="Cat-1"
              fontFamily=".SFNS-Regular, .AppleSystemUIFont"
              fontSize="12.2385496"
              fontWeight="normal"
              fill="#181912"
            >
              <tspan x="42.6700128" y="118.633571">
                Cat 1
              </tspan>
            </text>
            <ellipse
              id="Oval"
              fill="#ECECEB"
              cx="93.8018327"
              cy="115.885631"
              rx="8.43162541"
              ry="8.43371675"
            ></ellipse>
            <path
              d="M94.4873239,115.885631 L97.3062355,113.06602 C97.428759,112.943614 97.4766886,112.765124 97.4319697,112.597786 C97.3872508,112.430448 97.2566773,112.299685 97.0894349,112.254753 C96.9221925,112.209822 96.7436894,112.257548 96.6211659,112.379954 L93.8018327,115.19997 L90.982508,112.379954 C90.8599845,112.257548 90.6814813,112.209822 90.514239,112.254753 C90.3469966,112.299685 90.2164231,112.430448 90.1717042,112.597786 C90.1269853,112.765124 90.1749149,112.943614 90.2974384,113.06602 L93.1163416,115.885631 L90.2974384,118.705242 C90.1080335,118.894465 90.1078485,119.201442 90.2970253,119.390894 C90.486202,119.580346 90.7931031,119.580531 90.982508,119.391308 L93.8018327,116.571292 L96.6211575,119.391316 C96.8105624,119.58054 97.1174634,119.580355 97.3066402,119.390903 C97.4958169,119.201451 97.4956319,118.894474 97.306227,118.70525 L94.4873239,115.885631 Z"
              id="Color"
              fill="#181912"
              fillRule="nonzero"
            ></path>
            <rect
              id="close"
              fillOpacity="0"
              fill="#FFFFFF"
              fillRule="nonzero"
              x="87.0565324"
              y="109.138657"
              width="13.4906007"
              height="13.4939468"
            ></rect>
            <rect
              id="Rectangle-Copy-6"
              stroke="#EAEFEF"
              strokeWidth="1.01987914"
              fill="#FFFFFF"
              x="116.444789"
              y="102.690781"
              width="87.5121877"
              height="25.3354857"
              rx="4.07951655"
            ></rect>
            <text
              id="Cat-20"
              fontFamily=".SFNS-Regular, .AppleSystemUIFont"
              fontSize="12.2385496"
              fontWeight="normal"
              fill="#181912"
            >
              <tspan x="130.320055" y="118.633571">
                Cat 20
              </tspan>
            </text>
            <ellipse
              id="Oval"
              fill="#ECECEB"
              cx="190.765525"
              cy="115.885631"
              rx="8.43162541"
              ry="8.43371675"
            ></ellipse>
            <path
              d="M191.451016,115.885631 L194.269928,113.06602 C194.392451,112.943614 194.440381,112.765124 194.395662,112.597786 C194.350943,112.430448 194.22037,112.299685 194.053127,112.254753 C193.885885,112.209822 193.707382,112.257548 193.584858,112.379954 L190.765525,115.19997 L187.9462,112.379954 C187.823677,112.257548 187.645174,112.209822 187.477931,112.254753 C187.310689,112.299685 187.180115,112.430448 187.135396,112.597786 C187.090678,112.765124 187.138607,112.943614 187.261131,113.06602 L190.080034,115.885631 L187.261131,118.705242 C187.071726,118.894465 187.071541,119.201442 187.260718,119.390894 C187.449894,119.580346 187.756795,119.580531 187.9462,119.391308 L190.765525,116.571292 L193.58485,119.391316 C193.774255,119.58054 194.081156,119.580355 194.270332,119.390903 C194.459509,119.201451 194.459324,118.894474 194.269919,118.70525 L191.451016,115.885631 Z"
              id="Color"
              fill="#181912"
              fillRule="nonzero"
            ></path>
            <rect
              id="close"
              fillOpacity="0"
              fill="#FFFFFF"
              fillRule="nonzero"
              x="184.020225"
              y="109.138657"
              width="13.4906007"
              height="13.4939468"
            ></rect>
            <rect
              id="Rectangle-Copy-7"
              stroke="#EAEFEF"
              strokeWidth="1.01987914"
              fill="#FFFFFF"
              x="213.408481"
              y="102.690781"
              width="126.508455"
              height="25.3354857"
              rx="4.07951655"
            ></rect>
            <text
              id="Cat-300000"
              fontFamily=".SFNS-Regular, .AppleSystemUIFont"
              fontSize="12.2385496"
              fontWeight="normal"
              fill="#181912"
            >
              <tspan x="227.794333" y="118.633571">
                Cat 300000
              </tspan>
            </text>
            <g
              id="utility/action/search"
              opacity="0.400000006"
              transform="translate(309.862234, 57.903828)"
              fillRule="nonzero"
            >
              <path
                d="M21.2097537,20.1711206 L19.915984,18.8770301 L16.8625763,15.822865 C18.0036526,14.5043995 18.6350095,12.8208161 18.6423238,11.0769701 C18.6426371,8.09720083 16.8483123,5.41067916 14.0961071,4.27022606 C11.3439018,3.12977296 8.17586965,3.76000342 6.06937708,5.86701848 C3.96288451,7.97403353 3.33281032,11.1428515 4.47298062,13.8957394 C5.61315092,16.6486272 8.29900641,18.4433971 11.2780368,18.4430837 C13.0214334,18.4357732 14.7045861,17.8042773 16.0227232,16.6629422 L19.0761309,19.7171073 L20.3699006,21.0111979 C20.601824,21.2431788 20.9778461,21.2431788 21.2097695,21.0111979 C21.4416929,20.779217 21.4416929,20.4031016 21.2097695,20.1711206 L21.2097537,20.1711206 Z M11.2780209,17.2571082 C8.7785604,17.2574215 6.52505319,15.7516092 5.56840631,13.4418959 C4.61175943,11.1321827 5.14039038,8.47348076 6.9077759,6.70565687 C8.67516141,4.93783299 11.333204,4.40907091 13.6423445,5.36595507 C15.951485,6.32283924 17.456924,8.5769054 17.4566107,11.0769859 C17.4523426,14.4884033 14.6885924,17.252839 11.2780209,17.2571082 L11.2780209,17.2571082 Z"
                id="Color"
                fill="#181412"
              ></path>
              <rect
                id="search"
                fillOpacity="0"
                fill="#FFFFFF"
                x="0"
                y="0"
                width="25.2948762"
                height="25.3011502"
              ></rect>
            </g>
            <ellipse
              id="Oval"
              fill="#ECECEB"
              cx="326.725485"
              cy="115.885631"
              rx="8.43162541"
              ry="8.43371675"
            ></ellipse>
            <path
              d="M327.410976,115.885631 L330.229888,113.06602 C330.352411,112.943614 330.400341,112.765124 330.355622,112.597786 C330.310903,112.430448 330.180329,112.299685 330.013087,112.254753 C329.845845,112.209822 329.667341,112.257548 329.544818,112.379954 L326.725485,115.19997 L323.90616,112.379954 C323.783637,112.257548 323.605133,112.209822 323.437891,112.254753 C323.270649,112.299685 323.140075,112.430448 323.095356,112.597786 C323.050637,112.765124 323.098567,112.943614 323.22109,113.06602 L326.039994,115.885631 L323.22109,118.705242 C323.031686,118.894465 323.031501,119.201442 323.220677,119.390894 C323.409854,119.580346 323.716755,119.580531 323.90616,119.391308 L326.725485,116.571292 L329.54481,119.391316 C329.734214,119.58054 330.041116,119.580355 330.230292,119.390903 C330.419469,119.201451 330.419284,118.894474 330.229879,118.70525 L327.410976,115.885631 Z"
              id="Color"
              fill="#181912"
              fillRule="nonzero"
            ></path>
            <rect
              id="close"
              fillOpacity="0"
              fill="#FFFFFF"
              fillRule="nonzero"
              x="319.980184"
              y="109.138657"
              width="13.4906007"
              height="13.4939468"
            ></rect>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default AddProductCategory;
