/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import EazyHeader from "../Header";
import LayoutWrapper from "../Layout";
// Not in use right now
// import Facebook from "../../icons/fb";
// import Twitter from "../../icons/twitter";
// import Instagram from "../../icons/Insta";
// import Linkedin from "../../icons/Linkedin";
import { navigate } from "@reach/router";
import Link from "gatsby-link";
import { Message, useMessage } from "../../lib/getMessages";
import NotFound from "../../pages/404";
import ForgotPassword from "../ForgotPassword";
import LoginForm from "../LoginForm";
import Popup from "../Popup";
import SignupForm from "../SignupForm";
import ImageSlideshow from "../SlideShow";
import "./blog.css";

import bigrockDesktop from "../../images/bigrockDesktop.png";
import customDomainSetupImage1Desktop from "../../images/custom-domain-setup-image1-desktop.png";
import domainDesktop from "../../images/domainDesktop.png";
import goDaddyDesktop from "../../images/goDaddyDesktop.png";
import hostinger from "../../images/hostinger.png";
import hoverDesktop from "../../images/hoverDesktop.png";
import oneComDesktop from "../../images/one.comDesktop.png";

import bigrockMobile from "../../images/bigrockMobile.png";
import customDomainSetupImage1Mobile from "../../images/custom-domain-setup-image1-mobile.png";
import domainMobile from "../../images/domainMobile.png";
import goDaddyMobile from "../../images/goDaddyMobile.png";
import hoverMobile from "../../images/hoverMobile.png";
import oneComMobile from "../../images/one.comMobile.png";
// import PayPal from "../../images/PayPal.png";
import Paytm from "../../images/Paytm.png";
import PayU from "../../images/PayU.png";
// import PhonePay from "../../images/PhonePay.png";
import Razorpay from "../../images/Razorpay.png";
// import Stripe from "../../images/Stripe.png";

import AddProductBarcode from "../../icons/AddProductBarcode";
import AddProductCategory from "../../icons/AddProductCategory";
import AddProductDescription from "../../icons/AddProductDescription";
import AddProductDiscountPrice from "../../icons/AddProductDiscountPrice";
import AddProductName from "../../icons/AddProductName";
import AddProductSellingPrice from "../../icons/AddProductSellingPrice";
import AddProductTax from "../../icons/AddProductTax";
import AddProductUploadImg from "../../icons/AddProductUploadImg";
import AddProductVariantQuantity from "../../icons/AddProductVariantQuantity";
import howToAddLogoUploadImg from "../../images/add-logo-favicon-img--for-content-for-upload-logo.svg";
import howToAddLogoParaOne from "../../images/how-to-add-logo-favicon-para-1-img.png";
import howToAddLogoParaTwo from "../../images/how-to-add-logo-favicon-para-2-img.svg";
import star from "../../images/Star.svg";
import couponImage1 from "../../images/Group25@3x.webp";
import couponImage2 from "../../images/Group28@3x.webp";
import couponImage3 from "../../images/Group31@3x.webp";
import couponImage4 from "../../images/Group33@3x.webp";
import couponImage5 from "../../images/Group36@3x.webp";
import couponImage6 from "../../images/Group37@3x.webp";
import ondcOperate from "../../images/OndcOperate.webp";

import googleapileft from "../../images/googleapileft.png";
import googleapiright from "../../images/googleapiright.png";
import ImagePopup from "../ImagePopup";
const BulletPoint = ({ item }) => (
  <>
    <li className="eazy-detailed-blog-bullets-heading">
      <span>
        <Message dictKey={item.title} />
      </span>
    </li>
    {item.description && (
      <p className="eazy-detailed-blog-bullets-description">
        <Message dictKey={item.description} />
      </p>
    )}
  </>
);

const FurtherReadingBlog = ({ blog, location }) => {
  const { title, description, image, slug } = blog;
  return (
    <div className="blog-further-reading-container">
      <img
        src={image}
        alt="blog-img"
        loading="eager"
        className="blog-further-reading-image-area"
      />
      <div className="blog-further-reading-text-area">
        <a href={`/${location}/${slug}`} className="blog-further-reading-title">
          <Message dictKey={title} />
        </a>
        <a
          href={`/${location}/${slug}`}
          className="blog-further-reading-description"
        >
          <Message dictKey={description} />
        </a>
      </div>
    </div>
  );
};

let isMobile = false;

const DetailedBlog = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  useEffect(() => {
    isMobile = window.innerWidth <= 640;
  }, []);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const [showSignupForm, setShowSignupForm] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [isLoggedIn, setIsLoggedInState] = useState(false);

  const getMessage = useMessage();
  const handleClickShowLoginForm = () => {
    setShowLoginForm(true);
  };
  const handleClickCloseLoginForm = () => {
    setShowLoginForm(false);
  };
  const setIsLoggedIn = (isLoggedin) => {
    setIsLoggedInState(isLoggedin);
  };
  const handleClickShowSignupForm = () => {
    setShowSignupForm(true);
  };
  const handleClickCloseSignupForm = () => {
    setShowSignupForm(false);
  };
  const handleClickShowForgotPassword = () => {
    setShowForgotPassword(true);
  };
  const handleClickCloseForgotPassword = () => {
    setShowForgotPassword(false);
  };

  const handleTryZopping = () => {
    if (!isLoggedIn) {
      handleClickShowSignupForm();
    } else {
      window.open("/admin/home/dashboard", "_blank");
    }
  };
  const handleSetupFooter = () => {
    if (!isLoggedIn) {
      handleClickShowSignupForm();
    } else {
      window.open("/admin/settings/contact-details", "_blank");
    }
  };
  const getParagraphs = (array = [], startingID = 1, endingID) => {
    startingID = Math.max(startingID, 1) ?? 1;
    endingID = Math.min(endingID, array.length) ?? array.length;

    return (
      <>
        {array
          .filter(({ id }) => id >= startingID && id <= endingID)
          .map(({ className = "", description }) => (
            <p className={`eazy-detailed-blog-description ${className}`}>
              <Message dictKey={description} />
            </p>
          ))}
      </>
    );
  };

  const {
    language,
    location,
    updateLanguage,
    blog,
    furtherReadingBlogs,
    direction,
  } = props;

  const lang = language ? `/${language}` : "";
  const locationPathArray = location.pathname.split("/").filter((item) => item);
  const furtherReadingLocation = locationPathArray
    .slice(0, locationPathArray.length - 1)
    .join("/");
  if (!blog || !blog.slug) {
    return <NotFound {...props} />;
  }
  const {
    title,
    postedOn,
    timeToRead,
    description,
    list1,
    bannerImage,
    paragraphs,
    moreDescriptions,
    bulletPointCategories,
    heading,
    ImageForContent,
    ImageRelatedPoints,
    MoreHeading,
    AdditionalParagraph,
    importantConfigurePoints,
    guideParagraphs,
    configurePoints,
    serviceProvider,
    paragraphsDetails,
    emailService,
    metaDescription,
    OtherDescriptions,
    dotDescription,
    someDescription,
    dataConfigurePoint,
    importantPoints,
    dataPoints,
    definePoints,
    reflectPoints,
    uninstallPoint,
    MoreImageRelatedPoints,
    affectPoints,
    definePointsForStore,
  } = blog;
  const titleText = getMessage(title);
  const metaText = getMessage(metaDescription);
  let canonicalUrl = `https://staging.zopping.com/blog/${blog.slug}`;
  if (typeof window !== "undefined") {
    if (window.location.host === "zopping.com") {
      canonicalUrl = `https://zopping.com/blog/${blog.slug}`;
    }
  }
  const isDirectionRTL = direction === "rtl";
  return (
    <LayoutWrapper language={language} location={location}>
      <div className="eazy-page">
        <Helmet title={`${titleText} - Zopping Blog`}>
          <meta name="description" content={metaText} />
          <meta property="og:title" content={titleText} />
          <meta property="og:image" content={bannerImage} />
          <meta property="og:url" content={titleText} />
          <link rel="canonical" href={canonicalUrl} />
        </Helmet>
        <EazyHeader
          language={language}
          updateLanguage={updateLanguage}
          current="blog"
          isDirectionRTL={isDirectionRTL}
        />
        <div className="eazy-detailed-blog-main-container">
          <div className="eazy-detailed-blog">
            <img
              src={bannerImage}
              alt="blog-img"
              loading="eager"
              className="eazy-detailed-blog-image"
            />
            <div className="eazy-detailed-blog-description-container ">
              <header className="eazy-detailed-blog-heading">
                <Message dictKey={title} />
              </header>
              <p className="eazy-detailed-blog-information">
                <Message dictKey="posted.on" />: {postedOn}. {timeToRead}
                <Message dictKey="read.text" />
              </p>
              <p className="eazy-detailed-blog-description">
                <Message dictKey={description} />
              </p>
              {moreDescriptions?.map((detail) => (
                <p className="eazy-detailed-blog-description" key={detail.id}>
                  <Message dictKey={detail.description} />
                </p>
              ))}
              {heading && (
                <h2 className="eazy-detailed-blog-inside-heading">
                  <Message dictKey={heading} />
                </h2>
              )}
              {blog.slug ===
                "role-and-responsibilities-of-network-participants" && (
                <>
                  <div className="eazy-detailed-blog-img-background">
                    <div className="eazy-detailed-blog-explain">
                      <div className="eazy-detailed-blog-content">
                        {ImageRelatedPoints?.items?.map((detail) => (
                          <Message dictKey={detail.description} />
                        ))}
                      </div>
                      <div className="eazy-detailed-blog-content">
                        {MoreImageRelatedPoints?.items?.map((detail) => (
                          <Message dictKey={detail.description} />
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="eazy-detailed-blog-img-background-mobile">
                    <div className="eazy-detailed-blog-explain">
                      <div className="eazy-detailed-blog-content-mobile">
                        {ImageRelatedPoints?.items?.map((detail) => (
                          <Message dictKey={detail.description} />
                        ))}
                      </div>
                      <div className="eazy-detailed-blog-content-mobile">
                        {MoreImageRelatedPoints?.items?.map((detail) => (
                          <Message dictKey={detail.description} />
                        ))}
                      </div>
                    </div>
                  </div>
                </>
              )}
              {blog.slug === "step-by-step-guide" && (
                <div className="step-by-step-guide-container">
                  <div className="step-by-step-guide-wrp">
                    <ol className="steps-to-change-themes">
                      {bulletPointCategories?.stepsForChangingTheme?.map(
                        (ele) => {
                          return (
                            <li>
                              <Message dictKey={ele.description} />
                            </li>
                          );
                        }
                      )}
                    </ol>
                  </div>
                  <p className="eazy-detailed-blog-description">
                    <Message dictKey="step-by-step-guide-step2.3" />
                  </p>
                  <div className="step-by-step-guide-wrp">
                    <ol className="steps-to-change-pscolor">
                      {bulletPointCategories?.stepsForPSColour?.map((ele) => {
                        return (
                          <li>
                            <Message dictKey={ele.description} />
                          </li>
                        );
                      })}
                    </ol>
                  </div>
                  <p className="eazy-detailed-blog-description">
                    <Message dictKey="step-by-step-guide-step2.4" />
                  </p>
                  <div className="step-by-step-guide-wrp">
                    <ol>
                      {bulletPointCategories?.stepsForCustomizingPage?.map(
                        (ele) => {
                          return (
                            <li>
                              <Message dictKey={ele.description} />
                            </li>
                          );
                        }
                      )}
                    </ol>
                  </div>

                  {getParagraphs(guideParagraphs, 1, 7)}
                  <div className="step-by-step-guide-wrp">
                    <ol className="steps-to-change-pscolor">
                      {bulletPointCategories?.stepsToCreatePolicyPage?.map(
                        (ele) => {
                          return (
                            <li>
                              <Message dictKey={ele.description} />
                            </li>
                          );
                        }
                      )}
                    </ol>
                  </div>

                  {getParagraphs(guideParagraphs, 8, 9)}
                  <div className="step-by-step-guide-wrp">
                    <ol className="steps-to-change-pscolor">
                      {bulletPointCategories?.stepsForPaymentSetup?.map(
                        (ele) => {
                          return (
                            <li>
                              <Message dictKey={ele.description} />
                            </li>
                          );
                        }
                      )}
                    </ol>
                  </div>

                  {getParagraphs(guideParagraphs, 10, 12)}
                  <div className="step-by-step-guide-wrp">
                    <ol className="steps-to-change-pscolor">
                      {bulletPointCategories?.shippingSupport?.map((ele) => {
                        return (
                          <li>
                            <Message dictKey={ele.description} />
                          </li>
                        );
                      })}
                    </ol>
                  </div>
                  <p className="eazy-detailed-blog-description">
                    <Message dictKey="step-by-step-guide-step6-para1" />
                  </p>
                  <div className="step-by-step-guide-wrp">
                    <ol className="steps-to-change-pscolor">
                      {bulletPointCategories?.stepsForIntegratingLogistics?.map(
                        (ele) => {
                          return (
                            <li>
                              <Message dictKey={ele.description} />
                            </li>
                          );
                        }
                      )}
                    </ol>
                  </div>

                  {getParagraphs(guideParagraphs, 13, 21)}
                  <div className="step-by-step-guide-wrp">
                    <ol className="steps-to-change-pscolor">
                      {bulletPointCategories?.stepsForGoogleAnalytics?.map(
                        (ele) => {
                          return (
                            <li>
                              <Message dictKey={ele.description} />
                            </li>
                          );
                        }
                      )}
                    </ol>
                  </div>

                  <p className="eazy-detailed-blog-description">
                    <Message dictKey="step-by-step-guide-step8.2.1" />
                  </p>
                  <div className="step-by-step-guide-wrp">
                    <ol className="steps-to-change-pscolor">
                      {bulletPointCategories?.stepsForFacebookAnalytics?.map(
                        (ele) => {
                          return (
                            <li>
                              <Message dictKey={ele.description} />
                            </li>
                          );
                        }
                      )}
                    </ol>
                  </div>

                  {getParagraphs(guideParagraphs, 22, 23)}
                  <div className="step-by-step-guide-wrp">
                    <ol className="steps-to-change-pscolor">
                      {bulletPointCategories?.stepsForGoogleTagManager?.map(
                        (ele) => {
                          return (
                            <li>
                              <Message dictKey={ele.description} />
                            </li>
                          );
                        }
                      )}
                    </ol>
                  </div>
                </div>
              )}
              {blog.slug ===
                "how-to-set-up-Email-and-smtp-configurations-for-your-online-store-on-zopping" && (
                <div className="configure-email-smtp-points">
                  {importantConfigurePoints?.map((item) => (
                    <div className="email-SMTP-step">
                      <div className="bullet"></div>
                      <Message dictKey={item?.point} />
                    </div>
                  ))}
                </div>
              )}
              {blog.slug === "how-to-add-products-to-your-online-store" && (
                <div className="eazy-detailed-blog-img-outer">
                  <div className="eazy-detailed-blog-explain-img">
                    <div className="eazy-detailed-blog-img-content">
                      {ImageRelatedPoints?.items?.map((detail) => (
                        <Message dictKey={detail.description} />
                      ))}
                    </div>
                    {ImageForContent && (
                      <div className="eazy-detailed-blog-img">
                        <img src={ImageForContent} alt="addProductMobileImg" />
                      </div>
                    )}
                  </div>
                </div>
              )}
              {list1?.description && (
                <p className="eazy-detailed-blog-bullets-information">
                  <Message dictKey={list1.description} />
                </p>
              )}
              {list1?.items?.map((item) => (
                <BulletPoint item={item} key={item.id} />
              ))}
              {paragraphs?.map((detail) => (
                <p className="eazy-detailed-blog-description" key={detail.id}>
                  <Message dictKey={detail.description} />
                </p>
              ))}
              {blog.slug ===
                "how-to-set-up-Email-and-smtp-configurations-for-your-online-store-on-zopping" && (
                <div>
                  {paragraphsDetails?.map((detail, id) => (
                    <div className="communication-detail-step">
                      <span>
                        {id === 0 || id === 4 ? "" : <span>{id}.</span>}
                      </span>
                      <Message dictKey={detail?.description} />
                    </div>
                  ))}
                </div>
              )}
              {blog.slug ===
                "how-to-set-up-Email-and-smtp-configurations-for-your-online-store-on-zopping" && (
                <div>
                  {configurePoints?.map((item, index) => {
                    return (
                      <div
                        className={
                          index % 2 === 0
                            ? "configure-wrp"
                            : "configure-wrp rev"
                        }
                      >
                        <img src={item?.img} alt={item?.alt} />
                        <div className="configure-steps-wrp">
                          {item?.description?.map((paragraphs, index) => {
                            return (
                              <>
                                {index === 0 ? (
                                  <h2>
                                    <Message dictKey={paragraphs} />
                                  </h2>
                                ) : (
                                  <div className="email-SMTP-step">
                                    <div className="bullet"></div>
                                    <Message dictKey={paragraphs} />
                                  </div>
                                )}
                              </>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                  <div>
                    {serviceProvider?.serviceProviderHeadings?.map(
                      (item, index) => {
                        return (
                          <div>
                            <Message dictKey={item?.heading} />
                          </div>
                        );
                      }
                    )}
                    {serviceProvider?.serviceProviderSubHeadings?.map(
                      (item, index) => {
                        return (
                          <div className="service-provider-subheading">
                            <img src={item?.img} alt="" />
                            <Message dictKey={item?.heading} />
                          </div>
                        );
                      }
                    )}
                    <div className="email-provider-steps-wrapper">
                      {serviceProvider?.serviceProviderDescription?.map(
                        (item, index) => {
                          return (
                            <div className="email-SMTP-step">
                              <div className="bullet"></div>
                              <Message dictKey={item?.heading} />
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
              )}
              {blog.slug === "what-is-an-abandoned-cart" && (
                <div>
                  {configurePoints?.map((item, index) => {
                    return (
                      <div className="configure-wrp abandoned-cart">
                        <div className="configure-steps-wrapper">
                          <span className="step-index">{index + 1}.</span>
                          <Message dictKey={item?.description} />
                        </div>
                        {item?.img && (
                          <input
                            type="image"
                            src={item?.img}
                            alt={item?.alt}
                            className="variant-img"
                            onClick={() => handleImageClick(item?.pngImg)}
                          />
                        )}
                      </div>
                    );
                  })}
                  <p className="delivery-uninstall-heading">
                    <Message dictKey="how.to.install.abandoned.cart.extension" />
                  </p>
                  {dataPoints.slice(0, 1).map((detail, index) => (
                    <div className="configure-wrp abandoned-cart-install">
                      <div className="email-SMTP-step direction-column">
                        <div className="email-SMTP-step">
                          <div className="step-index">{index + 1}.</div>
                          <Message dictKey={detail?.description} />
                        </div>
                        {dataPoints.length > 1 && (
                          <div className="email-SMTP-step">
                            <div className="step-index">{index + 2}.</div>
                            <Message
                              dictKey={dataPoints[index + 1]?.description}
                            />
                          </div>
                        )}
                      </div>
                      {detail?.img && (
                        <input
                          type="image"
                          src={detail?.img}
                          alt={detail?.alt}
                          className="variant-img"
                          onClick={() => handleImageClick(detail?.pngImg)}
                        />
                      )}
                    </div>
                  ))}
                  {dataPoints.slice(2)?.map((detail, index) => (
                    <div className={index === 1 ? "" : "configure-wrp"}>
                      <div className="email-SMTP-step">
                        <div className="step-index">{index + 3}.</div>
                        <Message dictKey={detail?.description} />
                      </div>
                      {detail?.img && (
                        <div className="delivery-img">
                          <input
                            type="image"
                            src={detail?.img}
                            alt={detail?.alt}
                            className="variant-img"
                            onClick={() => handleImageClick(detail?.pngImg)}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                  <div className="eazy-detailed-blog-description eazy-affect-heading">
                    <p>
                      <Message dictKey="referral-management.slot.affect" />
                    </p>
                  </div>
                  {affectPoints?.map((item, index) => {
                    return (
                      <div>
                        {item?.description && (
                          <div className="email-SMTP-step configure-wrf-affect-block ">
                            <div className="step-index">{item.id}.</div>
                            <Message dictKey={item.description} />
                          </div>
                        )}
                        {item?.note && (
                          <div className="email-SMTP-step">
                            <Message dictKey={item.note} />
                          </div>
                        )}
                        {item?.img && (
                          <div className="delivery-img">
                            <input
                              type="image"
                              src={item?.img}
                              alt={item?.alt}
                              className="variant-img"
                              onClick={() => handleImageClick(item?.pngImg)}
                            />
                          </div>
                        )}
                      </div>
                    );
                  })}
                  <p className="delivery-uninstall-heading">
                    <Message dictKey="uninstall.heading" />
                  </p>
                  {uninstallPoint?.map((item, index) => {
                    return (
                      <div>
                        <div className="uninstall-wrp">
                          <Message dictKey={item?.description} />
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              {blog.slug === "product-variant-support-extension" && (
                <div>
                  {dataConfigurePoint.map((item, index) => {
                    return (
                      <div
                        className={
                          index % 2 === 0
                            ? "configure-wrp"
                            : "configure-wrp rev"
                        }
                      >
                        <input
                          type="image"
                          src={item?.img}
                          alt={item?.alt}
                          className="variant-img"
                          onClick={() => handleImageClick(item?.pngImg)}
                        />
                        <div className="configure-steps-wrp">
                          {item?.description?.map((paragraphs, index) => {
                            return (
                              <div className="email-SMTP-step">
                                <div className="bullet"></div>
                                <Message dictKey={paragraphs} />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                  <p>
                    <Message dictKey="define.the.product.variants.for.my.products.heading" />
                  </p>
                  {configurePoints?.map((item, index) => {
                    return (
                      <div
                        className={
                          index % 2 === 0
                            ? "configure-wrp"
                            : "configure-wrp rev"
                        }
                      >
                        <input
                          type="image"
                          src={item?.img}
                          alt={item?.alt}
                          className="variant-img"
                          onClick={() => handleImageClick(item?.pngImg)}
                        />
                        <div className="configure-steps-wrapper">
                          <div className="step-index">{index + 1}.</div>
                          <Message dictKey={item?.description} />
                        </div>
                      </div>
                    );
                  })}
                  {importantPoints?.map((item, index) => {
                    return (
                      <div className="varinat-wrp">
                        {item?.description?.map((paragraphs, index) => {
                          return (
                            <div className="email-SMTP-step">
                              <Message dictKey={paragraphs} />
                            </div>
                          );
                        })}
                        <input
                          type="image"
                          src={item?.img}
                          alt={item?.alt}
                          className="variant-img"
                          onClick={() => handleImageClick(item?.pngImg)}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
              {blog.slug === "delivery-area-support-extension" && (
                <div>
                  {configurePoints?.map((item, index) => {
                    return (
                      <div
                        className={
                          index === 0
                            ? "configure-wrp"
                            : index > 0 && index % 2 === 0
                            ? "configure-wrp rev"
                            : "configure-wrp"
                        }
                      >
                        {item?.img && (
                          <input
                            type="image"
                            src={item?.img}
                            alt={item?.alt}
                            className="variant-img"
                            onClick={() => handleImageClick(item?.pngImg)}
                          />
                        )}
                        <div className="configure-steps-wrapper">
                          <div className="step-index">{index + 1}.</div>
                          <Message dictKey={item?.description} />
                        </div>
                      </div>
                    );
                  })}
                  {dataPoints?.map((detail) => (
                    <p
                      className="eazy-detailed-blog-description "
                      key={detail.id}
                    >
                      <Message dictKey={detail.description} />
                    </p>
                  ))}
                  {dataConfigurePoint.map((item, index) => {
                    return (
                      <div
                        className={
                          index % 2 === 0
                            ? "configure-wrp"
                            : "configure-wrp rev"
                        }
                      >
                        <input
                          type="image"
                          src={item?.img}
                          alt={item?.alt}
                          className="variant-img"
                          onClick={() => handleImageClick(item?.pngImg)}
                        />
                        <div className="configure-steps-wrp">
                          {item?.description?.map((paragraphs, index) => {
                            return (
                              <div className="email-SMTP-step">
                                <div className="bullet"></div>
                                <Message dictKey={paragraphs} />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                  <div>
                    <p className="delivery-define-heading">
                      <Message dictKey="how.do.i.define.delivery.areas" />
                    </p>
                  </div>
                  {definePoints?.map((item, index) => {
                    return (
                      <div>
                        {item?.id === 2 || item?.id === 7 ? (
                          <div>
                            <div className="email-SMTP-step">
                              <div className="step-index">{index + 1}.</div>
                              <Message dictKey={item.description} />
                            </div>
                            <div className="delivery-img">
                              <input
                                type="image"
                                src={item?.img}
                                alt={item?.alt}
                                className="variant-img"
                                onClick={() => handleImageClick(item?.pngImg)}
                              />
                            </div>
                          </div>
                        ) : (
                          <div
                            className={
                              index < 6
                                ? index % 2 === 0
                                  ? "configure-wrp rev"
                                  : "configure-wrp"
                                : index % 2 === 0
                                ? "configure-wrp "
                                : "configure-wrp rev"
                            }
                          >
                            <div className="configure-steps-wrapper">
                              <div className="step-index">{index + 1}.</div>
                              <Message dictKey={item?.description} />
                            </div>
                            {item?.img && (
                              <input
                                type="image"
                                src={item?.img}
                                alt={item?.alt}
                                className="variant-img"
                                onClick={() => handleImageClick(item?.pngImg)}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    );
                  })}
                  <div>
                    <p className="eazy-detailed-blog-description">
                      <Message dictKey="how.will.this.change.reflect" />
                    </p>
                  </div>
                  {reflectPoints?.map((item, index) => {
                    return (
                      <div
                        className={
                          index === 0
                            ? "configure-wrp"
                            : index >= 2 && index % 2 !== 0
                            ? "configure-wrp"
                            : "configure-wrp rev"
                        }
                      >
                        {item?.img && (
                          <input
                            type="image"
                            src={item?.img}
                            alt={item?.alt}
                            className="variant-img"
                            onClick={() => handleImageClick(item?.pngImg)}
                          />
                        )}
                        <div className="configure-steps-wrapper">
                          <div className="step-index">{index + 1}.</div>
                          <Message dictKey={item?.description} />
                        </div>
                      </div>
                    );
                  })}
                  <p className="delivery-uninstall-heading">
                    <Message dictKey="uninstall.heading" />
                  </p>
                  {uninstallPoint?.map((item, index) => {
                    return (
                      <div>
                        <div className="uninstall-wrp">
                          <div className="step-index">{index + 1}.</div>
                          <Message dictKey={item?.description} />
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              {emailService && (
                <div>
                  <Message dictKey={emailService} />
                </div>
              )}
              {blog.slug === "what-is-delivery-pickup-slot-management" && (
                <div>
                  {configurePoints?.map((item, index) => {
                    return (
                      <div
                        className={
                          index === 0
                            ? "configure-wrp"
                            : index > 0 && index % 2 === 0
                            ? "configure-wrp rev"
                            : "configure-wrp"
                        }
                      >
                        {item?.img && (
                          <input
                            type="image"
                            src={item?.img}
                            alt={item?.alt}
                            className="variant-img"
                            onClick={() => handleImageClick(item?.pngImg)}
                          />
                        )}
                        <div className="configure-steps-wrapper">
                          <div className="step-index">{index + 1}.</div>
                          <Message dictKey={item?.description} />
                        </div>
                      </div>
                    );
                  })}
                  {dataPoints?.map((detail) => (
                    <p
                      className="eazy-detailed-blog-description "
                      key={detail.id}
                    >
                      <Message dictKey={detail.description} />
                    </p>
                  ))}
                  {dataConfigurePoint.map((item, index) => {
                    return (
                      <div
                        className={
                          index % 2 === 0
                            ? "configure-wrp"
                            : "configure-wrp rev"
                        }
                      >
                        <input
                          type="image"
                          src={item?.img}
                          alt={item?.alt}
                          className="variant-img"
                          onClick={() => handleImageClick(item?.pngImg)}
                        />
                        <div className="configure-steps-wrp">
                          {item?.description?.map((paragraphs, index) => {
                            return (
                              <div className="email-SMTP-step">
                                <div className="bullet"></div>
                                <Message dictKey={paragraphs} />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                  <div className="delivery-define-heading">
                    <Message dictKey="delivery.pickup.slot.extension.installation.note" />
                  </div>
                  <div>
                    <p className="delivery-define-heading">
                      <Message dictKey="how.do.i.define.delivery.pickup.slot.my.store" />
                    </p>
                  </div>
                  {definePointsForStore?.map((item, index) => {
                    return (
                      <div>
                        <div
                          className={
                            index % 2 === 0 || index === 7
                              ? "configure-wrp"
                              : "configure-wrp rev"
                          }
                        >
                          <div className="configure-steps-wrapper">
                            <div className="step-index">{index + 1}.</div>
                            <Message dictKey={item?.description} />
                          </div>
                          {item?.img && (
                            <input
                              type="image"
                              src={item?.img}
                              alt={item?.alt}
                              className="variant-img"
                              onClick={() => handleImageClick(item?.pngImg)}
                            />
                          )}
                        </div>
                      </div>
                    );
                  })}

                  <div>
                    <p className="delivery-define-heading">
                      <Message dictKey="how.do.i.define.delivery.pickup.slot" />
                    </p>
                  </div>

                  {definePoints?.map((item, index) => {
                    return (
                      <div>
                        {item?.id === 2 || item?.id === 4 ? (
                          <div>
                            <div className="email-SMTP-step">
                              <div className="step-index">{index + 1}.</div>
                              <Message dictKey={item.description} />
                            </div>
                            <div className="delivery-img">
                              <input
                                type="image"
                                src={item?.img}
                                alt={item?.alt}
                                className="variant-img"
                                onClick={() => handleImageClick(item?.pngImg)}
                              />
                            </div>
                          </div>
                        ) : (
                          <div
                            className={
                              index < 6
                                ? index % 2 === 0
                                  ? "configure-wrp rev"
                                  : "configure-wrp"
                                : index % 2 === 0
                                ? "configure-wrp "
                                : "configure-wrp rev"
                            }
                          >
                            <div className="configure-steps-wrapper">
                              <div className="step-index">{index + 1}.</div>
                              <Message dictKey={item?.description} />
                            </div>
                            {item?.img && (
                              <input
                                type="image"
                                src={item?.img}
                                alt={item?.alt}
                                className="variant-img"
                                onClick={() => handleImageClick(item?.pngImg)}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    );
                  })}
                  <div>
                    <p className="eazy-detailed-blog-description">
                      <Message dictKey="how.to.define.slot.capacity.delivery/pickup" />
                    </p>
                  </div>
                  {reflectPoints?.map((item, index) => {
                    return (
                      <div>
                        {item?.id === 3 ? (
                          <div>
                            <div className="email-SMTP-step">
                              <div className="step-index">{index + 1}.</div>
                              <Message dictKey={item.description} />
                            </div>
                            <div className="delivery-img">
                              <input
                                type="image"
                                src={item?.img}
                                alt={item?.alt}
                                className="variant-img"
                                onClick={() => handleImageClick(item?.pngImg)}
                              />
                            </div>
                          </div>
                        ) : (
                          <div
                            className={
                              index < 6
                                ? index % 2 === 0
                                  ? "configure-wrp rev"
                                  : "configure-wrp"
                                : index % 2 === 0
                                ? "configure-wrp "
                                : "configure-wrp rev"
                            }
                          >
                            <div className="configure-steps-wrapper">
                              <div className="step-index">{index + 1}.</div>
                              <Message dictKey={item?.description} />
                            </div>
                            {item?.img && (
                              <input
                                type="image"
                                src={item?.img}
                                alt={item?.alt}
                                className="variant-img"
                                onClick={() => handleImageClick(item?.pngImg)}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    );
                  })}
                  <div>
                    <p className="eazy-detailed-blog-description">
                      <Message dictKey="how.will.this.change.affect" />
                    </p>
                  </div>
                  {affectPoints?.map((item, index) => {
                    return (
                      <div>
                        {item?.id === 2 ? (
                          <div>
                            <div className="email-SMTP-step">
                              <div className="step-index">{index + 1}.</div>
                              <Message dictKey={item.description} />
                            </div>
                            <div className="delivery-img">
                              <input
                                type="image"
                                src={item?.img}
                                alt={item?.alt}
                                className="variant-img"
                                onClick={() => handleImageClick(item?.pngImg)}
                              />
                            </div>
                          </div>
                        ) : (
                          <div
                            className={
                              index === 0
                                ? "configure-wrp"
                                : index > 0 && index % 2 === 0
                                ? "configure-wrp "
                                : "configure-wrp rev"
                            }
                          >
                            <div className="configure-steps-wrapper">
                              <div className="step-index">{index + 1}.</div>
                              <Message dictKey={item?.description} />
                            </div>
                            {item?.img && (
                              <input
                                type="image"
                                src={item?.img}
                                alt={item?.alt}
                                className="variant-img"
                                onClick={() => handleImageClick(item?.pngImg)}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    );
                  })}
                  <p className="delivery-uninstall-heading">
                    <Message dictKey="uninstall.heading" />
                  </p>
                  {uninstallPoint?.map((item, index) => {
                    return (
                      <div>
                        <div className="uninstall-wrp">
                          <div className="step-index">{index + 1}.</div>
                          <Message dictKey={item?.description} />
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              {blog.slug === "what-is-referral-management" && (
                <div>
                  {configurePoints?.map((item, index) => {
                    return (
                      <div
                        className={
                          index === 0
                            ? "configure-wrp"
                            : index > 0 && index % 2 === 0
                            ? "configure-wrp rev"
                            : "configure-wrp"
                        }
                      >
                        {item?.img && (
                          <input
                            type="image"
                            src={item?.img}
                            alt={item?.alt}
                            className="variant-img"
                            onClick={() => handleImageClick(item?.pngImg)}
                          />
                        )}
                        <div className="configure-steps-wrapper">
                          <div className="step-index">{index + 1}.</div>
                          <Message dictKey={item?.description} />
                        </div>
                      </div>
                    );
                  })}
                  {dataConfigurePoint?.map((item, index) => {
                    return (
                      <div
                        className={
                          index % 2 === 0
                            ? "configure-wrp"
                            : "configure-wrp rev"
                        }
                      >
                        <input
                          type="image"
                          src={item?.img}
                          alt={item?.alt}
                          className="variant-img"
                          onClick={() => handleImageClick(item?.pngImg)}
                        />
                        <div className="configure-steps-wrp">
                          {item?.description?.map((paragraphs, index) => {
                            return (
                              <div className="email-SMTP-step">
                                <div className="bullet"></div>
                                <Message dictKey={paragraphs} />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                  {definePointsForStore?.map((item, index) => {
                    return (
                      <div>
                        <div
                          className={
                            index % 2 === 0 || index === 7
                              ? "configure-wrp"
                              : "configure-wrp rev"
                          }
                        >
                          <div className="configure-steps-wrapper">
                            <div className="step-index">{index + 1}.</div>
                            <Message dictKey={item?.description} />
                          </div>
                          {item?.img && (
                            <input
                              type="image"
                              src={item?.img}
                              alt={item?.alt}
                              className="variant-img"
                              onClick={() => handleImageClick(item?.pngImg)}
                            />
                          )}
                        </div>
                      </div>
                    );
                  })}
                  <div>
                    <p className="eazy-detailed-blog-description">
                      <Message dictKey="referral-management.slot.affect" />
                    </p>
                  </div>
                  {affectPoints?.map((item, index) => {
                    return (
                      <div
                        className={
                          index === 0
                            ? "configure-wrp rev"
                            : index > 0 && index % 2 === 0
                            ? "configure-wrp rev"
                            : "configure-wrp"
                        }
                      >
                        <div className="configure-steps-wrapper">
                          <div className="step-index">{index + 1}.</div>
                          <Message dictKey={item?.description} />
                        </div>
                        {item?.img && (
                          <input
                            type="image"
                            src={item?.img}
                            alt={item?.alt}
                            className="variant-img"
                            onClick={() => handleImageClick(item?.pngImg)}
                          />
                        )}
                      </div>
                    );
                  })}
                  <p className="delivery-uninstall-heading">
                    <Message dictKey="note.heading" />
                  </p>
                  {dataPoints?.map((detail) => (
                    <li
                      className="eazy-detailed-blog-description "
                      key={detail.id}
                    >
                      <Message dictKey={detail.description} />
                    </li>
                  ))}
                  <p className="delivery-uninstall-heading">
                    <Message dictKey="uninstall.heading" />
                  </p>
                  {uninstallPoint?.map((item, index) => {
                    return (
                      <div>
                        <div className="uninstall-wrp">
                          <div className="step-index">{index + 1}.</div>
                          <Message dictKey={item?.description} />
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              {blog.slug === "what-are-reviews-ratings" && (
                <div>
                  {configurePoints?.map((item, index) => {
                    return (
                      <div
                        className={
                          index === 0
                            ? "configure-wrp"
                            : index > 0 && index % 2 === 0
                            ? "configure-wrp rev"
                            : "configure-wrp"
                        }
                      >
                        {item?.img && (
                          <input
                            type="image"
                            src={item?.img}
                            alt={item?.alt}
                            className="variant-img"
                            onClick={() => handleImageClick(item?.pngImg)}
                          />
                        )}
                        <div className="configure-steps-wrapper">
                          <div className="step-index">{index + 1}.</div>
                          <Message dictKey={item?.description} />
                        </div>
                      </div>
                    );
                  })}
                  {dataConfigurePoint?.map((item, index) => {
                    return (
                      <div
                        className={
                          index % 2 === 0
                            ? "configure-wrp"
                            : "configure-wrp rev"
                        }
                      >
                        <input
                          type="image"
                          src={item?.img}
                          alt={item?.alt}
                          className="variant-img"
                          onClick={() => handleImageClick(item?.pngImg)}
                        />
                        <div className="configure-steps-wrp">
                          {item?.description?.map((paragraphs, index) => {
                            return (
                              <div className="email-SMTP-step">
                                <div className="bullet"></div>
                                <Message dictKey={paragraphs} />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                  {definePointsForStore?.map((item, index) => {
                    return (
                      <div
                        className={
                          index % 2 === 0 || index === 7
                            ? "configure-wrp"
                            : "configure-wrp rev"
                        }
                      >
                        <div className="configure-steps-wrapper">
                          <div className="step-index">{index + 1}.</div>
                          <Message dictKey={item?.description} />
                        </div>
                        {item?.img && (
                          <input
                            type="image"
                            src={item?.img}
                            alt={item?.alt}
                            className="variant-img"
                            onClick={() => handleImageClick(item?.pngImg)}
                          />
                        )}
                      </div>
                    );
                  })}
                  <p className="delivery-uninstall-heading">
                    <Message dictKey="what-are-reviews-ratings.slot.qn2-heading" />
                  </p>
                  {dataPoints?.map((detail, index) => (
                    <div
                      className={
                        index === 0
                          ? "configure-wrp"
                          : index > 0 && index % 2 === 0
                          ? "configure-wrp rev"
                          : "configure-wrp"
                      }
                    >
                      <div className="email-SMTP-step">
                        <div className="step-index">{index + 1}.</div>
                        <Message dictKey={detail?.description} />
                      </div>
                      {detail?.img && (
                        <div className="delivery-img">
                          <input
                            type="image"
                            src={detail?.img}
                            alt={detail?.alt}
                            className="variant-img"
                            onClick={() => handleImageClick(detail?.pngImg)}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                  <div>
                    <p className="eazy-detailed-blog-description">
                      <Message dictKey="referral-management.slot.affect" />
                    </p>
                  </div>
                  {affectPoints?.map((item, index) => {
                    return (
                      <div>
                        {item?.description && (
                          <div className="email-SMTP-step">
                            <div className="step-index">{item.id}.</div>
                            <Message dictKey={item.description} />
                          </div>
                        )}
                        {item?.note && (
                          <div className="email-SMTP-step">
                            <Message dictKey={item.note} />
                          </div>
                        )}
                        {item?.img && (
                          <div className="delivery-img">
                            <input
                              type="image"
                              src={item?.img}
                              alt={item?.alt}
                              className="variant-img"
                              onClick={() => handleImageClick(item?.pngImg)}
                            />
                          </div>
                        )}
                      </div>
                    );
                  })}
                  <p className="delivery-uninstall-heading">
                    <Message dictKey="uninstall.heading" />
                  </p>
                  {uninstallPoint?.map((item, index) => {
                    return (
                      <div>
                        <div className="uninstall-wrp">
                          <div className="step-index">{index + 1}.</div>
                          <Message dictKey={item?.description} />
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              {blog.slug === "what-is-customer-wallet" && (
                <div>
                  {configurePoints?.map((item, index) => {
                    return (
                      <div className={"configure-wrp"}>
                        {item?.img && (
                          <input
                            type="image"
                            src={item?.img}
                            alt={item?.alt}
                            className="seo-variant-img"
                            onClick={() => handleImageClick(item?.pngImg)}
                          />
                        )}
                        <div className="configure-steps-wrapper">
                          <Message dictKey={item?.description} />
                        </div>
                      </div>
                    );
                  })}
                  {dataPoints?.map((detail, index) => (
                    <>
                      <p className="delivery-uninstall-heading">
                        <Message dictKey="customer-wallet-support.qn3-heading" />
                      </p>
                      <div
                        className={
                          index % 2 === 1
                            ? "configure-wrp"
                            : "configure-wrp rev"
                        }
                      >
                        <div className="configure-steps-wrapper">
                          <Message dictKey={detail?.description} />
                        </div>
                        <div className="">
                          <input
                            type="image"
                            src={detail?.img}
                            alt={detail?.alt}
                            className="variant-img"
                            onClick={() => handleImageClick(detail?.pngImg)}
                          />
                        </div>
                      </div>
                    </>
                  ))}
                  <p className="delivery-uninstall-heading">
                    <Message dictKey="customer-wallet-support.qn3-heading" />
                  </p>
                  {definePoints?.map((item, index) => {
                    return (
                      <div>
                        {item?.id === 4 || item?.id === 7 ? (
                          <div>
                            <div className="email-SMTP-step">
                              <div className="step-index">{index + 1}.</div>
                              <Message dictKey={item.description} />
                            </div>
                            {item?.note && (
                              <div className="email-SMTP-step">
                                <Message dictKey={item.note} />
                              </div>
                            )}
                            {item?.img && (
                              <div className="delivery-img">
                                <input
                                  type="image"
                                  src={item?.img}
                                  alt={item?.alt}
                                  className="variant-img"
                                  onClick={() => handleImageClick(item?.pngImg)}
                                />
                              </div>
                            )}
                            {item?.mainPoints &&
                              item.mainPoints.length > 0 &&
                              item?.mainPoints?.map((point) => {
                                return (
                                  <div className="main-points">
                                    <div className="bullet"></div>
                                    <Message dictKey={point.point} />
                                  </div>
                                );
                              })}
                          </div>
                        ) : (
                          <div
                            className={
                              index < 6
                                ? index % 2 === 0
                                  ? "configure-wrp rev"
                                  : "configure-wrp"
                                : index % 2 === 0
                                ? "configure-wrp "
                                : "configure-wrp rev"
                            }
                          >
                            <div className="configure-steps-wrapper">
                              <div className="step-index">{index + 1}.</div>
                              <Message dictKey={item?.description} />
                            </div>
                            {item?.img && (
                              <input
                                type="image"
                                src={item?.img}
                                alt={item?.alt}
                                className="variant-img"
                                onClick={() => handleImageClick(item?.pngImg)}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    );
                  })}
                  <div>
                    <p className="delivery-uninstall-heading">
                      <Message dictKey="referral-management.slot.affect" />
                    </p>
                  </div>
                  {affectPoints?.map((item, index) => {
                    const { id = "", img = "" } = item;
                    return (
                      <div className="configure-wrf-affect-block">
                        {img && (
                          <div className="delivery-img">
                            <input
                              type="image"
                              src={item?.img}
                              alt={item?.alt}
                              className="seo-variant-img"
                              onClick={() => handleImageClick(item?.pngImg)}
                            />
                          </div>
                        )}

                        <div className="email-SMTP-step">
                          {id && <div className="step-index">{id}.</div>}
                          <Message dictKey={item.description} />
                        </div>
                      </div>
                    );
                  })}
                  <p className="delivery-uninstall-heading">
                    <Message dictKey="uninstall.heading" />
                  </p>
                  {uninstallPoint?.map((item, index) => {
                    return (
                      <div>
                        <div className="uninstall-wrp">
                          <Message dictKey={item?.description} />
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              {blog.slug === "what-are-custom-fields" && (
                <div>
                  {configurePoints?.map((item, index) => {
                    return (
                      <div
                        className={
                          index === 0
                            ? "configure-wrp"
                            : index > 0 && index % 2 === 0
                            ? "configure-wrp rev"
                            : "configure-wrp"
                        }
                      >
                        {item?.img && (
                          <input
                            type="image"
                            src={item?.img}
                            alt={item?.alt}
                            className="seo-variant-img"
                            onClick={() => handleImageClick(item?.pngImg)}
                          />
                        )}
                        <div className="eazy-detailed-blog-explain">
                          <div className="eazy-detailed-blog-content">
                            {index + 1}.
                          </div>
                          <Message dictKey={item?.description} />
                        </div>
                      </div>
                    );
                  })}
                  {dataConfigurePoint?.map((item, index) => {
                    return (
                      <div
                        className={
                          index % 2 === 0
                            ? "configure-wrp"
                            : "configure-wrp rev"
                        }
                      >
                        <input
                          type="image"
                          src={item?.img}
                          alt={item?.alt}
                          className="variant-img"
                          onClick={() => handleImageClick(item?.pngImg)}
                        />
                        <div className="configure-steps-wrp">
                          {item?.description?.map((paragraphs, index) => {
                            return (
                              <div className="email-SMTP-step">
                                <div className="bullet"></div>
                                <Message dictKey={paragraphs} />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                  <p className="delivery-uninstall-heading">
                    <Message dictKey="custom-fields.slot.qn2-heading" />
                  </p>
                  {dataPoints?.map((detail, index) => (
                    <div
                      className={
                        index % 2 === 1 ? "configure-wrp" : "configure-wrp rev"
                      }
                    >
                      <div className="configure-steps-wrapper">
                        <div className="step-index">{index + 1}.</div>
                        <Message dictKey={detail?.description} />
                      </div>
                      <div className="delivery-img">
                        <input
                          type="image"
                          src={detail?.img}
                          alt={detail?.alt}
                          className="variant-img"
                          onClick={() => handleImageClick(detail?.pngImg)}
                        />
                      </div>
                    </div>
                  ))}
                  <p className="delivery-uninstall-heading">
                    <Message dictKey="custom-fields.slot.qn3-heading" />
                  </p>
                  {definePoints?.map((item, index) => {
                    return (
                      <div>
                        {item?.id === 4 || item?.id === 7 ? (
                          <div>
                            <div className="email-SMTP-step">
                              <div className="step-index">{index + 1}.</div>
                              <Message dictKey={item.description} />
                            </div>
                            {item?.note && (
                              <div className="email-SMTP-step">
                                <Message dictKey={item.note} />
                              </div>
                            )}
                            {item?.img && (
                              <div className="delivery-img">
                                <input
                                  type="image"
                                  src={item?.img}
                                  alt={item?.alt}
                                  className="variant-img"
                                  onClick={() => handleImageClick(item?.pngImg)}
                                />
                              </div>
                            )}
                            {item?.mainPoints &&
                              item.mainPoints.length > 0 &&
                              item?.mainPoints?.map((point) => {
                                return (
                                  <div className="main-points">
                                    <div className="bullet"></div>
                                    <Message dictKey={point.point} />
                                  </div>
                                );
                              })}
                          </div>
                        ) : (
                          <div
                            className={
                              index < 6
                                ? index % 2 === 0
                                  ? "configure-wrp rev"
                                  : "configure-wrp"
                                : index % 2 === 0
                                ? "configure-wrp "
                                : "configure-wrp rev"
                            }
                          >
                            <div className="configure-steps-wrapper">
                              <div className="step-index">{index + 1}.</div>
                              <Message dictKey={item?.description} />
                            </div>
                            {item?.img && (
                              <input
                                type="image"
                                src={item?.img}
                                alt={item?.alt}
                                className="variant-img"
                                onClick={() => handleImageClick(item?.pngImg)}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    );
                  })}
                  <div>
                    <p className="delivery-uninstall-heading">
                      <Message dictKey="referral-management.slot.affect" />
                    </p>
                  </div>
                  {affectPoints?.map((item, index) => {
                    return (
                      <div>
                        <div className="email-SMTP-step">
                          <div className="step-index">{index + 1}.</div>
                          <Message dictKey={item.description} />
                        </div>
                        <div className="delivery-img">
                          <input
                            type="image"
                            src={item?.img}
                            alt={item?.alt}
                            className="seo-variant-img"
                            onClick={() => handleImageClick(item?.pngImg)}
                          />
                        </div>
                      </div>
                    );
                  })}
                  <p className="delivery-uninstall-heading">
                    <Message dictKey="uninstall.heading" />
                  </p>
                  {uninstallPoint?.map((item, index) => {
                    return (
                      <div>
                        <div className="uninstall-wrp">
                          <div className="step-index">{index + 1}.</div>
                          <Message dictKey={item?.description} />
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              {blog.slug === "introduction-to-ondc" && (
                <div className="ondc-operate-img-wrp">
                  <img src={ondcOperate} alt="add-img-for-ondc-operate" />
                </div>
              )}
              {OtherDescriptions?.items?.map((des) => (
                <p className="eazy-detailed-blog-description">
                  <Message dictKey={des.description} />
                </p>
              ))}
              {blog.slug === "introduction-to-ondc" && (
                <div className="advantage-of-ondc">
                  <h2 className="eazy-detailed-blog-bullets-heading">
                    <span>
                      <Message
                        dictKey={"advantage-of-ondc-from-a-seller-perspective"}
                      />
                    </span>
                  </h2>
                  {dotDescription?.items?.map((point) => (
                    <div className="disc-container">
                      <span class="small-dot"></span>
                      <p className="eazy-detailed-blog-description">
                        <Message dictKey={point.description} />
                      </p>
                    </div>
                  ))}
                </div>
              )}
              {someDescription?.items?.map((des) => (
                <p className="eazy-detailed-blog-description">
                  <Message dictKey={des.description} />
                </p>
              ))}
              {blog.slug === "introduction-to-ondc" && (
                <div className="sign-up">
                  <Message dictKey="signup.for.ondc.via.zopping" />
                </div>
              )}
              {blog.slug ===
                "how-to-add-logo-and-favicon-to-your-online-store" && (
                <div className="store-logo-appear-img-wrp">
                  <img src={howToAddLogoParaOne} alt="how-to-add-logo" />
                </div>
              )}
              {MoreHeading?.items?.map((detail) => (
                <h2 class="eazy-detailed-blog-inside-heading">
                  <Message dictKey={detail.description} />
                </h2>
              ))}
              {blog.slug ===
                "how-to-add-logo-and-favicon-to-your-online-store" && (
                <div className="add-logo-and-favicon-sub-container">
                  <p>
                    <Message
                      dictKey={
                        "how.to.add.logo.favicon.additional.para.1.description"
                      }
                    />
                  </p>
                  <div className="grocery-shop-image-wrp">
                    <img src={howToAddLogoParaTwo} alt="how-to-add-logo" />
                  </div>
                  <h2>
                    <Message
                      dictKey={"how.to.add.logo.favicon.more.heading.title.one"}
                    />
                  </h2>
                  <div className="configure-logo-and-favicon-wrp">
                    <div className="description">
                      {ImageRelatedPoints?.items?.map((detail) => (
                        <Message dictKey={detail.description} />
                      ))}
                    </div>
                    {ImageForContent && (
                      <div className="image">
                        <img src={ImageForContent} alt="addProductMobileImg" />
                      </div>
                    )}
                  </div>
                  <p className="how-to-add-logo-favicon-para">
                    <Message
                      dictKey={"how.to.add.logo.favicon.para.2.description"}
                    />
                  </p>
                  <h>
                    <Message
                      dictKey={"how.to.add.logo.favicon.more.heading.title.two"}
                    />
                  </h>
                  <div className="note-logo-and-favicon-wrp">
                    <div className="image">
                      <img src={howToAddLogoUploadImg} alt="" />
                    </div>
                    <div className="description">
                      <Message
                        dictKey={
                          "how.to.add.logo.favicon.note.para.one.description"
                        }
                      />
                      <Message
                        dictKey={
                          "how.to.add.logo.favicon.note.para.two.description"
                        }
                      />
                      <Message
                        dictKey={
                          "how.to.add.logo.favicon.note.para.three.description"
                        }
                      />
                      <Message
                        dictKey={
                          "how.to.add.logo.favicon.note.para.four.description"
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
              {blog.slug === "how-to-add-products-to-your-online-store" && (
                <div className="image-slider-outer">
                  <ImageSlideshow arrowAroundContent restrictAutoScroll>
                    <div className="explanation-field">
                      <AddProductName className="image" />

                      <div className="description">
                        <Message dictKey="add.product.productname.para" />
                        <br></br>
                        <Message dictKey="add.product.productname.para.example" />
                      </div>
                    </div>
                    <div className="explanation-field">
                      <AddProductCategory className="image" />

                      <div className="description">
                        <Message dictKey="add.product.category.para" />
                        <br></br>
                        <Message dictKey="add.product.category.para.example" />
                        <br></br>
                        <Message dictKey="add.product.category.para.example.note" />
                      </div>
                    </div>
                    <div className="explanation-field">
                      <AddProductDescription className="image" />

                      <div className="description">
                        <Message dictKey="add.product.description.para" />
                        <br></br>
                        <Message dictKey="add.product.description.para.example" />
                        <br></br>
                        <Message dictKey="add.product.description.para.example.one" />
                        <br></br>
                        <Message dictKey="add.product.description.para.example.two" />
                        <br></br>
                        <Message dictKey="add.product.description.para.example.three" />
                        <br></br>
                        <Message dictKey="add.product.description.para.example.four" />
                      </div>
                    </div>
                    <div className="explanation-field">
                      <AddProductUploadImg className="image" />

                      <div className="description">
                        <Message dictKey="add.product.upload.image.para" />
                      </div>
                    </div>
                    <div className="explanation-field">
                      <AddProductBarcode className="image" />

                      <div className="description">
                        <Message dictKey="add.product.barcode.image.para" />
                        <br></br>
                        <Message dictKey="add.product.barcode.note.para" />
                      </div>
                    </div>
                    <div className="explanation-field">
                      <AddProductSellingPrice className="image" />

                      <div className="description">
                        <Message dictKey="add.product.sellingprice.para" />
                        <br></br>
                        <Message dictKey="add.product.sellingprice.note.para" />
                      </div>
                    </div>
                    <div className="explanation-field">
                      <AddProductDiscountPrice className="image" />

                      <div className="description">
                        <Message dictKey="add.product.discount.para" />
                        <br></br>
                      </div>
                    </div>
                    <div className="explanation-field">
                      <AddProductTax className="image" />
                      <div className="description">
                        <Message dictKey="add.product.tax.para" />
                        <br></br>
                        <Message dictKey="add.product.tax.note.para" />
                      </div>
                    </div>
                    <div className="explanation-field">
                      <AddProductVariantQuantity className="image" />

                      <div className="description">
                        <Message dictKey="add.product.quantity.para" />
                        <br></br>
                        <Message dictKey="add.product.quantity.note.para" />
                      </div>
                    </div>
                  </ImageSlideshow>
                </div>
              )}
              {blog.slug === "custom-domain-setup" && (
                <div className="custom-domain">
                  {dotDescription?.items?.map((point) => (
                    <div className="disc-container">
                      <span class="small-dot"></span>
                      <p className="eazy-detailed-blog-description">
                        <Message dictKey={point.description} />
                      </p>
                    </div>
                  ))}
                  <div className="reference-heading">
                    <span>
                      <Message dictKey={"custom.domain.refrence.heading"} />
                    </span>
                  </div>
                  <div className="custom-domain-setup-image-container">
                    <div className="custom-domain-setup-image">
                      <div className="custom-domain-setup-logo">
                        <img
                          src={isMobile ? goDaddyMobile : goDaddyDesktop}
                          alt="goDaddy"
                        />
                      </div>
                      <div className="custom-domain-setup-text-container">
                        <div className="custom-domain-setup-text">
                          How to Add
                        </div>
                        <a
                          className="custom-domain-setup-link"
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://in.godaddy.com/help/add-a-cname-record-19236"
                        >
                          CNAME
                        </a>
                      </div>
                    </div>
                    <div className="custom-domain-setup-image">
                      <div className="custom-domain-setup-logo">
                        <img
                          src={isMobile ? oneComMobile : oneComDesktop}
                          alt="oneCom"
                        />
                      </div>
                      <div className="custom-domain-setup-text-container">
                        <div className="custom-domain-setup-text">
                          How to Add
                        </div>
                        <a
                          className="custom-domain-setup-link"
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://help.one.com/hc/en-us/articles/360000803517-How-do-I-create-a-CNAME-record-"
                        >
                          CNAME
                        </a>
                      </div>
                    </div>
                    <div className="custom-domain-setup-image">
                      <div className="custom-domain-setup-logo">
                        <img
                          src={isMobile ? domainMobile : domainDesktop}
                          alt="domainCom"
                        />
                      </div>
                      <div className="custom-domain-setup-text-container">
                        <div className="custom-domain-setup-text">
                          How to Add
                        </div>
                        <a
                          className="custom-domain-setup-link"
                          rel="noopener noreferrer"
                          target="_blank"
                          href="https://www.domain.com/help/article/dns-management-how-to-update-cname-aliases"
                        >
                          CNAME
                        </a>
                      </div>
                    </div>
                    <div className="custom-domain-setup-image">
                      <div className="custom-domain-setup-logo">
                        <img
                          src={isMobile ? hoverMobile : hoverDesktop}
                          alt="hover"
                        />
                      </div>
                      <div className="custom-domain-setup-text-container">
                        <div className="custom-domain-setup-text">
                          How to Add
                        </div>
                        <a
                          className="custom-domain-setup-link"
                          rel="noopener noreferrer"
                          target="_blank"
                          href="https://support.doublethedonation.com/portal/en/kb/articles/setting-up-your-cname-with-hover"
                        >
                          CNAME
                        </a>
                      </div>
                    </div>
                    <div className="custom-domain-setup-image">
                      <div className="custom-domain-setup-logo">
                        <img
                          src={isMobile ? bigrockMobile : bigrockDesktop}
                          alt="bigRock"
                        />
                      </div>
                      <div className="custom-domain-setup-text-container">
                        <div className="custom-domain-setup-text">
                          How to Add
                        </div>
                        <a
                          className="custom-domain-setup-link"
                          rel="noopener noreferrer"
                          target="_blank"
                          href="https://support.bigrock.com/index.php?/Knowledgebase/Article/View/1217/5/adding-a-cname-record-on-dns-management"
                        >
                          CNAME
                        </a>
                      </div>
                    </div>
                    <div className="custom-domain-setup-image">
                      <div className="custom-domain-setup-logo">
                        <img src={hostinger} alt="hostinger" />
                      </div>
                      <div className="custom-domain-setup-text-container">
                        <div className="custom-domain-setup-text">
                          How to Add
                        </div>
                        <a
                          className="custom-domain-setup-link"
                          rel="noopener noreferrer"
                          target="_blank"
                          href="https://www.hostinger.in/tutorials/how-to-use-hostinger-dns-zone-editor#What_Is_an_A_Record"
                        >
                          CNAME
                        </a>
                      </div>
                    </div>
                  </div>
                  <br></br>
                  <p className="custom-domain-setup-para">
                    <Message dictKey={"custom-domain-page.2.description"} />
                  </p>

                  <h2>
                    <Message dictKey={"custom-domain-setup-heading-2"} />
                  </h2>

                  <p className="custom-domain-setup-para">
                    <Message dictKey={"custom-domain-setup-description-3"} />
                  </p>
                  <img
                    src={
                      isMobile
                        ? customDomainSetupImage1Mobile
                        : customDomainSetupImage1Desktop
                    }
                    alt=""
                  />
                  <p className="custom-domain-setup-para">
                    <Message dictKey={"custom-domain-setup-description-4"} />
                  </p>
                </div>
              )}
              {blog.slug === "add-products-faqs" && (
                <div className="add-products-faqs">
                  <h2 className="eazy-detailed-blog-bullets-heading">
                    <span>
                      <Message
                        dictKey={
                          "add.products.faqs.question.one.para.description"
                        }
                      />
                    </span>
                  </h2>
                  <p>
                    <Message
                      dictKey={"add.products.faqs.answer.one.para.description"}
                    />
                  </p>
                  <div className="eazy-detailed-blog-img-outer-new">
                    <img src={ImageForContent} alt="" />
                  </div>
                  <h2 className="eazy-detailed-blog-bullets-heading">
                    <span>
                      <Message
                        dictKey={
                          "add.products.faqs.question.two.para.description"
                        }
                      />
                    </span>
                  </h2>
                  <p>
                    <Message
                      dictKey={"add.products.faqs.answer.two.para.description"}
                    />
                  </p>
                  <h2 className="eazy-detailed-blog-bullets-heading">
                    <span>
                      <Message
                        dictKey={
                          "add.products.faqs.question.three.para.description"
                        }
                      />
                    </span>
                  </h2>
                  <p>
                    <Message
                      dictKey={
                        "add.products.faqs.answer.three.para.description"
                      }
                    />
                  </p>
                  <h2 className="eazy-detailed-blog-bullets-heading">
                    <Message
                      dictKey={
                        "add.products.faqs.question.four.para.description"
                      }
                    />
                  </h2>
                  <div className="add-products-faqs-points-para">
                    <p className="eazy-detailed-blog-description">
                      <img src={star} alt="" />
                      <Message
                        dictKey={
                          "add.products.faqs.answer.four.para.description"
                        }
                      />
                    </p>
                    <p className="eazy-detailed-blog-description">
                      <img src={star} alt="" />
                      <Message
                        dictKey={
                          "add.products.faqs.answer.four.para.description.one"
                        }
                      />
                    </p>
                    <p className="eazy-detailed-blog-description">
                      <img src={star} alt="" />
                      <Message
                        dictKey={
                          "add.products.faqs.answer.four.para.description.two"
                        }
                      />
                    </p>
                    <p className="eazy-detailed-blog-description">
                      <img src={star} alt="" />
                      <Message
                        dictKey={
                          "add.products.faqs.answer.four.para.description.three"
                        }
                      />
                    </p>
                    <p className="eazy-detailed-blog-description">
                      <img src={star} alt="" />
                      <Message
                        dictKey={
                          "add.products.faqs.answer.four.para.description.four"
                        }
                      />
                    </p>
                  </div>
                  <p>
                    <Message
                      dictKey={"add.products.faqs.paragraph.description"}
                    />
                  </p>
                </div>
              )}
              {blog.slug === "create-discount-coupons-zopping" && (
                <div className="coupon-key">
                  <h2 className="eazy-detailed-blog-bullets-heading">
                    <span>
                      <Message
                        dictKey={"create-discount-coupons-zopping-question1"}
                      />
                    </span>
                  </h2>

                  <div className="coupon-key-container-1">
                    <img
                      src={couponImage1}
                      className="coupon-key-image"
                      alt=""
                    />
                    <div className="coupon-key-text">
                      <div>
                        <h2>
                          <Message
                            dictKey={
                              "create-discount-coupons-zopping-question1-step-heading1"
                            }
                          />
                        </h2>
                        <div className="coupon-key-item">
                          <span class="small-disk"></span>
                          <Message
                            dictKey={
                              "create-discount-coupons-zopping-question1-step1"
                            }
                          />
                        </div>
                        <div className="coupon-key-item">
                          <span class="small-disk"></span>
                          <Message
                            dictKey={
                              "create-discount-coupons-zopping-question1-step2"
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="coupon-key-container-2">
                    <div className="coupon-key-text">
                      <div>
                        <h2>
                          <Message
                            dictKey={
                              "create-discount-coupons-zopping-question1-step-heading2"
                            }
                          />
                        </h2>
                        <div className="coupon-key-item">
                          <span class="small-disk"></span>
                          <p>
                            <Message
                              dictKey={
                                "create-discount-coupons-zopping-question1-step3"
                              }
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                    <img
                      src={couponImage2}
                      className="coupon-key-image"
                      alt=""
                    />
                  </div>
                  <div className="coupon-key-container-1">
                    <img
                      src={couponImage3}
                      className="coupon-key-image"
                      alt=""
                    />
                    <div className="coupon-key-text">
                      <div>
                        <h2>
                          <Message
                            dictKey={
                              "create-discount-coupons-zopping-question1-step-heading3"
                            }
                          />
                        </h2>
                        <div className="coupon-key-item">
                          <span class="small-disk"></span>
                          <p className="eazy-detailed-blog-description">
                            <Message
                              dictKey={
                                "create-discount-coupons-zopping-question1-step4"
                              }
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <h2 className="eazy-detailed-blog-bullets-heading coupon-detaied-points">
                    <Message
                      dictKey={
                        "create-discount-coupons-zopping-question1-sub-heading1"
                      }
                    />
                  </h2>

                  <div className="text-description">
                    <h2 className="text-description-heading">
                      <Message
                        dictKey={
                          "create-discount-coupons-zopping-question1-sub-heading1-point1"
                        }
                      />
                    </h2>
                    <p className="text-description-body">
                      <Message
                        dictKey={
                          "create-discount-coupons-zopping-question1-sub-heading1-point1-body"
                        }
                      />
                    </p>
                  </div>

                  <div className="text-description">
                    <h2 className="text-description-heading">
                      <Message
                        dictKey={
                          "create-discount-coupons-zopping-question1-sub-heading1-point2"
                        }
                      />
                    </h2>
                    <p className="text-description-body">
                      <Message
                        dictKey={
                          "create-discount-coupons-zopping-question1-sub-heading1-point2-body"
                        }
                      />
                    </p>
                  </div>

                  <div className="text-description">
                    <h2 className="text-description-heading">
                      <Message
                        dictKey={
                          "create-discount-coupons-zopping-question1-sub-heading1-point3"
                        }
                      />
                    </h2>
                    <p className="text-description-body">
                      <Message
                        dictKey={
                          "create-discount-coupons-zopping-question1-sub-heading1-point3-body"
                        }
                      />
                    </p>
                  </div>
                  <div className="text-description">
                    <h2 className="text-description-heading">
                      <Message
                        dictKey={
                          "create-discount-coupons-zopping-question1-sub-heading1-point4"
                        }
                      />
                    </h2>
                    <p className="text-description-body">
                      <Message
                        dictKey={
                          "create-discount-coupons-zopping-question1-sub-heading1-point4-body"
                        }
                      />
                    </p>
                  </div>
                  <div className="text-description">
                    <h2 className="text-description-heading">
                      <Message
                        dictKey={
                          "create-discount-coupons-zopping-question1-sub-heading1-point5"
                        }
                      />
                    </h2>
                    <p className="text-description-body">
                      <Message
                        dictKey={
                          "create-discount-coupons-zopping-question1-sub-heading1-point5-body"
                        }
                      />
                    </p>
                  </div>
                  <div className="text-description">
                    <h2 className="text-description-heading">
                      <Message
                        dictKey={
                          "create-discount-coupons-zopping-question1-sub-heading1-point6"
                        }
                      />
                    </h2>
                    <p className="text-description-body">
                      <Message
                        dictKey={
                          "create-discount-coupons-zopping-question1-sub-heading1-point6-body"
                        }
                      />
                    </p>
                  </div>
                  <div className="text-description">
                    <h2 className="text-description-heading">
                      <Message
                        dictKey={
                          "create-discount-coupons-zopping-question1-sub-heading1-point7"
                        }
                      />
                    </h2>
                    <p className="text-description-body">
                      <Message
                        dictKey={
                          "create-discount-coupons-zopping-question1-sub-heading1-point7-body"
                        }
                      />
                    </p>
                  </div>
                  <div className="text-description">
                    <h2 className="text-description-heading">
                      <Message
                        dictKey={
                          "create-discount-coupons-zopping-question1-sub-heading1-point8"
                        }
                      />
                    </h2>
                    <p className="text-description-body">
                      <Message
                        dictKey={
                          "create-discount-coupons-zopping-question1-sub-heading1-point8-body"
                        }
                      />
                    </p>
                  </div>
                  <div className="text-description">
                    <h2 className="text-description-heading">
                      <Message
                        dictKey={
                          "create-discount-coupons-zopping-question1-sub-heading1-point9"
                        }
                      />
                    </h2>
                    <p className="text-description-body">
                      <Message
                        dictKey={
                          "create-discount-coupons-zopping-question1-sub-heading1-point9-body"
                        }
                      />
                    </p>
                  </div>
                  <div className="text-description">
                    <h2 className="text-description-heading">
                      <Message
                        dictKey={
                          "create-discount-coupons-zopping-question1-sub-heading1-point10"
                        }
                      />
                    </h2>
                    <p className="text-description-body">
                      <Message
                        dictKey={
                          "create-discount-coupons-zopping-question1-sub-heading1-point10-body"
                        }
                      />
                    </p>
                  </div>
                  <div className="text-description">
                    <h2 className="text-description-heading">
                      <Message
                        dictKey={
                          "create-discount-coupons-zopping-question1-sub-heading1-point11"
                        }
                      />
                    </h2>
                    <p className="text-description-body">
                      <Message
                        dictKey={
                          "create-discount-coupons-zopping-question1-sub-heading1-point11-body"
                        }
                      />
                    </p>
                  </div>
                  <div className="text-description">
                    <h2 className="text-description-heading">
                      <Message
                        dictKey={
                          "create-discount-coupons-zopping-question1-sub-heading1-point12"
                        }
                      />
                    </h2>
                    <p className="text-description-body">
                      <Message
                        dictKey={
                          "create-discount-coupons-zopping-question1-sub-heading1-point12-body"
                        }
                      />
                    </p>
                  </div>
                  <div className="text-description">
                    <h2 className="text-description-heading">
                      <Message
                        dictKey={
                          "create-discount-coupons-zopping-question1-sub-heading1-point13"
                        }
                      />
                    </h2>
                    <p className="text-description-body">
                      <Message
                        dictKey={
                          "create-discount-coupons-zopping-question1-sub-heading1-point13-body"
                        }
                      />
                    </p>
                  </div>
                  <div className="text-description">
                    <h2 className="text-description-heading">
                      <Message
                        dictKey={
                          "create-discount-coupons-zopping-question1-sub-heading1-point14"
                        }
                      />
                    </h2>
                    <p className="text-description-body">
                      <Message
                        dictKey={
                          "create-discount-coupons-zopping-question1-sub-heading1-point14-body"
                        }
                      />
                    </p>
                  </div>
                  <div className="text-description">
                    <h2 className="text-description-heading">
                      <Message
                        dictKey={
                          "create-discount-coupons-zopping-question1-sub-heading1-point15"
                        }
                      />
                    </h2>
                    <p className="text-description-body">
                      <Message
                        dictKey={
                          "create-discount-coupons-zopping-question1-sub-heading1-point15-body"
                        }
                      />
                    </p>
                  </div>
                  <div className="text-description">
                    <h2 className="text-description-heading">
                      <Message
                        dictKey={
                          "create-discount-coupons-zopping-question1-sub-heading1-point16"
                        }
                      />
                    </h2>
                    <p className="text-description-body">
                      <Message
                        dictKey={
                          "create-discount-coupons-zopping-question1-sub-heading1-point16-body"
                        }
                      />
                    </p>
                  </div>

                  <h2 id="eazy-detailed-blog-bullets-heading">
                    <Message
                      dictKey={"create-discount-coupons-zopping-question2"}
                    />
                  </h2>
                  <p className="eazy-detailed-blog-description">
                    <Message
                      dictKey={
                        "create-discount-coupons-zopping-question2-answer"
                      }
                    />
                  </p>

                  <h2 id="eazy-detailed-blog-bullets-heading">
                    <Message
                      dictKey={"create-discount-coupons-zopping-question3"}
                    />
                  </h2>
                  <p className="eazy-detailed-blog-description">
                    <Message
                      dictKey={
                        "create-discount-coupons-zopping-question3-answer"
                      }
                    />
                  </p>

                  <h2 id="eazy-detailed-blog-bullets-heading">
                    <Message
                      dictKey={"create-discount-coupons-zopping-question4"}
                    />
                  </h2>
                  <p className="eazy-detailed-blog-description">
                    <Message
                      dictKey={
                        "create-discount-coupons-zopping-question4-answer"
                      }
                    />
                  </p>

                  <div className="coupon-key-container-1">
                    <img
                      src={couponImage4}
                      className="coupon-key-image"
                      alt=""
                    />
                    <div className="coupon-key-text">
                      <div>
                        <h2>
                          <Message
                            dictKey={
                              "create-discount-coupons-zopping-question4-step-heading1"
                            }
                          />
                        </h2>
                        <div className="coupon-key-item">
                          <span class="small-disk"></span>
                          <p className="eazy-detailed-blog-description">
                            <Message
                              dictKey={
                                "create-discount-coupons-zopping-question4-step1"
                              }
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="coupon-key-container-2">
                    <div className="coupon-key-text">
                      <div>
                        <h2>
                          <Message
                            dictKey={
                              "create-discount-coupons-zopping-question4-step-heading2"
                            }
                          />
                        </h2>
                        <div className="coupon-key-item">
                          <span class="small-disk"></span>
                          <p>
                            <Message
                              dictKey={
                                "create-discount-coupons-zopping-question4-step2"
                              }
                            />
                          </p>
                        </div>
                        <div className="coupon-key-item">
                          <span class="small-disk"></span>
                          <p>
                            <Message
                              dictKey={
                                "create-discount-coupons-zopping-question4-step3"
                              }
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                    <img
                      src={couponImage5}
                      className="coupon-key-image"
                      alt=""
                    />
                  </div>
                  <div className="coupon-key-container-1">
                    <img
                      src={couponImage6}
                      className="coupon-key-image"
                      alt=""
                    />
                    <div className="coupon-key-text">
                      <div>
                        <h2>
                          <Message
                            dictKey={
                              "create-discount-coupons-zopping-question4-step-heading3"
                            }
                          />
                        </h2>
                        <div className="coupon-key-item">
                          <span class="small-disk"></span>
                          <p className="eazy-detailed-blog-description">
                            <Message
                              dictKey={
                                "create-discount-coupons-zopping-question4-step4"
                              }
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {blog.slug === "how-to-create-google-map-api-key" && (
                <div className="google-api-key">
                  <h2 className="eazy-detailed-blog-bullets-heading">
                    <span>
                      <Message dictKey={"google-api-key-question3-heading"} />
                    </span>
                  </h2>
                  <div className="disc-container">
                    <span className="number-list">1.</span>
                    <p className="eazy-detailed-blog-description">
                      <Message dictKey={"google-api-key-answer3-step1"} />
                    </p>
                  </div>
                  <div className="disc-container">
                    <span className="number-list">2.</span>
                    <p className="eazy-detailed-blog-description">
                      <Message dictKey={"google-api-key-answer3-step2"} />
                    </p>
                  </div>
                  <div className="disc-container">
                    <span className="number-list">3.</span>
                    <p className="eazy-detailed-blog-description">
                      <Message dictKey={"google-api-key-answer3-step3"} />
                    </p>
                  </div>
                  <div className="disc-container">
                    <span className="number-list">4.</span>
                    <p className="eazy-detailed-blog-description">
                      <Message dictKey={"google-api-key-answer3-step4"} />
                    </p>
                  </div>
                  <h2 className="eazy-detailed-blog-bullets-heading">
                    <span>
                      <Message dictKey={"google-api-key-question4-heading"} />
                    </span>
                  </h2>
                  <div className="disc-container">
                    <span className="number-list">1.</span>
                    <p className="eazy-detailed-blog-description">
                      <Message dictKey={"google-api-key-answer4-para1"} />
                    </p>
                  </div>
                  <div className="disc-container">
                    <span className="number-list">2.</span>
                    <p className="eazy-detailed-blog-description">
                      <Message dictKey={"google-api-key-answer4-para2"} />
                    </p>
                  </div>
                  <h2 className="eazy-detailed-blog-bullets-heading">
                    <span>
                      <Message dictKey={"google-api-key-question5-heading"} />
                    </span>
                  </h2>
                  <div className="disc-container">
                    <span className="number-list">1.</span>
                    <p className="eazy-detailed-blog-description">
                      <Message dictKey={"google-api-key-answer5-step1"} />
                    </p>
                  </div>
                  <div className="disc-container">
                    <span className="number-list">2.</span>
                    <p className="eazy-detailed-blog-description">
                      <Message dictKey={"google-api-key-answer5-step2"} />
                    </p>
                  </div>
                  <div className="disc-container">
                    <span className="number-list">3.</span>
                    <p className="eazy-detailed-blog-description">
                      <Message dictKey={"google-api-key-answer5-step3"} />
                    </p>
                  </div>
                  <div className="disc-container">
                    <span className="number-list">4.</span>
                    <p className="eazy-detailed-blog-description">
                      <Message dictKey={"google-api-key-answer5-step4"} />
                    </p>
                  </div>

                  <h2 className="eazy-detailed-blog-bullets-heading">
                    <span>
                      <Message dictKey={"google-api-key-question6-heading"} />
                    </span>
                  </h2>
                  <p className="eazy-detailed-blog-description">
                    <Message dictKey={"google-api-key-answer6-para"} />
                  </p>
                  <div className="disc-container">
                    <span class="small-disk-1"></span>
                    <p className="eazy-detailed-blog-description">
                      <Message dictKey={"google-api-key-answer6-step1"} />
                    </p>
                  </div>
                  <div className="disc-container">
                    <span class="small-disk-1"></span>
                    <p className="eazy-detailed-blog-description">
                      <Message dictKey={"google-api-key-answer6-step2"} />
                    </p>
                  </div>
                  <div className="disc-container">
                    <span class="small-disk-1"></span>
                    <p className="eazy-detailed-blog-description">
                      <Message dictKey={"google-api-key-answer6-step3"} />
                    </p>
                  </div>
                  <div className="disc-container">
                    <span class="small-disk-1"></span>
                    <p className="eazy-detailed-blog-description">
                      <Message dictKey={"google-api-key-answer6-step4"} />
                    </p>
                  </div>

                  <h2 className="eazy-detailed-blog-bullets-heading">
                    <span>
                      <Message
                        dictKey={"google.api.key.login.dashboard.heading"}
                      />
                    </span>
                  </h2>
                  <div className="google-api-key-container-1">
                    <img
                      src={googleapileft}
                      className="google-api-key-image"
                      alt=""
                    />
                    <div className="google-api-key-text">
                      <div>
                        <h2>Step 1</h2>
                        <div className="google-api-key-item">
                          <span class="small-disk"></span>
                          <Message dictKey={"click-on-setting"} />
                        </div>
                        <div className="google-api-key-item">
                          <span class="small-disk"></span>
                          <Message dictKey={"click-on-api-inegration"} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="google-api-key-container-2">
                    <div className="google-api-key-text">
                      <div>
                        <h2>Step 2</h2>
                        <div className="google-api-key-item">
                          <span class="small-disk"></span>
                          <p className="eazy-detailed-blog-description">
                            <Message dictKey={"google-api-key-setup"} />
                          </p>
                        </div>
                      </div>
                    </div>
                    <img
                      src={googleapiright}
                      className="google-api-key-image"
                      alt=""
                    />
                  </div>
                  <h2 className="eazy-detailed-blog-bullets-heading">
                    <span>
                      <Message
                        dictKey={"google.api.key.question.cost.heading"}
                      />
                    </span>
                  </h2>
                  <p className="eazy-detailed-blog-description">
                    <Message
                      dictKey={"google.api.key.answer.cost.para1.description"}
                    />
                  </p>
                  <p className="eazy-detailed-blog-description">
                    <Message
                      dictKey={"google.api.key.answer.cost.para2.description"}
                    />
                  </p>
                  <p className="eazy-detailed-blog-description">
                    <Message
                      dictKey={"google.api.key.answer.cost.para3.description"}
                    />
                  </p>
                </div>
              )}
              {blog.slug ===
                "how-to-accept-online-payments-from-customers-for-their-orders-on-zopping" && (
                <div className="online-payment-setup-blog">
                  <div className="online-payments-blog-wrp">
                    <div className="online-payment-image-description">
                      {ImageForContent && (
                        <div className="eazy-detailed-blog-img">
                          <img
                            src={ImageForContent}
                            alt="addProductMobileImg"
                          />
                        </div>
                      )}
                      <div className="description">
                        {ImageRelatedPoints?.items?.map((detail) => (
                          <div className="online-payment-description-step">
                            <Message dictKey={detail.description} />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <h>
                    <Message
                      dictKey={
                        "how.to.get.the.merchant.and.api.keys.from.your.payment.gateway"
                      }
                    />
                  </h>
                  <div className="online-payment-options">
                    <div className="payment-options">
                      <Link to={`${lang}/blog/paytm-api-setup`}>
                        <div className="option">
                          <div className="option-image">
                            <img
                              src={Paytm}
                              className="payment-option-logo"
                              alt="paytm"
                            />
                          </div>
                        </div>
                      </Link>
                      <Link to={`${lang}/blog/razorpay-api-setup`}>
                        <div className="option">
                          <div className="option-image">
                            <img
                              src={Razorpay}
                              className="payment-option-logo"
                              alt="razorPay"
                            />
                          </div>
                        </div>
                      </Link>
                      <Link to={`${lang}/blog/payu-api-setup`}>
                        <div className="option">
                          <div className="option-image">
                            <img
                              src={PayU}
                              className="payment-option-logo"
                              alt="payU"
                            />
                          </div>
                        </div>
                      </Link>
                    </div>
                    {/* <div className='payment-options'>
                      <div className='option'>
                        <div className='option-image'>
                          <img src={PhonePay} className='payment-option-logo' alt="phonePay" />
                        </div>
                      </div>
                      <div className='option'>
                        <div className='option-image'>
                          <img src={PayPal} className='payment-option-logo' alt="payPal"/>
                        </div>
                      </div>
                      <div className='option'>
                        <div className='option-image'>
                          <img src={Stripe} className='payment-option-logo' alt="stripe" />
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              )}
              {blog.slug === "paytm-api-setup" && (
                <div className="configure-points">
                  <ul>
                    {importantConfigurePoints?.map((item) => (
                      <li>
                        <Message dictKey={item.point} />
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {blog.slug === "payu-api-setup" && (
                <div className="configure-points">
                  <ul>
                    {importantConfigurePoints?.map((item) => (
                      <li>
                        <Message dictKey={item.point} />
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {blog.slug === "razorpay-api-setup" && (
                <div className="configure-points">
                  <ul>
                    {importantConfigurePoints?.map((item) => (
                      <li>
                        <Message dictKey={item.point} />
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {AdditionalParagraph?.items?.map((detail) => (
                <p className="eazy-detailed-blog-description">
                  <Message dictKey="add.product.additional.para" />
                </p>
              ))}
              {blog.slug === "what-is-seo-optimization-extension" && (
                <p className="eazy-detailed-blog-description">
                  {importantPoints?.map((item, index) => {
                    return (
                      <div className="varinat-wrp">
                        {item?.description?.map((paragraphs, index) => {
                          return (
                            <div className="seo-step">
                              <Message dictKey={paragraphs} />
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                  {ImageRelatedPoints?.items?.map((des) => {
                    return (
                      <div className="seo-point-wrp">
                        <div className="seo-step">
                          <Message dictKey={des?.description} />
                        </div>
                        {des?.img && (
                          <input
                            type="image"
                            src={des?.img}
                            alt="blog-img"
                            loading="eager"
                            className="seo-variant-img"
                            onClick={() => handleImageClick(des?.pngImg)}
                          />
                        )}
                        {des?.mainPoints &&
                          des.mainPoints.length > 0 &&
                          des?.mainPoints?.map((point) => {
                            return (
                              <div className="main-points">
                                <div className="bullet"></div>
                                <Message dictKey={point.point} />
                              </div>
                            );
                          })}
                        {des?.dataConfigurePoint &&
                          des?.dataConfigurePoint?.map((item, index) => {
                            return (
                              <div
                                className={
                                  index % 2 === 0
                                    ? "seo-configure-wrp"
                                    : "seo-configure-wrp rev"
                                }
                              >
                                <input
                                  type="image"
                                  src={item?.img}
                                  alt={item?.alt}
                                  className="variant-img"
                                  onClick={() => handleImageClick(item?.pngImg)}
                                />
                                <div className="configure-steps-wrp">
                                  {item?.description?.map(
                                    (paragraphs, index) => {
                                      return (
                                        <div
                                          className={
                                            item?.bulletPoint
                                              ? "seo-bullet-step"
                                              : "seo-step"
                                          }
                                        >
                                          {item?.bulletPoint && (
                                            <div className="bullet"></div>
                                          )}
                                          <Message dictKey={paragraphs} />
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    );
                  })}
                </p>
              )}
            </div>
            {blog.slug === "five-reasons-online-store" && (
              <div onClick={handleTryZopping}>
                <div className="zopping-try">
                  contain
                  <Message dictKey="give.zopping.try" />
                </div>
              </div>
            )}
            {blog.slug === "top-five-features" && (
              <Link
                to={`${lang}/blog/how-to-accept-online-payments-from-customers-for-their-orders-on-zopping`}
              >
                <div className="zopping-try">
                  <Message dictKey="explore.prices" />
                </div>
              </Link>
            )}
            {blog.slug === "how-to-add-products-to-your-online-store" && (
              <Link to={`${lang}/blog/add-products-faqs`}>
                <div className="zopping-try">
                  <Message dictKey="explore.faqs" />
                </div>
              </Link>
            )}

            {blog.slug === "how-to-customize-footer" && (
              <div onClick={handleSetupFooter}>
                <div className="zopping-try">
                  <Message dictKey="set.up.your.footer.now" />
                </div>
              </div>
            )}
            <Popup show={showModal} close={handleCloseModal}>
              <ImagePopup
                img={selectedImage}
                show={showModal}
                close={handleCloseModal}
              />
            </Popup>
            <Popup show={showLoginForm} close={handleClickCloseLoginForm}>
              <LoginForm
                close={handleClickCloseLoginForm}
                showSignup={handleClickShowSignupForm}
                showForgotPasswordForm={handleClickShowForgotPassword}
                setIsLoggedIn={setIsLoggedIn}
              />
            </Popup>
            <Popup show={showSignupForm} close={handleClickCloseSignupForm}>
              <SignupForm
                close={handleClickCloseSignupForm}
                showLogin={handleClickShowLoginForm}
                setIsLoggedIn={setIsLoggedIn}
              />
            </Popup>
            <Popup
              show={showForgotPassword}
              close={handleClickCloseForgotPassword}
            >
              <ForgotPassword
                isDirectionRTL={isDirectionRTL}
                close={handleClickCloseForgotPassword}
                showLogin={handleClickShowLoginForm}
                setIsLoggedIn={setIsLoggedIn}
              />
            </Popup>
            {/* Not in use right now */}
            {/* <div className="eazy-detailed-blog-share-article">
              <div className="eazy-detailed-blog-share-container">
                <span>
                  {Message({ dictKey: "share.this.article" }).toUpperCase()}
                </span>
                <div className="eazy-detailed-blog-social-links-wrp">
                  <a
                    href="https://www.instagram.com/zoppingapp/"
                    className="eazy-detailed-blog-social-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Instagram className="instagram-icon" />
                    <span className="eazy-detailed-blog-social-text">
                      <Message dictKey="instagram.text" />
                    </span>
                  </a>
                  <a
                    href="https://www.facebook.com/ZoppingApp"
                    className="eazy-detailed-blog-social-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Facebook className="fb-icon" />
                    <span className="eazy-detailed-blog-social-text">
                      <Message dictKey="facebook.text" />
                    </span>
                  </a>
                  <a
                    href="https://twitter.com/ZoppingApp"
                    className="eazy-detailed-blog-social-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Twitter className="twitter-icon" />
                    <span className="eazy-detailed-blog-social-text">
                      <Message dictKey="twitter.text" />
                    </span>
                  </a>
                  <a
                    href="https://linkedin.com/in/ZoppingApp"
                    className="eazy-detailed-blog-social-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Linkedin className="linkedin-icon" />
                    <span className="eazy-detailed-blog-social-text">
                      <Message dictKey="linkedin.text" />
                    </span>
                  </a>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="eazy-detailed-blog-further-reading-container">
          <div className="eazy-detailed-blog-further-reading-inner">
            <span className="eazy-detailed-blog-further-reading__title">
              <Message dictKey="you.might.also.like.reading" />
            </span>
            <div className="eazy-detailed-blog-further-reading">
              <FurtherReadingBlog
                blog={furtherReadingBlogs[0]}
                location={furtherReadingLocation}
              />
              <FurtherReadingBlog
                blog={furtherReadingBlogs[1]}
                location={furtherReadingLocation}
              />
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default DetailedBlog;
