import Link from "gatsby-link";
import React, { useEffect, useState } from "react";
import EazyHeader from "../components/Header";
import Layout from "../components/Layout";
import Loader from "../components/Loader";
import WithProviders from "../components/WithProviders";
import ErrorImg from "../images/404_new.webp";
import { Message } from "../lib/getMessages";

const NotFound = (props) => {
  const { language, updateLanguage, direction } = props;
  const isDirectionRTL = direction === "rtl";
  const [browser, setBrowser] = useState(undefined);
  useEffect(() => {
    setBrowser(typeof window !== "undefined" && window);
  }, []);
  return (
    <Layout
      language={language}
      isDirectionRTL={isDirectionRTL}
      location={props.location}
    >
      <div className="eazy-page">
        <EazyHeader language={language} updateLanguage={updateLanguage} />
        {browser ? (
          <div className="error-404">
            <img src={ErrorImg} alt="Error 404" />
            <p className="message404">
              <Message dictKey="message404" />
            </p>
            <Link to={`/${language}`} className="link404">
              <Message dictKey="go.home" />
            </Link>
          </div>
        ) : (
          <div className="loader-wrapper404">
            <Loader />
          </div>
        )}
      </div>
    </Layout>
  );
};

export default WithProviders(NotFound);
