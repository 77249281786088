import React from "react";

function AddProductDescription(props) {
  return (
    <svg
      width="344.6px"
      height="280px"
      viewBox="0 0 564 343"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <filter
          x="-19.6%"
          y="-55.4%"
          width="139.2%"
          height="210.7%"
          filterUnits="objectBoundingBox"
          id="filter-1"
        >
          <feGaussianBlur
            stdDeviation="25.168952"
            in="SourceGraphic"
          ></feGaussianBlur>
        </filter>
      </defs>
      <g id="Blog" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group-21" transform="translate(82.986899, 32.000000)">
          <g
            id="Group-189"
            transform="translate(28.315448, 0.000000)"
            fill="#E9F0F4"
          >
            <ellipse
              id="Oval-Copy"
              transform="translate(66.039863, 213.843164) rotate(11.000000) translate(-66.039863, -213.843164) "
              cx="66.0398634"
              cy="213.843164"
              rx="56.3306789"
              ry="56.4231828"
            ></ellipse>
            <ellipse
              id="Oval"
              transform="translate(215.939449, 128.422093) rotate(11.000000) translate(-215.939449, -128.422093) "
              cx="215.939449"
              cy="128.422093"
              rx="109.347788"
              ry="109.527355"
            ></ellipse>
          </g>
          <g
            id="Group-23"
            transform="translate(199.013101, 139.887399) rotate(6.000000) translate(-199.013101, -139.887399) translate(6.354346, 69.000000)"
          >
            <rect
              id="Rectangle-Copy-17"
              fill="#000000"
              opacity="0.057274"
              filter="url(#filter-1)"
              x="0"
              y="2.66924043"
              width="385.31751"
              height="136.404541"
              rx="10.0675808"
            ></rect>
            <rect
              id="Rectangle-Copy-15"
              fill="#FFFFFF"
              x="16.1445884"
              y="0"
              width="353.028333"
              height="141.774799"
              rx="10.0675808"
            ></rect>
            <path
              d="M347.655919,39.1692333 C348.628949,39.1692333 349.509862,39.5636316 350.147518,40.2012874 C350.785174,40.8389433 351.179572,41.7198566 351.179572,42.6928866 L351.179572,42.6928866 L351.179572,120.562942 C351.179572,121.535972 350.785174,122.416886 350.147518,123.054542 C349.509862,123.692197 348.628949,124.086596 347.655919,124.086596 L347.655919,124.086596 L37.1234386,124.086596 C36.1504086,124.086596 35.2694953,123.692197 34.6318395,123.054542 C33.9941836,122.416886 33.5997853,121.535972 33.5997853,120.562942 L33.5997853,120.562942 L33.5997853,42.6928866 C33.5997853,41.7198566 33.9941836,40.8389433 34.6318395,40.2012874 C35.2694953,39.5636316 36.1504086,39.1692333 37.1234386,39.1692333 L37.1234386,39.1692333 Z"
              id="Rectangle-7-Copy-20"
              stroke="#DADEE0"
              strokeWidth="1.00675808"
              fill="#FFFFFF"
            ></path>
            <text
              id="Material:-Mesh,-Warr"
              fontFamily="Cabin-Regular, Cabin"
              fontSize="12.081097"
              fontWeight="normal"
              fill="#181912"
            >
              <tspan x="52.305698" y="62.9614056">
                Material: Mesh, Warranty: 90 days
              </tspan>
            </text>
            <text
              id="Description-Copy-4"
              fontFamily="OpenSans-SemiBold, Open Sans"
              fontSize="11.0743389"
              fontWeight="500"
              letterSpacing="-0.147657848"
              fill="#B8B8B5"
            >
              <tspan x="35.5389656" y="29.6879178">
                DESCRIPTION
              </tspan>
            </text>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default AddProductDescription;
