import React from "react";
import Cross from "../../images/Cross.svg";
import "./style.css";
const ImagePopup = (props) => {
  const { img, close } = props;
  return (
    <div className="image-popupcontainer">
      <div
        className="close-button"
        onClick={close}
        onKeyDown={close}
        role="button"
        tabIndex="0"
      >
        <img src={Cross} alt="close-img-popup" />
      </div>
      <img src={img} alt="selectedImage" className="variant-img" />
    </div>
  );
};
export default ImagePopup;
