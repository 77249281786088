import React from "react";
import { Helmet } from "react-helmet";
import DetailedBlog from "../../components/Blog";
import EazyHeader from "../../components/Header";
import LayoutWrapper from "../../components/Layout";
import WithProviders from "../../components/WithProviders";
import fiveReasonsToHaveOnlineStoreBanner from "../../images/5-reasons-to-have-your-own-online-store-banner.png";
import fiveReasonsToHaveOnlineStore from "../../images/5-reasons-to-have-your-own-online-store.png";
import addLogoAndFaviconBanner from "../../images/add-logo-and-favicon-in-store-banner.png";
import addLogoAndFavicon from "../../images/add-logo-and-favicon-in-store.png";
import addLogoFaviconImgForContent from "../../images/add-logo-favicon-img-for-content.png";
import addProductsFaqsBanner from "../../images/add-products-faqs-banner.png";
import addProductsFaqs from "../../images/add-products-faqs.png";
import addProductToEcommerceStore from "../../images/add-products-to-ecommerce-store.png";
import customDomainSetup from "../../images/customdomainsetup.png";
import customDomainSetupBanner from "../../images/customdomainsetupbanner.png";
import exploreFaqsImg from "../../images/explore-faqs.png";
import addProductInStoreBanner from "../../images/how-to-add-products-to-your-online-store-banner.png";
import addProductInStore from "../../images/how-to-add-products-to-your-online-store.png";
import footerImg from "../../images/how-to-customize-footer-img.png";
import customizeFooterBanner from "../../images/how-to-customize-the-footer-in-your-online-store-banner.png";
import customizeFooter from "../../images/how-to-customize-the-footer-in-your-online-store.png";
import setUpOnlineStoreBanner from "../../images/how-to-set-up-my-own-online-store-using-zopping-banner.png";
import setUpOnlineStore from "../../images/how-to-set-up-my-own-online-store-using-zopping.png";
import onlinePaymentImage from "../../images/online-payment-logo.png";
import onlinePaymentSetupDemoImage from "../../images/online-payment-setup-demo.png";
import onlinePaymentSetupWallpaper from "../../images/online-payment-setup-wallpaper.png";
import paytmApiSetupBanner from "../../images/paytm-api-setup-banner.png";
import paytmApiSetupLogo from "../../images/paytm-api-setup-logo.png";
import payUApiSetupBanner from "../../images/payU-api-setup-banner.png";
import payUApiSetupLogo from "../../images/payU-api-setup-logo.png";
import razorpayApiSetupBanner from "../../images/razorpay-api-setup-banner.png";
import razorpayApiSetupLogo from "../../images/razorpay-api-setup-logo.png";
import topSevenFeatureBanner from "../../images/top-7-features-to-look-for-while-choosing-your-online-store-builder-banner.png";
import topSevenFeature from "../../images/top-7-features-to-look-for-while-choosing-your-online-store-builder.png";
import stepByStepGuidLogo from "../../images/step-by-step-guide-logo.png";
import stepByStepGuidBanner from "../../images/step-by-step-guide-banner.png";
import couponBanner from "../../images/couponbanner.webp";
import squareBlog from "../../images/blog-square.webp";
import Bitmap from "../../images/Bitmap.webp";
import EmailSMTP from "../../images/Email-SMTP.webp";
import EmailSMTPStep1 from "../../images/EmailSMTPStep1.webp";
import EmailSMTPStep2 from "../../images/EmailSMTPStep2.webp";
import EmailSMTPStep3 from "../../images/EmailSMTPStep3.webp";
import Gmail from "../../images/Gmail.webp";
import { Message } from "../../lib/getMessages";
import googleapikeyBanner from "../../images/googleapi.png";
import googleapikeylogo from "../../images/googleapilogo.png";
import ondcBlog from "../../images/OndcImage.webp";
import ondcBanner from "../../images/Ondc.webp";
import productVariantBlog from "../../images/productVariantBlog.webp";
import productVariantBanner from "../../images/productVariantBanner.webp";
import productVariantimg1 from "../../images/productVariantimg1.webp";
import productVariantimgpng1 from "../../images/productVariantimgpng1.png";
import productvariantimg2 from "../../images/productvariantimg2.webp";
import productVariantimgpng2 from "../../images/productVariantimgpng2.png";
import productvariantimg3 from "../../images/productvariantimg3.webp";
import productVariantimgpng3 from "../../images/productVariantimgpng3.png";
import productvariantimg4 from "../../images/productvariantimg4.webp";
import productVariantimgpng4 from "../../images/productVariantimgpng4.png";
import variantstep1 from "../../images/varinatstep1.webp";
import variantsteppng1 from "../../images/variantsteppng1.png";
import variantstep2 from "../../images/variantstep2.webp";
import variantsteppng2 from "../../images/variantsteppng2.png";
import variantstep3 from "../../images/variantstep3.webp";
import variantsteppng3 from "../../images/variantsteppng3.png";
import variantstep4 from "../../images/variantstep4.webp";
import variantsteppng4 from "../../images/variantsteppng4.png";
import SeoImg from "../../images/SeoImg.webp";
import SeoBannerImg from "../../images/SeoBannerImage.webp";
import seovariantImg1 from "../../images/seovariantimg1.webp";
import seovariantpng1 from "../../images/seovariantpng1.webp";
import seovariantImg2 from "../../images/seovariantimg2.webp";
import seovariantpng2 from "../../images/seovariantpng2.webp";
import seovariantpng3 from "../../images/seovariantpng3.webp";
import seovariantImg3 from "../../images/seovariantimg3.webp";
import seovariantimg4 from "../../images/seovariantimg4.webp";
import seovariantpng4 from "../../images/seovariantpng4.webp";
import seovariantImg5 from "../../images/seovariantimg5.webp";
import seovariantpng5 from "../../images/seovariantpng5.webp";
import seovariantimg6 from "../../images/seovariantimg6.webp";
import deliveryAreaSupportBlog from "../../images/deliveryAreaSupportBlog.webp";
import deliveryAreaSupportBanner from "../../images/deliveryAreaSupportBanner.webp";
import deliverySupportPre1 from "../../images/deliverySupportPre1.webp";
import deliverySupportPre2 from "../../images/deliverySupportPre2.webp";
import deliverySupportPre3 from "../../images/deliverySupportPre3.webp";
import deliverySupportInstall from "../../images/deliverySupportInstall.webp";
import deliveryDefinePoint1 from "../../images/deliveryDefinePoint1.webp";
import deliveryDefinePoint1png from "../../images/deliveryDefinePoint1png.png";
import deliveryDefinePoint3 from "../../images/deliveryDefinePoint3.webp";
import deliveryDefinePoint4 from "../../images/deliveryDefinePoint4.webp";
import deliveryDefinePoint5 from "../../images/deliveryDefinePoint5.webp";
import deliveryDefinePoint6 from "../../images/deliveryDefinePoint6.webp";
import deliveryDefinePoint7 from "../../images/deliveryDefinePoint7.webp";
import deliveryDefinePoint8 from "../../images/deliveryDefinePoint8.webp";
import deliveryDefinePoint9 from "../../images/deliveryDefinePoint9.webp";
import deliveryDefinePoint10 from "../../images/deliveryDefinePoint10.webp";
import deliveryReflectPoint1 from "../../images/deliveryReflectPoint1.webp";
import deliveryReflectPoint3 from "../../images/deliveryReflectPoint3.webp";
import deliveryReflectPoint4 from "../../images/deliveryReflectPoint4.webp";
import deliveryReflectPoint5 from "../../images/deliveryReflectPoint5.webp";
import deliverySupportPre1png from "../../images/deliverySupportPre1.png";
import deliverySupportPre2png from "../../images/deliverySupportPre2.png";
import deliverySupportPre3png from "../../images/deliverySupportPre3.png";
import deliverySupportInstallPng from "../../images/deliverySupportInstallPng.png";
import deliveryDefinePoint3Png from "../../images/deliveryDefinePoint3Png.png";
import deliveryDefinePoint4Png from "../../images/deliveryDefinePoint4Png.png";
import deliveryDefinePoint5Png from "../../images/deliveryDefinePoint5Png.png";
import deliveryDefinePoint6Png from "../../images/deliveryDefinePoint6Png.png";
import deliveryDefinePoint7Png from "../../images/deliveryDefinePoint7.png";
import deliveryDefinePoint8Png from "../../images/deliveryDefinePoint8Png.png";
import deliveryDefinePoint9Png from "../../images/deliveryDefinePoint9Png.png";
import deliveryDefinePoint10Png from "../../images/deliveryDefinePoint10Png.png";
import deliveryReflectPoint1Png from "../../images/deliveryReflectPoint1Png.png";
import deliveryReflectPoint2Png from "../../images/deliveryReflectPoint2Png.png";
import deliveryReflectPoint3Png from "../../images/deliveryReflectPoint3Png.png";
import deliveryReflectPoint4Png from "../../images/deliveryReflectPoint4Png.png";

import seovariantpng6 from "../../images/seovariantpng6.webp";
import roleBanner from "../../images/roleBanner.webp";
import roleResponsibilitiesBlog from "../../images/roleResponsibilityblog.webp";
import deliveryPickupSlotBlog from "../../images/deliveryPickupSlotBlog.webp";
import deliveryPickupSlotBlogBanner from "../../images/deliveryPickupSlotBlogBanner.webp";
import deliveryPickupSlotInstallPng from "../../images/deliveryPickupSlotInstallPng.png";
import deliveryPickupSlotInstall from "../../images/deliveryPickupSlotInstall.webp";
import deliveryPickupSlotPrePoint4 from "../../images/deliveryPickupSlotPrePoint4.webp";
import deliveryPickupSlotPrePoint4Png from "../../images/deliveryPickupSlotPrePoint4Png.png";
import definedeliverypickupPoint1Png from "../../images/definedeliverypickupPoint1Png.png";
import defineDeliveryPickupPoint1 from "../../images/definedeliverypickupPoint1Png.webp";
import defineDeliverypickupPoint4Png from "../../images/defineDeliverypickupPoint4Png.png";
import defineDeliverypickupPoint4 from "../../images/defineDeliverypickupPoint4.webp";
import reflectDeliveryPickupSlotPoint3png from "../../images/reflectDeliveryPickupSlotPoint3Png.png";
import reflectDeliveryPickupSlotPoint3 from "../../images/reflectDeliveryPickupSlotPoint3.webp";
import affectDeliveryPickupSlotpoint4 from "../../images/affectDeliveryPickupSlotpoint4.webp";
import affectDeliveryPickupSlotpoint4Png from "../../images/affectDeliveryPickupSlotpoint4Png.png";
import definedSlotsPoint4 from "../../images/definedSlotsPoint4.webp";
import definedSlotsPoint4Png from "../../images/definedSlotsPoint4Png.png";
import definedSlotsPoint5 from "../../images/definedSlotsPoint5.webp";
import definedSlotsPoint6 from "../../images/definedSlotsPoint6.webp";
import definedSlotsPoint5Png from "../../images/definedSlotsPoint5Png.png";
import definedSlotsPoint6Png from "../../images/definedSlotsPoint6Png.png";

import referralManagementBlog from "../../images/referralManagementBlog.png";
import referralManagement1 from "../../images/referralManagement1.png";
import referralmanagement2 from "../../images/referralmanagement2.png";
import referralManagement3 from "../../images/referralManagement3.png";
import referralManagement4 from "../../images/referralManagement4.png";
import referralManagement5 from "../../images/referralManagement5.png";

import reviewRatingBlog from "../../images/reviewRatingBlog.png";
import reviewRating1 from "../../images/reviewRating1.png";
import reviewRating2 from "../../images/reviewRating2.png";
import reviewRating3 from "../../images/reviewRating3.png";
import reviewRating4 from "../../images/reviewRating4.png";
import reviewRating5 from "../../images/reviewRating5.png";

import customFieldBlog from "../../images/customFieldBlog.png";
import customField1 from "../../images/customField1.png";
import customField2 from "../../images/customField2.png";
import customField3 from "../../images/customField3.png";
import customField4 from "../../images/customField4.png";
import customField5 from "../../images/customField5.png";
import customField6 from "../../images/customField6.png";
import customField7 from "../../images/customField7.png";
import customField8 from "../../images/customField8.png";
import customField9 from "../../images/customField9.png";

import abandondedCartBlog from "../../images/abandonedCartBlog.png";
import abandonedCartBanner from "../../images/abandonedCartBanner.webp";
import abandonedCartPre1 from "../../images/abandonedCartPre1.webp";
import abandonedCartPre1Png from "../../images/abandonedCartPre1Png.png";
import abandonedCartAffectImg2png from "../../images/abandonedCartAffectImg2png.png";
import abandonedCartAffectImg2 from "../../images/abandonedCartAffectImg2.webp";
import abandonedCartSetupImg4 from "../../images/abandonedCartSetupImg4.webp";
import abandonedCartSetupImg4png from "../../images/abandonedCartSetupImg4png.png";
import abandonedCartSetupImg1 from "../../images/abandonedCartSetupImg1.webp";
import abandonedCartSetupImg1png from "../../images/abandonedCartSetupImg1png.png";

import customerWallet1 from "../../images/customer-wallet-image.png";
import customerWallet2 from "../../images/customer-wallet-support-extension.png";
import customerWallet3 from "../../images/wallet-configration.png";
import customerWallet4 from "../../images/wallet-option-themes.png";
import customerWallet5 from "../../images/wallet-in-themes.png";
import customerWallet6 from "../../images/wallet-page-themes.png";
import customerWallet7 from "../../images/customer-wallet-page-admin.png";
import customerWallet8 from "../../images/customer-wallet-configration.webp";
import customerWallet9 from "../../images/customer-wallet-extension.webp";
import customerWallet10 from "../../images/customer-wallet-theme.webp";
import customerWallet11 from "../../images/customer-wallet-theme-page.webp";
import customerWallet12 from "../../images/customer-wallet-theme-page1.webp";
import customerWallet13 from "../../images/customer-wallet-admin.webp";

import "./blog.css";

const blogs = [
  {
    slug: "what-is-customer-wallet",
    id: 1,
    metaDescription: "what-is-customer-wallet.description",
    title: "what-is-customer-wallet",
    image: customerWallet1,
    bannerImage: customerWallet1,
    postedOn: "14 Jan, 2023",
    timeToRead: "10 min ",
    description: "what-is-customer-wallet.description",
    configurePoints: [
      {
        id: 1,
        description: "what-is-customer-wallet.qn1-heading",
      },
      {
        id: 2,
        description: "customer-wallet.ans1",
      },
      {
        id: 3,
        description: "prerequisites",
      },
      {
        id: 4,
        description: "what-is-customer-wallet.prerequisites.step.1",
      },
    ],
    dataPoints: [
      {
        id: 1,
        img: customerWallet9,
        pngImg: customerWallet2,
        alt: "customField1",
        description: "what-is-customer-wallet.prerequisites.step.2",
      },
    ],
    definePoints: [
      {
        id: 1,
        description: "customer-wallet-support.define1",
      },
      {
        id: 2,
        description: "customer-wallet-support.define2",
      },
    ],
    affectPoints: [
      {
        img: customerWallet8,
        pngImg: customerWallet3,
        alt: "customField4",
        description: "customer-wallet-conflagration.notes.point1",
      },
      {
        id: 3,
        description: "customer-wallet-configuration.notes.point2",
      },
      {
        id: 4,
        description: "customer-wallet-configuration.notes.point3",
      },
      {
        description: "customer-wallet-configuration.notes.point4",
      },
      {
        id: 5,
        description: "customer-wallet-configuration.notes.point5",
      },
      {
        id: 6,
        description: "customer-wallet-configuration.notes.point6",
      },
      {
        description: "how-do-i-affect-online-store",
      },
      {
        id: 1,
        description: "customer-wallet-configuration.notes.point7",
      },
      {
        id: 2,
        img: customerWallet10,
        pngImg: customerWallet4,
        alt: "customField4",
        description: "customer-wallet-configuration.notes.point8",
      },
      {
        id: 3,
        img: customerWallet11,
        pngImg: customerWallet5,
        alt: "customField4",
        description: "customer-wallet-configuration.notes.point9",
      },
      {
        id: 4,
        description: "customer-wallet-configuration.notes.point10",
      },
      {
        id: 5,
        img: customerWallet12,
        pngImg: customerWallet6,
        alt: "customField4",
        description: "customer-wallet-configuration.notes.point11",
      },
      {
        img: customerWallet13,
        pngImg: customerWallet7,
        alt: "customField4",
        description: "customer-wallet-configuration.notes.point12",
      },
      {
        description: "customer-wallet-configuration.notes.point13",
      },
      {
        description: "customer-wallet-configuration.notes.point14",
      },
    ],
    uninstallPoint: [
      {
        id: 1,
        description: "customer-wallet.uninstall.point1",
      },
    ],
  },
  {
    slug: "what-is-an-abandoned-cart",
    id: 2,
    metaDescription: "what.is.an.abandoned.cart.meta.description",
    title: "what.is.an.abandoned.cart",
    image: abandondedCartBlog,
    bannerImage: abandonedCartBanner,
    postedOn: "17 January, 2024",
    timeToRead: "7 min",
    description: "what.is.an.abandoned.cart.description",
    moreDescriptions: [
      {
        id: 1,
        description: "what.is.an.abandoned.cart.qn1.heading",
      },
      {
        id: 2,
        description: "what.is.an.abandoned.cart.ans1",
      },
      {
        id: 3,
        description: "prerequisites",
      },
    ],
    configurePoints: [
      {
        id: 1,
        description: "what.is.an.abandoned.cart.prerequisites.step.1",
      },
      {
        id: 2,
        img: abandonedCartPre1,
        pngImg: abandonedCartPre1Png,
        alt: "abandonedCartPre1",
        description: "what.is.an.abandoned.cart.prerequisites.step.2",
      },
      {
        id: 3,
        description: "what.is.an.abandoned.cart.prerequisites.step.3",
      },
    ],
    dataPoints: [
      {
        id: 1,
        img: abandonedCartSetupImg1,
        pngImg: abandonedCartSetupImg1png,
        alt: "abandonedCartSetupImg1",
        description: "abandoned.cart.extension.setup.step1",
      },
      {
        id: 2,
        description: "abandoned.cart.extension.setup.step2",
      },
      {
        id: 3,
        description: "abandoned.cart.extension.setup.step3",
      },
      {
        id: 4,
        img: abandonedCartSetupImg4,
        pngImg: abandonedCartSetupImg4png,
        alt: "abandonedCartSetupImg4",
        description: "abandoned.cart.extension.setup.step4",
      },
      {
        id: 5,
        description: "abandoned.cart.extension.setup.step5",
      },
      {
        id: 6,
        description: "abandoned.cart.extension.setup.step6",
      },
    ],
    affectPoints: [
      {
        id: 1,
        description:
          "abandoned.cart.extension.setup.how.will.this.change.affect.point1",
      },
      {
        id: 2,
        img: abandonedCartAffectImg2,
        pngImg: abandonedCartAffectImg2png,
        alt: "abandonedCartAffectImg2",
        description:
          "abandoned.cart.extension.setup.how.will.this.change.affect.point2",
      },
      {
        id: 3,
        description:
          "abandoned.cart.extension.setup.how.will.this.change.affect.point3",
      },
    ],
    uninstallPoint: [
      {
        id: 1,
        description:
          "abandoned.cart.extension.setup.what.happens.when.i.uninstall.this.extension.step1",
      },
    ],
  },
  {
    slug: "what-are-custom-fields",
    id: 3,
    metaDescription: "what-are-custom-fields.description",
    title: "what-are-custom-fields",
    image: customFieldBlog,
    bannerImage: customFieldBlog,
    postedOn: "27 December, 2023",
    timeToRead: "11 min",
    description: "what-are-custom-fields.description",
    moreDescriptions: [
      {
        id: 1,
        description: "what-are-custom-fields.slot.qn1-heading",
      },
      {
        id: 2,
        description: "custom-fields.slot.ans1",
      },
      {
        id: 3,
        description: "prerequisites",
      },
      {
        id: 4,
        description: "reviews-ratings.slot.prerequisites.step.1",
      },
    ],
    dataPoints: [
      {
        id: 1,
        img: customField1,
        pngImg: customField1,
        alt: "customField1",
        description: "custom-fields.slot.prerequisites.step.2",
      },
    ],
    definePoints: [
      {
        id: 1,
        description: "custom-fields.slot.define1",
      },
      {
        id: 2,
        description: "custom-fields.slot.define2",
      },
      {
        id: 3,
        img: customField2,
        pngImg: customField2,
        alt: "customField2",
        description: "custom-fields.slot.define3",
      },
      {
        id: 4,
        img: customField3,
        pngImg: customField3,
        alt: "customField3",
        description: "custom-fields.slot.define4",
        note: "custom-fieldss.slot.define4.slot",
        mainPoints: [
          {
            point: ["custom-fields.slot.define4.point1"],
          },
          {
            point: ["custom-fields.slot.define4.point2"],
          },
        ],
      },
      {
        id: 5,
        description: "custom-fields.slot.define5",
      },
      {
        id: 6,
        description: "custom-fields.slot.define6",
      },
      {
        id: 7,
        description: "custom-fields.slot.define7",
      },
    ],
    affectPoints: [
      {
        id: 1,
        img: customField4,
        pngImg: customField4,
        alt: "customField4",
        description: "custom-fields.slot.affect.point1",
      },
      {
        id: 2,
        img: customField5,
        pngImg: customField5,
        alt: "customField5",
        description: "custom-fields.slot.affect.point2",
      },
      {
        id: 3,
        img: customField6,
        pngImg: customField6,
        alt: "customField6",
        description: "custom-fields.slot.affect.point3",
      },
      {
        id: 4,
        img: customField7,
        pngImg: customField7,
        alt: "customField7",
        description: "custom-fields.slot.affect.point4",
      },
      {
        id: 5,
        img: customField8,
        pngImg: customField8,
        alt: "customField8",
        description: "custom-fields.slot.affect.point5",
      },
      {
        id: 6,
        img: customField9,
        pngImg: customField9,
        alt: "customField9",
        description: "custom-fields.slot.affect.point6",
      },
    ],
    uninstallPoint: [
      {
        id: 1,
        description: "custom-fields.slot.uninstall.point1",
      },
      {
        id: 2,
        description: "custom-fields.slot.uninstall.point2",
      },
      {
        id: 3,
        description: "custom-fields.slot.uninstall.point3",
      },
    ],
  },
  {
    slug: "what-are-reviews-ratings",
    id: 4,
    metaDescription: "what-are-reviews-ratings.description",
    title: "what-are-reviews-ratings",
    image: reviewRatingBlog,
    bannerImage: reviewRatingBlog,
    postedOn: "27 December, 2023",
    timeToRead: "8 min ",
    description: "what-are-reviews-ratings.description",
    moreDescriptions: [
      {
        id: 1,
        description: "what-are-reviews-ratings.slot.qn1-heading",
      },
      {
        id: 2,
        description: "reviews-ratings.slot.ans1",
      },
      {
        id: 3,
        description: "prerequisites",
      },
      {
        id: 4,
        description: "reviews-ratings.slot.prerequisites.step.1",
      },
    ],
    dataPoints: [
      {
        id: 1,
        img: reviewRating1,
        pngImg: reviewRating1,
        alt: "reviewRating1",
        description: "reviews-ratings.slot.prerequisites.step.2",
      },
      {
        id: 2,
        description: "reviews-ratings.slot.prerequisites.step.22",
      },
    ],
    affectPoints: [
      {
        note: "reviews-ratings.slot.affect.note1",
      },
      {
        id: 1,
        img: reviewRating2,
        pngImg: reviewRating2,
        alt: "reviewRating2",
        description: "reviews-ratings.slot.affect.point2",
      },
      {
        note: "reviews-ratings.slot.affect.note2",
      },
      {
        id: 2,
        img: reviewRating3,
        pngImg: reviewRating3,
        alt: "reviewRating3",
        description: "reviews-ratings.slot.affect.point3",
      },
      {
        id: 3,
        img: reviewRating4,
        pngImg: reviewRating4,
        alt: "reviewRating4",
        description: "reviews-ratings.slot.affect.point4",
      },
      {
        id: 4,
        img: reviewRating5,
        pngImg: reviewRating5,
        alt: "reviewRating5",
      },
    ],
    uninstallPoint: [
      {
        id: 1,
        description: "reviews-ratings.slot.uninstall.point1",
      },
      {
        id: 2,
        description: "reviews-ratings.slot.uninstall.point2",
      },
    ],
  },
  {
    slug: "what-is-referral-management",
    id: 5,
    metaDescription: "what-is-referral-management.description",
    title: "what-is-referral-management",
    image: referralManagementBlog,
    bannerImage: referralManagementBlog,
    postedOn: "27 December, 2023",
    timeToRead: "8 min ",
    description: "what-is-referral-management.description",
    moreDescriptions: [
      {
        id: 1,
        description: "what-is-referral-management.slot.qn1-heading",
      },
      {
        id: 2,
        description: "referral-management.slot.ans1",
      },
      {
        id: 3,
        description: "prerequisites",
      },
      {
        id: 4,
        description: "referral-management.slot.prerequisites.step.1",
      },
      {
        id: 5,
        description: "what-is-referral-management.slot.qn2-heading",
      },
    ],
    configurePoints: [
      {
        id: 1,
        img: referralManagement1,
        pngImg: referralManagement1,
        alt: "referralManagement1",
        description: "referral-management.slot.prerequisites.step.3",
      },
      {
        id: 2,
        description: "referral-management.slot.prerequisites.step.4",
      },
      {
        id: 3,
        description: "referral-management.slot.prerequisites.step.5",
      },
      {
        id: 4,
        img: referralmanagement2,
        pngImg: referralmanagement2,
        alt: "referralmanagement2",
        description: "referral-management.slot.prerequisites.step.6",
      },
      {
        id: 5,
        description: "referral-management.slot.prerequisites.step.7",
      },
      {
        id: 6,
        description: "referral-management.slot.prerequisites.step.8",
      },
      {
        id: 7,
        description: "referral-management.slot.prerequisites.step.9",
      },
      {
        id: 8,
        description: "referral-management.slot.prerequisites.step.10",
      },
    ],
    affectPoints: [
      {
        id: 1,
        img: referralManagement3,
        pngImg: referralManagement3,
        alt: "referralmanagement3",
        description: "referral-management.slot.affect.point1",
      },
      {
        id: 2,
        img: referralManagement4,
        pngImg: referralManagement4,
        alt: "referralmanagement4",
        description: "referral-management.slot.affect.point2",
      },
      {
        id: 3,
        description: "referral-management.slot.affect.point3",
      },
      {
        id: 4,
        img: referralManagement5,
        pngImg: referralManagement5,
        alt: "referralmanagement5",
        description: "referral-management.slot.affect.point4",
      },
      {
        id: 5,
        description: "referral-management.slot.affect.point5",
      },
    ],
    dataPoints: [
      {
        id: 1,
        description: "referral-management.slot.note.point1",
      },
      {
        id: 2,
        description: "referral-management.slot.note.point2",
      },
      {
        id: 3,
        description: "referral-management.slot.note.point3",
      },
    ],
    uninstallPoint: [
      {
        id: 1,
        description: "referral-management.slot.uninstall.point1",
      },
      {
        id: 2,
        description: "referral-management.slot.uninstall.point2",
      },
      {
        id: 3,
        description: "referral-management.slot.uninstall.point3",
      },
    ],
  },
  {
    slug: "what-is-delivery-pickup-slot-management",
    id: 6,
    metaDescription: "what.is.delivery/pickup.slot.management.description",
    title: "what.is.delivery/pickup.slot.management",
    image: deliveryPickupSlotBlog,
    bannerImage: deliveryPickupSlotBlogBanner,
    postedOn: "24 November, 2023",
    timeToRead: "10 min ",
    description: "what.is.delivery/pickup.slot.management.description",
    moreDescriptions: [
      {
        id: 1,
        description:
          "why.do.i.need.this.extension.delivery.pickup.slot.qn1-heading",
      },
      {
        id: 2,
        description: "delivery.pickup.slot.ans1",
      },
      {
        id: 3,
        description:
          "what.extension.will.i.need.to.manage.delivery.pickup.slot.qn2-heading",
      },
      {
        id: 4,
        description: "delivery.pickup.slot.ans2",
      },
      {
        id: 5,
        description: "prerequisites",
      },
    ],
    configurePoints: [
      {
        id: 1,
        description: "delivery.pickup.slot.prerequisites.step.1",
      },
      {
        id: 2,
        img: deliverySupportPre1,
        pngImg: deliverySupportPre1png,
        alt: "deliveryPickupBlogPre1",
        description: "delivery.pickup.slot.prerequisites.step.2",
      },
      {
        id: 3,
        img: deliveryPickupSlotPrePoint4,
        pngImg: deliveryPickupSlotPrePoint4Png,
        alt: "deliveryPickupBlogPre3",
        description: "delivery.pickup.slot.prerequisites.step.4",
      },
    ],
    dataPoints: [
      {
        id: 1,
        description: "how.to.install.delivery.pickup.slot.extension",
      },
    ],
    dataConfigurePoint: [
      {
        id: 1,
        img: deliveryPickupSlotInstall,
        pngImg: deliveryPickupSlotInstallPng,
        alt: "deliverySupportInstall",
        description: ["delivery.pickup.slot.extension.setup.step1"],
      },
    ],
    definePointsForStore: [
      {
        id: 1,
        description: "delivery.pickup.slot.my.store.define.point1",
      },
      {
        id: 2,
        description: "delivery.pickup.slot.my.store.define.point2",
      },
      {
        id: 3,
        description: "delivery.pickup.slot.my.store.define.point3",
      },
      {
        id: 4,
        img: definedSlotsPoint4,
        pngImg: definedSlotsPoint4Png,
        alt: "deliveryDefinePoint4",
        description: "delivery.pickup.slot.my.store.define.point4",
      },
      {
        id: 5,
        img: definedSlotsPoint5,
        pngImg: definedSlotsPoint5Png,
        alt: "deliveryDefinePoint4",
        description: "delivery.pickup.slot.my.store.define.point5",
      },
      {
        id: 6,
        img: definedSlotsPoint6,
        pngImg: definedSlotsPoint6Png,
        alt: "deliveryDefinePoint4",
        description: "delivery.pickup.slot.my.store.define.point6",
      },
      {
        id: 7,
        description: "delivery.pickup.slot.my.store.define.point7",
      },
      {
        id: 8,
        description: "delivery.pickup.slot.my.store.define.point8",
      },
    ],
    definePoints: [
      {
        id: 1,
        description: "delivery.pickup.slot.define.point1",
      },
      {
        id: 2,
        img: defineDeliveryPickupPoint1,
        pngImg: definedeliverypickupPoint1Png,
        alt: "deliveryDefinePoint1",
        description: "delivery.pickup.slot.define.point2",
      },
      {
        id: 3,
        description: "delivery.pickup.slot.define.point3",
      },
      {
        id: 4,
        img: defineDeliverypickupPoint4,
        pngImg: defineDeliverypickupPoint4Png,
        alt: "deliveryDefinePoint4",
        description: "delivery.pickup.slot.define.point4",
      },
    ],
    reflectPoints: [
      {
        id: 1,
        description: "delivery.pickup.slot.reflect.point1",
      },
      {
        id: 2,
        description: "delivery.pickup.slot.reflect.point2",
      },
      {
        id: 3,
        img: reflectDeliveryPickupSlotPoint3,
        pngImg: reflectDeliveryPickupSlotPoint3png,
        alt: "deliveryPickupSlotReflectPoint3",
        description: "delivery.pickup.slot.reflect.point3",
      },
      {
        id: 4,
        description: "delivery.pickup.slot.reflect.point4",
      },
      {
        id: 5,
        description: "delivery.pickup.slot.reflect.point5",
      },
      {
        id: 6,
        description: "delivery.pickup.slot.reflect.point6",
      },
    ],
    affectPoints: [
      {
        id: 1,
        description: "delivery.pickup.slot.affect.point3",
      },
      {
        id: 2,
        img: affectDeliveryPickupSlotpoint4,
        pngImg: affectDeliveryPickupSlotpoint4Png,
        description: "delivery.pickup.slot.affect.point4",
      },
      {
        id: 3,
        description: "delivery.pickup.slot.affect.point5",
      },
    ],
    uninstallPoint: [
      {
        id: 1,
        description: "delivery.pickup.slot.uninstall.point1",
      },
      {
        id: 2,
        description: "delivery.pickup.slot.uninstall.point2",
      },
      {
        id: 3,
        description: "delivery.pickup.slot.uninstall.point3",
      },
    ],
  },
  {
    slug: "delivery-area-support-extension",
    id: 7,
    metaDescription: "delivery.area.support.extension.description",
    title: "delivery.area.support.extension",
    image: deliveryAreaSupportBlog,
    bannerImage: deliveryAreaSupportBanner,
    postedOn: "17 November, 2023",
    timeToRead: "10 min ",
    description: "introduction.to.delivery.area.support.description",
    moreDescriptions: [
      {
        id: 1,
        description:
          "why.do.i.need.this.extension.delivery.support.qn1-heading",
      },
      {
        id: 2,
        description: "delivery.area.support.extension.ans1",
      },
      {
        id: 3,
        description: "prerequisites",
      },
    ],
    configurePoints: [
      {
        id: 1,
        description: "prerequisites.step.1",
      },
      {
        id: 2,
        img: deliverySupportPre1,
        pngImg: deliverySupportPre1png,
        alt: "deliverySupportPre1",
        description: "prerequisites.step.2",
      },
      {
        id: 3,
        img: deliverySupportPre2,
        pngImg: deliverySupportPre2png,
        alt: "deliverySupportPre2",
        description: "prerequisites.step.3",
      },
      {
        id: 4,
        img: deliverySupportPre3,
        pngImg: deliverySupportPre3png,
        alt: "deliverySupportPre3",
        description: "prerequisites.step.4",
      },
    ],
    dataPoints: [
      {
        id: 1,
        description: "how.to.install.delivery.support.extension",
      },
    ],
    dataConfigurePoint: [
      {
        id: 1,
        img: deliverySupportInstall,
        pngImg: deliverySupportInstallPng,
        alt: "deliverySupportInstall",
        description: ["setup-step1", "setup-step2"],
      },
    ],
    definePoints: [
      {
        id: 1,
        description: "define.point1",
      },
      {
        id: 2,
        img: deliveryDefinePoint1,
        pngImg: deliveryDefinePoint1png,
        alt: "deliveryDefinePoint1",
        description: "define.point2",
      },
      {
        id: 3,
        img: deliveryDefinePoint3,
        pngImg: deliveryDefinePoint3Png,
        alt: "deliveryDefinePoint3",
        description: "define.points3",
      },
      {
        id: 4,
        img: deliveryDefinePoint4,
        pngImg: deliveryDefinePoint4Png,
        alt: "deliveryDefinePoint4",
        description: "define.point4",
      },
      {
        id: 5,
        img: deliveryDefinePoint5,
        pngImg: deliveryDefinePoint5Png,
        alt: "deliveryDefinePoint5",
        description: "define.point5",
      },
      {
        id: 6,
        img: deliveryDefinePoint6,
        pngImg: deliveryDefinePoint6Png,
        alt: "deliveryDefinePoint6",
        description: "define.point6",
      },
      {
        id: 7,
        img: deliveryDefinePoint7,
        pngImg: deliveryDefinePoint7Png,
        alt: "deliveryDefinePoint7",
        description: "define.point7",
      },
      {
        id: 8,
        img: deliveryDefinePoint8,
        pngImg: deliveryDefinePoint8Png,
        alt: "deliveryDefinePoint8",
        description: "define.point8",
      },
      {
        id: 9,
        img: deliveryDefinePoint9,
        pngImg: deliveryDefinePoint9Png,
        alt: "deliveryDefinePoint9",
        description: "define.point9",
      },
      {
        id: 10,
        img: deliveryDefinePoint10,
        pngImg: deliveryDefinePoint10Png,
        alt: "deliveryDefinePoint4",
        description: "define.point10",
      },
    ],
    reflectPoints: [
      {
        id: 1,
        img: deliveryReflectPoint1,
        pngImg: deliveryReflectPoint1Png,
        alt: "deliveryReflectPoint1",
        description: "reflect.point1",
      },
      {
        id: 2,
        description: "reflect.point2",
      },
      {
        id: 3,
        img: deliveryReflectPoint3,
        pngImg: deliveryReflectPoint2Png,
        alt: "deliveryReflectPoint3",
        description: "reflect.point3",
      },
      {
        id: 4,
        img: deliveryReflectPoint4,
        pngImg: deliveryReflectPoint3Png,
        alt: "deliveryReflectPoint4",
        description: "reflect.point4",
      },
      {
        id: 5,
        img: deliveryReflectPoint5,
        pngImg: deliveryReflectPoint4Png,
        alt: "deliveryReflectPoint5",
        description: "reflect.point5",
      },
    ],
    uninstallPoint: [
      {
        id: 1,
        description: "uninstall.point1",
      },
      {
        id: 2,
        description: "uninstall.point2",
      },
      {
        id: 3,
        description: "uninstall.point3",
      },
    ],
  },
  {
    slug: "role-and-responsibilities-of-network-participants",
    id: 8,
    metaDescription: "role.responsibility.of.network.participants.description",
    title: "role.responsibility.of.network.participants",
    image: roleResponsibilitiesBlog,
    bannerImage: roleBanner,
    postedOn: "21 November, 2023",
    timeToRead: "5 min ",
    description: "role.responsibility.of.network.participants.description",
    moreDescriptions: [
      {
        id: 1,
        description:
          "role.responsibility.of.network.participants.more.description.para1",
      },
      {
        id: 2,
        description:
          "role.responsibility.of.network.participants.more.description.para2",
      },
      {
        id: 3,
        description:
          "role.responsibility.of.network.participants.more.description.para3",
      },
    ],
    ImageRelatedPoints: {
      items: [
        {
          id: 1,
          description: "role.responsibility.imgcontent.one",
        },
        {
          id: 2,
          description: "role.responsibility.imgcontent.two",
        },
        {
          id: 3,
          description: "role.responsibility.imgcontent.three",
        },
        {
          id: 4,
          description: "role.responsibility.imgcontent.four",
        },
        {
          id: 5,
          description: "role.responsibility.imgcontent.five",
        },
        {
          id: 6,
          description: "role.responsibility.imgcontent.six",
        },
        {
          id: 7,
          description: "role.responsibility.imgcontent.seven",
        },
        {
          id: 8,
          description: "role.responsibility.imgcontent.eight",
        },
      ],
    },
    MoreImageRelatedPoints: {
      items: [
        {
          id: 1,
          description: "role.responsibility.imgcontent2.one",
        },
        {
          id: 2,
          description: "role.responsibility.imgcontent2.two",
        },
        {
          id: 3,
          description: "role.responsibility.imgcontent2.three",
        },
        {
          id: 4,
          description: "role.responsibility.imgcontent2.four",
        },
      ],
    },
    paragraphs: [
      { id: 1, description: "role.responsibility.para.1.description" },
      { id: 2, description: "role.responsibility.para.2.description" },
      { id: 3, description: "role.responsibility.para.3.description" },
      { id: 4, description: "role.responsibility.para.4.description" },
      { id: 5, description: "role.responsibility.para.5.description" },
      { id: 6, description: "role.responsibility.para.6.description" },
      { id: 7, description: "role.responsibility.para.7.description" },
      { id: 8, description: "role.responsibility.para.8.description" },
      { id: 9, description: "role.responsibility.para.9.description" },
    ],
  },
  {
    slug: "what-is-seo-optimization-extension",
    id: 9,
    ImageForContent: SeoImg,
    metaDescription: "what.is.seo.optimization.extension.description",
    title: "what.is.seo.optimization.extension.title",
    image: SeoImg,
    bannerImage: SeoBannerImg,
    postedOn: "16 Nov, 2023",
    timeToRead: "11 min ",
    importantPoints: [
      {
        id: 1,
        description: [
          "what.is.seo.optimization.extension.para.1.heading1",
          "what.is.seo.optimization.extension.para.1.step1",
          "what.is.seo.optimization.extension.para.1.step2",
        ],
        alt: "variantstep1",
      },
      {
        id: 2,
        description: [
          "what.is.seo.optimization.extension.para.2.heading",
          "what.is.seo.optimization.extension.para.2.step1",
        ],
      },
      {
        id: 3,
        description: ["what.is.seo.optimization.extension.para.3.heading"],
      },
    ],
    ImageRelatedPoints: {
      items: [
        {
          id: 1,
          dataConfigurePoint: [
            {
              id: 1,
              img: seovariantImg1,
              pngImg: seovariantpng1,
              bulletPoint: true,
              alt: "seovariantimg1",
              description: [
                "seo-extension-setup-step1",
                "seo-extension-setup-step2",
              ],
            },
          ],
        },
        {
          id: 2,
          description: ["what.is.seo.optimization.extension.para.4.heading"],
        },
        {
          id: 3,
          description: ["what.is.seo.optimizaiton.extension.step1"],
        },
        {
          id: 4,
          description: ["what.is.seo.optimizaiton.extension.step2"],
          img: seovariantImg2,
          pngImg: seovariantpng2,
          alt: "seovariantimg2",
        },
        {
          id: 5,
          description: ["what.is.seo.optimizaiton.extension.step3"],
        },
        {
          id: 6,
          description: ["what.is.seo.optimizaiton.extension.step4"],
          img: seovariantImg3,
          pngImg: seovariantpng3,
          alt: "seovariantimg3",
        },
        {
          id: 7,
          description: ["what.is.seo.optimizaiton.extension.step5"],
          mainPoints: [
            {
              point: ["what.is.seo.optimization.extension.step5.point1"],
            },
            {
              point: ["what.is.seo.optimization.extension.step5.point2"],
            },
            {
              point: ["what.is.seo.optimization.extension.step5.point3"],
            },
            {
              point: ["what.is.seo.optimization.extension.step5.point4"],
            },
            {
              point: ["what.is.seo.optimization.extension.step5.point5"],
            },
          ],
        },
        {
          id: 8,
          description: ["what.is.seo.optimization.extension.step6"],
          mainPoints: [
            {
              point: ["what.is.seo.optimization.extension.step6.point1"],
            },
            {
              point: ["what.is.seo.optimization.extension.step6.point2"],
            },
          ],
        },
        {
          id: 9,
          description: ["what.is.seo.optimization.extension.step7"],
        },
        {
          id: 10,
          description: ["what.is.seo.optimization.extension.para5.heading"],
        },
        {
          id: 11,
          description: ["what.is.seo.optimization.extension.para5.step1"],
        },
        {
          id: 12,
          description: ["what.is.seo.optimization.extension.para5.step2"],
        },
        {
          id: 13,
          description: ["what.is.seo.optimization.extension.para5.step3"],
          img: seovariantimg4,
          pngImg: seovariantpng4,
          alt: "seovariantimg4",
        },
        {
          id: 14,
          description: ["what.is.seo.optimization.extension.para6.heading"],
        },
        {
          id: 15,
          description: ["what.is.seo.optimization.extension.para6.step1"],
        },
        {
          id: 16,
          dataConfigurePoint: [
            {
              id: 1,
              img: seovariantImg5,
              pngImg: seovariantpng5,
              alt: "seovariantimg5",
              bulletPoint: false,
              description: [
                "what.is.seo.optimization.extension.para6.step1.point1",
                "what.is.seo.optimization.extension.para6.step1.point2",
                "what.is.seo.optimization.extension.para6.step1.point3",
                "what.is.seo.optimization.extension.para6.step1.point4",
              ],
            },
            {
              id: 2,
              img: seovariantimg6,
              pngImg: seovariantpng6,
              alt: "seovariantimg6",
              bulletPoint: false,
              description: [
                "what.is.seo.optimization.extension.para6.step2.point1",
              ],
            },
          ],
        },
        {
          id: 17,
          description: ["what.is.seo.optimization.extension.para7.heading"],
        },
        {
          id: 18,
          description: ["what.is.seo.optimization.extension.para7.step1"],
        },
      ],
    },
  },
  {
    slug: "product-variant-support-extension",
    id: 10,
    metaDescription: "product.variant.support.extension.description",
    title: "product.variant.support.extension",
    image: productVariantBlog,
    bannerImage: productVariantBanner,
    postedOn: "25 October, 2023",
    timeToRead: "10 min ",

    moreDescriptions: [
      {
        id: 1,
        description: "what.is.product.variant.support.extension.qn1-heading",
      },
      {
        id: 2,
        description: "product.variant.support.extension.ans1",
      },
      {
        id: 3,
        description: "product.variant.qn2.heading",
      },
      {
        id: 4,
        description: "product.variant.ans2",
      },
      {
        id: 5,
        description: "how.to.install.product.varian-heading",
      },
    ],
    dataConfigurePoint: [
      {
        id: 1,
        img: productVariantimg1,
        pngImg: productVariantimgpng1,
        alt: "productVariantimg1",
        description: [
          "product-variant-extension-setup-step1",
          "product-variant-extension-setup-step2",
        ],
      },
    ],
    configurePoints: [
      {
        id: 1,
        img: productvariantimg2,
        pngImg: productVariantimgpng2,
        alt: "productvariantimg2",
        description: ["define.the.product.variants.for.my.products.step1"],
      },
      {
        id: 2,
        img: productvariantimg3,
        pngImg: productVariantimgpng3,
        alt: "productVariantimg3",
        description: "define.the.product.variants.for.my.products.step2",
      },
      {
        id: 3,
        img: productvariantimg4,
        pngImg: productVariantimgpng4,
        alt: "productVariantimg1",
        description: "define.the.product.variants.for.my.products.step3",
      },
    ],
    importantPoints: [
      {
        id: 1,
        description: [
          "how.will.this.change.reflect.on.my.website.heading1",
          "how.will.this.change.reflect.on.my.website.description1",
        ],
        img: variantstep1,
        pngImg: variantsteppng1,
        alt: "variantstep1",
      },
      {
        id: 2,
        description: [
          "how.will.this.change.reflect.on.my.website.heading2",
          "how.will.this.change.reflect.on.my.website.description2",
          "how.will.this.change.reflect.on.my.website.heading2.1",
          "how.will.this.change.reflect.on.my.website.description2.1",
          "how.will.this.change.reflect.on.my.website.description2.2",
        ],
        img: variantstep2,
        pngImg: variantsteppng2,
        alt: "variantstep2",
      },
      {
        id: 3,
        description: [
          "how.will.this.change.reflect.on.my.website.description3",
        ],
        img: variantstep3,
        pngImg: variantsteppng3,
        alt: "variantstep3",
      },
      {
        id: 4,
        description: [
          "how.will.this.change.reflect.on.my.website.description4",
          "how.will.this.change.reflect.on.my.website.heading4",
        ],
        img: variantstep4,
        pngImg: variantsteppng4,
        alt: "variantstep4",
      },
      {
        id: 5,
        description: [
          "what.happens.when.i.uninstall.this.extension.heading",
          "what.happens.when.i.uninstall.this.extension.step1",
          "what.happens.when.i.uninstall.this.extension.step2",
        ],
      },
    ],
  },
  {
    slug: "introduction-to-ondc",
    id: 11,
    metaDescription: "introduction.to.ondc",
    title: "introduction.to.ondc",
    image: ondcBlog,
    bannerImage: ondcBanner,
    postedOn: "04 October, 2023",
    timeToRead: "11 min ",
    description: "introduction.to.ondc.description",
    moreDescriptions: [
      {
        id: 1,
        description: "what-ondc-is-ques1-heading",
      },
      {
        id: 2,
        description: "ondc-ans1-heading-para1",
      },
      {
        id: 3,
        description: "ondc-ans1-heading-para2",
      },
      {
        id: 5,
        description: "ondc-challenges-on-your-own-web-heading",
      },
      {
        id: 6,
        description: "ondc-challenges-on-your-own-web-heading-para",
      },
      {
        id: 7,
        description: "ondc-challenges-in-online-marketplace-heading",
      },
      {
        id: 8,
        description: "ondc-challenges-in-online-marketplace-heading-para",
      },
      {
        id: 9,
        description: "how-ondc-operate-heading",
      },
      {
        id: 10,
        description: "how-ondc-operate-heading-para",
      },
    ],
    OtherDescriptions: {
      items: [
        {
          id: 1,
          description:
            "who-is-buyer-app-seller-app-and-logistic-service-provider",
        },
        {
          id: 2,
          description: "buyer-app",
        },
        {
          id: 3,
          description: "buyer-app-points",
        },
        {
          id: 4,
          description: "seller-app",
        },
        {
          id: 5,
          description: "seller-app-points",
        },
        {
          id: 6,
          description: "logistic-service-provider",
        },
        {
          id: 7,
          description: "logistic-service-provider-points",
        },
        {
          id: 8,
          description: "logistic-service-provider-point-discription",
        },
        {
          id: 9,
          description: "how-does-ecommerse-purchase-flow-happens-on-ondc",
        },
        {
          id: 10,
          description:
            "how-does-ecommerse-purchase-flow-happens-on-ondc-description-1",
        },
        {
          id: 11,
          description:
            "how-does-ecommerse-purchase-flow-happens-on-ondc-description-2",
        },
        {
          id: 12,
          description:
            "how-does-ecommerse-purchase-flow-happens-on-ondc-description-3",
        },
        {
          id: 13,
          description:
            "how-does-ecommerse-purchase-flow-happens-on-ondc-description-4",
        },
        {
          id: 14,
          description:
            "how-does-ecommerse-purchase-flow-happens-on-ondc-description-5",
        },
        {
          id: 15,
          description:
            "how-does-ecommerse-purchase-flow-happens-on-ondc-description-6",
        },
        {
          id: 16,
          description:
            "how-does-ecommerse-purchase-flow-happens-on-ondc-description-7",
        },
        {
          id: 17,
          description:
            "how-does-ecommerse-purchase-flow-happens-on-ondc-description-8",
        },
        {
          id: 18,
          description:
            "how-does-ecommerse-purchase-flow-happens-on-ondc-description-9",
        },
        {
          id: 19,
          description:
            "how-does-ecommerse-purchase-flow-happens-on-ondc-description-10",
        },
        {
          id: 20,
          description:
            "how-does-ecommerse-purchase-flow-happens-on-ondc-description-11",
        },
        {
          id: 21,
          description:
            "how-does-ecommerse-purchase-flow-happens-on-ondc-description-12",
        },
        {
          id: 22,
          description:
            "how-does-ecommerse-purchase-flow-happens-on-ondc-description-13",
        },
        {
          id: 23,
          description:
            "how-does-ecommerse-purchase-flow-happens-on-ondc-description-14",
        },
        {
          id: 24,
          description:
            "how-does-ecommerse-purchase-flow-happens-on-ondc-description-15",
        },
      ],
    },
    dotDescription: {
      items: [
        {
          id: 1,
          description:
            "advantage-of-ondc-from-a-seller-perspective-description-1",
        },
        {
          id: 2,
          description:
            "advantage-of-ondc-from-a-seller-perspective-description-2",
        },
        {
          id: 3,
          description:
            "advantage-of-ondc-from-a-seller-perspective-description-3",
        },
        {
          id: 4,
          description:
            "advantage-of-ondc-from-a-seller-perspective-description-4",
        },
        {
          id: 5,
          description:
            "advantage-of-ondc-from-a-seller-perspective-description-5",
        },
      ],
    },
    someDescription: {
      items: [
        {
          id: 1,
          description: "how-will-zopping-help-using-ondc",
        },
        {
          id: 2,
          description: "how-will-zopping-help-using-ondc-description-one",
        },
        {
          id: 3,
          description: "how-will-zopping-help-using-ondc-description-two",
        },
        {
          id: 4,
          description: "how-will-zopping-help-using-ondc-description-three",
        },
        {
          id: 5,
          description: "how-will-zopping-help-using-ondc-description-four",
        },
      ],
      forSignUp: "signup-for-ondc-via-zopping",
    },
  },
  {
    slug: "create-discount-coupons-zopping",
    id: 12,
    metaDescription: "create.discount.coupons.zopping.description",
    title: "create.discount.coupons.zopping.title",
    image: squareBlog,
    bannerImage: couponBanner,
    postedOn: "6 July, 2023",
    timeToRead: "11 min ",
    moreDescriptions: [
      {
        id: 1,
        description: "create-discount-coupons-zopping-para1",
      },
      {
        id: 2,
        description: "create-discount-coupons-zopping-para2",
      },
    ],
  },
  {
    slug: "how-to-set-up-Email-and-smtp-configurations-for-your-online-store-on-zopping",
    id: 13,
    title: "email.and.SMTP.setup",
    metaDescription: "email.and.SMTP.setup.meta.description",
    image: Bitmap,
    bannerImage: EmailSMTP,
    emailService: "email.support",
    moreDescriptions: [
      {
        id: 1,
        description: "email.and.SMTP.setup.description",
      },
      {
        id: 2,
        description: "email.and.SMTP.setup.description1",
      },
      {
        id: 3,
        description: "email.and.SMTP.setup.heading",
      },
    ],
    importantConfigurePoints: [
      {
        id: 1,
        point: "email-and-SMT-setup.configration1",
      },
      {
        id: 2,
        point: "email-and-SMT-setup.configration2",
      },
      {
        id: 3,
        point: "email-and-SMT-setup.configration3",
      },
    ],
    paragraphsDetails: [
      {
        id: 1,
        description: "email-comunications-heading",
      },
      {
        id: 2,
        description: "email-comunications-para1",
      },
      {
        id: 3,
        description: "email-comunications-para2",
      },
      {
        id: 4,
        description: "email-comunications-para3",
      },
      {
        id: 5,
        description: "configure-Email-and-SMTP",
      },
    ],
    configurePoints: [
      {
        id: 1,
        img: EmailSMTPStep1,
        alt: "EmailSMTPStep1",
        description: [
          "configure-Email-and-SMTP-Hedaing1",
          "configure-Email-and-SMTP-Hedaing1-para1",
        ],
      },
      {
        id: 2,
        img: EmailSMTPStep2,
        alt: "EmailSMTPStep2",
        description: [
          "configure-Email-and-SMTP-Hedaing2",
          "configure-Email-and-SMTP-Hedaing2-para1",
        ],
      },
      {
        id: 3,
        img: EmailSMTPStep3,
        alt: "EmailSMTPStep3",
        description: [
          "configure-Email-and-SMTP-Hedaing3",
          "configure-Email-and-SMTP-Hedaing3-para1",
          "configure-Email-and-SMTP-Hedaing3-para2",
        ],
      },
    ],
    serviceProvider: {
      serviceProviderHeadings: [
        {
          id: 1,
          heading: "email-service-provider",
        },
      ],
      serviceProviderSubHeadings: [
        {
          id: 1,
          img: Gmail,
          heading: "email-service-provider-heading",
        },
      ],
      serviceProviderDescription: [
        {
          id: 1,
          heading: "email-service-provider-para1",
        },
        {
          id: 2,
          heading: "email-service-provider-para2",
        },
        {
          id: 3,
          heading: "email-service-provider-para3",
        },
        {
          id: 4,
          heading: "email-service-provider-para4",
        },
        {
          id: 5,
          heading: "email-service-provider-para5",
        },
      ],
    },
    postedOn: "7 jul 2023",
    timeToRead: "3 min",
  },
  {
    slug: "how-to-create-google-map-api-key",
    id: 14,
    metaDescription: "google.api.key.metadata",
    description: "google.api.key.description",
    title: "google.api.key.title",
    image: googleapikeylogo,
    bannerImage: googleapikeyBanner,
    moreDescriptions: [
      {
        id: 1,
        description: "google-api-key-question1-heading",
      },
      {
        id: 2,
        description: "google-api-key-answer1-para",
      },
      {
        id: 3,
        description: "google-api-key-answer1-para2",
      },
      {
        id: 4,
        description: "google-api-key-question2-heading",
      },
      {
        id: 5,
        description: "google-api-key-answer2-para",
      },
    ],

    postedOn: "5 Jul, 2023",
    timeToRead: "5 min",
  },
  {
    slug: "step-by-step-guide",
    id: 15,
    title: "step.by.step.guide",
    metaDescription: "step.by.step.guide.description",
    image: stepByStepGuidLogo,
    bannerImage: stepByStepGuidBanner,
    moreDescriptions: [
      {
        id: 1,
        description: "step-by-step-para1",
      },
      {
        id: 2,
        description: "step-by-step-para2",
      },
      {
        id: 3,
        description: "step-by-step-guide-step1-heading",
      },
      {
        id: 4,
        description: "step-by-step-guide-step1-para",
      },
      {
        id: 5,
        description: "step-by-step-guide-step1-link",
      },
      {
        id: 6,
        description: "step-by-step-guide-step2-heading",
      },
      {
        id: 7,
        description: "step-by-step-guide-step2.1",
      },
      {
        id: 8,
        description: "step-by-step-guide-step2.1-link",
      },
      {
        id: 9,
        description: "step-by-step-guide-step2.2",
      },
    ],
    guideParagraphs: [
      {
        id: 1,
        description: "step-by-step-guide-step2.5",
      },
      {
        id: 2,
        description: "step-by-step-guide-step2.5-link",
      },
      {
        id: 3,
        description: "step-by-step-guide-step3-heading",
      },
      {
        id: 4,
        description: "step-by-step-guide-step3-para",
      },
      {
        id: 5,
        description: "step-by-step-guide-step3-link",
      },
      {
        id: 6,
        description: "step-by-step-guide-step4-heading",
      },
      {
        id: 7,
        description: "step-by-step-guide-step4-para",
      },
      {
        id: 8,
        description: "step-by-step-guide-step5-heading",
      },
      {
        id: 9,
        description: "step-by-step-guide-step5-para",
      },
      {
        id: 10,
        description: "step-by-step-guide-step5-link",
      },
      {
        id: 11,
        description: "step-by-step-guide-step6-heading",
      },
      {
        id: 12,
        description: "step-by-step-guide-step6-para",
      },
      {
        id: 13,
        description: "step-by-step-guide-step7-heading",
      },
      {
        id: 14,
        description: "step-by-step-guide-step7-para",
      },
      {
        id: 15,
        description: "step-by-step-guide-step7.1",
      },
      {
        id: 16,
        description: "step-by-step-guide-step7.2",
      },
      {
        id: 17,
        description: "step-by-step-guide-step7-note",
      },
      {
        id: 18,
        description: "step-by-step-guide-step7.3",
      },
      {
        id: 19,
        description: "step-by-step-guide-step8-heading",
      },
      {
        id: 20,
        description: "step-by-step-guide-step8.1",
      },
      {
        id: 21,
        description: "step-by-step-guide-step8-para1",
      },
      {
        id: 22,
        description: "step-by-step-guide-step8.2.2",
      },
      {
        id: 23,
        description: "step-by-step-guide-step8.3",
      },
    ],
    bulletPointCategories: {
      stepsForChangingTheme: [
        {
          id: 1,
          description: "change-theme-step1",
        },
        {
          id: 2,
          description: "change-theme-step2",
        },
        {
          id: 3,
          description: "change-theme-step3",
        },
        {
          id: 4,
          description: "change-theme-step4",
        },
      ],
      stepsForPSColour: [
        {
          id: 1,
          description: "ps-colour-step1",
        },
        {
          id: 2,
          description: "ps-colour-step2",
        },
        {
          id: 3,
          description: "ps-colour-step3",
        },
        {
          id: 4,
          description: "ps-colour-step4",
        },
      ],
      stepsForCustomizingPage: [
        {
          id: 1,
          description: "customize-page-step1",
        },
        {
          id: 2,
          description: "customize-page-step2",
        },
        {
          id: 3,
          description: "customize-page-step3",
        },
        {
          id: 4,
          description: "customize-page-step4",
        },
        {
          id: 5,
          description: "customize-page-step5",
        },
        {
          id: 6,
          description: "customize-page-step6",
        },
        {
          id: 7,
          description: "customize-page-step7",
        },
        {
          id: 8,
          description: "customize-page-step8",
        },
      ],
      stepsToCreatePolicyPage: [
        {
          id: 1,
          description: "policy-page-step1",
        },
        {
          id: 2,
          description: "policy-page-step2",
        },
        {
          id: 3,
          description: "policy-page-step3",
        },
        {
          id: 4,
          description: "policy-page-step4",
        },
        {
          id: 5,
          description: "policy-page-step6",
        },
        {
          id: 7,
          description: "policy-page-step7",
        },
        {
          id: 8,
          description: "policy-page-step8",
        },
      ],
      stepsForPaymentSetup: [
        {
          id: 1,
          description: "payment-setup-step1",
        },
        {
          id: 2,
          description: "payment-setup-step2",
        },
        {
          id: 3,
          description: "payment-setup-step3",
        },
      ],
      shippingSupport: [
        {
          id: 1,
          description: "shipping-support1",
        },
        {
          id: 2,
          description: "shipping-support2",
        },
        {
          id: 3,
          description: "shipping-support3",
        },
      ],
      stepsForIntegratingLogistics: [
        {
          id: 1,
          description: "integrating-logistics-step1",
        },
        {
          id: 2,
          description: "integrating-logistics-step2",
        },
        {
          id: 3,
          description: "integrating-logistics-step3",
        },
        {
          id: 4,
          description: "integrating-logistics-step4",
        },
        {
          id: 5,
          description: "integrating-logistics-step5",
        },
        {
          id: 6,
          description: "integrating-logistics-step6",
        },
        {
          id: 7,
          description: "integrating-logistics-step7",
        },
        {
          id: 8,
          description: "integrating-logistics-step8",
        },
        {
          id: 9,
          description: "integrating-logistics-step9",
        },
      ],
      stepsForGoogleAnalytics: [
        {
          id: 1,
          description: "google-analytics-step1",
        },
        {
          id: 2,
          description: "google-analytics-step2",
        },
        {
          id: 3,
          description: "google-analytics-step3",
        },
        {
          id: 4,
          description: "google-analytics-step4",
        },
        {
          id: 5,
          description: "google-analytics-step5",
        },
        {
          id: 6,
          description: "google-analytics-step6",
        },
      ],
      stepsForFacebookAnalytics: [
        {
          id: 1,
          description: "facebook-analytics-step1",
        },
        {
          id: 2,
          description: "facebook-analytics-step2",
        },
        {
          id: 3,
          description: "facebook-analytics-step3",
        },
        {
          id: 4,
          description: "facebook-analytics-step4",
        },
        {
          id: 5,
          description: "facebook-analytics-step5",
        },
        {
          id: 6,
          description: "facebook-analytics-step6",
        },
      ],
      stepsForGoogleTagManager: [
        {
          id: 1,
          description: "google-tag-manager-step1",
        },
        {
          id: 2,
          description: "google-tag-manager-step2",
        },
        {
          id: 3,
          description: "google-tag-manager-step3",
        },
        {
          id: 4,
          description: "google-tag-manager-step4",
        },
      ],
    },
    postedOn: "9 Jan 2023",
    timeToRead: "11 min",
  },
  {
    slug: "razorpay-api-setup",
    id: 16,
    title: "razorpay.api.setup.title",
    description: "razorpay.api.setup.description",
    image: razorpayApiSetupLogo,
    bannerImage: razorpayApiSetupBanner,
    moreDescriptions: [
      {
        id: 1,
        description: "razorpay.api.setup.steps",
      },
      {
        id: 2,
        description: "razorpay.api.setup.description.para1",
      },
      {
        id: 3,
        description: "razorpay.api.setup.description.para2",
      },
      {
        id: 4,
        description: "razorpay.api.setup.description.para3",
      },
    ],
    importantConfigurePoints: [
      {
        id: 1,
        point: "razorpay.api.configure.point1",
      },
      {
        id: 2,
        point: "razorpay.api.configure.point2",
      },
      {
        id: 3,
        point: "razorpay.api.configure.point3",
      },
      {
        id: 4,
        point: "razorpay.api.configure.point4",
      },
      {
        id: 5,
        point: "razorpay.api.configure.point5",
      },
      {
        id: 6,
        point: "razorpay.api.configure.point6",
      },
    ],
    postedOn: "13 Oct, 2022",
    timeToRead: "3 min",
  },
  {
    slug: "payu-api-setup",
    id: 17,
    title: "payu.api.setup.title",
    description: "payu.api.setup.description",
    image: payUApiSetupLogo,
    bannerImage: payUApiSetupBanner,
    moreDescriptions: [
      {
        id: 1,
        description: "payu.api.setup.steps",
      },
      {
        id: 2,
        description: "payu.api.setup.description.para1",
      },
      {
        id: 3,
        description: "payu.api.setup.description.para2",
      },
      {
        id: 4,
        description: "payu.api.setup.description.para3",
      },
    ],
    importantConfigurePoints: [
      {
        id: 1,
        point: "payu.api.configure.point1",
      },
      {
        id: 2,
        point: "payu.api.configure.point2",
      },
      {
        id: 3,
        point: "payu.api.configure.point3",
      },
      {
        id: 4,
        point: "payu.api.configure.point4",
      },
      {
        id: 5,
        point: "payu.api.configure.point5",
      },
      {
        id: 6,
        point: "payu.api.configure.point6",
      },
    ],
    postedOn: "13 Oct, 2022",
    timeToRead: "3 min",
  },
  {
    slug: "paytm-api-setup",
    id: 18,
    title: "paytm.api.setup.title",
    description: "paytm.api.setup.description",
    image: paytmApiSetupLogo,
    bannerImage: paytmApiSetupBanner,
    ImageForContent: paytmApiSetupBanner,
    moreDescriptions: [
      {
        id: 1,
        description: "paytm.api.setup.steps",
      },
      {
        id: 2,
        description: "paytm.api.setup.description.para1",
      },
      {
        id: 3,
        description: "paytm.api.setup.description.para2",
      },
      {
        id: 4,
        description: "paytm.api.setup.description.para3",
      },
    ],
    importantConfigurePoints: [
      {
        id: 1,
        point: "paytm.api.configure.point1",
      },
      {
        id: 2,
        point: "paytm.api.configure.point2",
      },
      {
        id: 3,
        point: "paytm.api.configure.point3",
      },
      {
        id: 4,
        point: "paytm.api.configure.point4",
      },
      {
        id: 5,
        point: "paytm.api.configure.point5",
      },
      {
        id: 6,
        point: "paytm.api.configure.point6",
      },
    ],
    postedOn: "13 Oct, 2022",
    timeToRead: "3 min",
  },
  {
    slug: "how-to-accept-online-payments-from-customers-for-their-orders-on-zopping",
    id: 19,
    description:
      "how.to.accept.online.payments.from.customers.for.their.orders.on.zopping.description",
    moreDescriptions: [
      {
        id: 1,
        description:
          "how.to.accept.online.payments.from.customers.for.their.orders.on.zopping.description1",
      },
      {
        id: 2,
        description:
          "how.to.accept.online.payments.from.customers.for.their.orders.on.zopping.description2",
      },
      {
        id: 2,
        description:
          "how.to.accept.online.payments.from.customers.for.their.orders.on.zopping.description3",
      },
    ],
    title:
      "how.to.accept.online.payments.from.customers.for.their.orders.on.zopping.title",
    image: onlinePaymentImage,
    bannerImage: onlinePaymentSetupWallpaper,
    ImageForContent: onlinePaymentSetupDemoImage,
    ImageRelatedPoints: {
      items: [
        {
          id: 1,
          description:
            "how.to.accept.online.payments.from.customers.for.their.orders.on.zopping.step1",
        },
        {
          id: 2,
          description:
            "how.to.accept.online.payments.from.customers.for.their.orders.on.zopping.step2",
        },
        {
          id: 3,
          description:
            "how.to.accept.online.payments.from.customers.for.their.orders.on.zopping.step3",
        },
        {
          id: 4,
          description:
            "how.to.accept.online.payments.from.customers.for.their.orders.on.zopping.step4",
        },
        {
          id: 5,
          description:
            "how.to.accept.online.payments.from.customers.for.their.orders.on.zopping.step5",
        },
        {
          id: 6,
          description:
            "how.to.accept.online.payments.from.customers.for.their.orders.on.zopping.step6",
        },
        {
          id: 7,
          description:
            "how.to.accept.online.payments.from.customers.for.their.orders.on.zopping.step7",
        },
      ],
    },
    postedOn: "13 Oct, 2022",
    timeToRead: "3 min",
  },
  {
    slug: "custom-domain-setup",
    id: 20,
    title: "custom.domain.title",
    description: "custom.domain.description",
    moreDescriptions: [
      {
        id: 1,
        description: "custom.domain.more.descriptions.1",
      },
    ],
    image: customDomainSetup,
    bannerImage: customDomainSetupBanner,
    heading: "custom.domain.heading.title",
    paragraphs: [
      {
        id: 1,
        description: "custom.domain.para.1.description",
      },
    ],
    dotDescription: {
      items: [
        {
          id: 1,
          description: "custom.domain.point.1.description",
        },
        {
          id: 2,
          description: "custom.domain.point.2.description",
        },
        {
          id: 3,
          description: "custom.domain.point.3.description",
        },
      ],
    },
    postedOn: "26 July, 2022",
    timeToRead: "4 min",
  },
  {
    slug: "five-reasons-online-store",
    id: 21,
    title: "five.reasons.title",
    description: "five.reasons.description",
    image: fiveReasonsToHaveOnlineStore,
    bannerImage: fiveReasonsToHaveOnlineStoreBanner,
    list1: {
      description: "five.reasons.list1.description",
      items: [
        {
          id: 1,
          title: "five.reasons.list1.items.1.title",
          description: "five.reasons.list1.items.1.description",
        },
        {
          id: 2,
          title: "five.reasons.list1.items.2.title",
          description: "five.reasons.list1.items.2.description",
        },
        {
          id: 3,
          title: "five.reasons.list1.items.3.title",
          description: "five.reasons.list1.items.3.description",
        },
        {
          id: 4,
          title: "five.reasons.list1.items.4.title",
          description: "five.reasons.list1.items.4.description",
        },
        {
          id: 5,
          title: "five.reasons.list1.items.5.title",
          description: "five.reasons.list1.items.5.description",
        },
      ],
    },
    paragraphs: [
      {
        id: 1,
        description: "five.reasons.paragraph.one",
      },
      {
        id: 2,
        description: "five.reasons.paragraph.two",
      },
    ],
    postedOn: "14 Jan, 2022",
    timeToRead: "3 min",
  },
  {
    slug: "top-five-features",
    id: 22,
    title: "top.five.features.title",
    description: "top.five.features.description",
    image: topSevenFeature,
    bannerImage: topSevenFeatureBanner,
    list1: {
      description: "",
      items: [
        {
          id: 1,
          title: "top.five.features.list1.items.1.title",
          description: "top.five.features.list1.items.1.description",
        },
        {
          id: 2,
          title: "top.five.features.list1.items.2.title",
          description: "top.five.features.list1.items.2.description",
        },
        {
          id: 3,
          title: "top.five.features.list1.items.3.title",
          description: "top.five.features.list1.items.3.description",
        },
        {
          id: 4,
          title: "top.five.features.list1.items.4.title",
          description: "top.five.features.list1.items.4.description",
        },
        {
          id: 5,
          title: "top.five.features.list1.items.5.title",
          description: "top.five.features.list1.items.5.description",
        },
      ],
    },
    postedOn: "18 Jan, 2022",
    timeToRead: "4 min",
  },
  {
    slug: "how-to-setup",
    id: 23,
    description: "how.to.setup.description",
    moreDescriptions: [
      {
        id: 1,
        description: "how.to.setup.more.descriptions.1",
      },
    ],
    title: "how.to.setup.title",
    image: setUpOnlineStore,
    bannerImage: setUpOnlineStoreBanner,
    list1: {
      description: "",
      items: [
        {
          id: 1,
          title: "how.to.setup.list1.items.1.title",
          description: "how.to.setup.list1.items.1.description",
        },
        {
          id: 2,
          title: "how.to.setup.list1.items.2.title",
          description: "how.to.setup.list1.items.2.description",
        },
        {
          id: 3,
          title: "how.to.setup.list1.items.3.title",
          description: "how.to.setup.list1.items.3.description",
        },
        {
          id: 4,
          title: "how.to.setup.list1.items.4.title",
          description: "how.to.setup.list1.items.4.description",
        },
        {
          id: 5,
          title: "how.to.setup.list1.items.5.title",
          description: "how.to.setup.list1.items.5.description",
        },
      ],
    },
    paragraphs: [
      {
        id: 1,
        description: "how.to.setup.para.1.description",
      },
      { id: 2, description: "how.to.setup.para.2.description" },
    ],
    postedOn: "21 Jan, 2022",
    timeToRead: "4 min",
  },
  {
    slug: "how-to-add-products-to-your-online-store",
    id: 24,
    title: "add.product.title",
    description: "add.product.description",
    moreDescriptions: [
      {
        id: 1,
        description: "add.product.more.descriptions.1",
      },
      {
        id: 2,
        description: "add.product.more.descriptions.2",
      },
    ],
    image: addProductInStore,
    bannerImage: addProductInStoreBanner,
    heading: "add.product.heading.title",
    ImageForContent: addProductToEcommerceStore,
    ImageRelatedPoints: {
      items: [
        {
          id: 1,
          description: "add.product.imgcontent.one",
        },
        {
          id: 2,
          description: "add.product.imgcontent.two",
        },
        {
          id: 3,
          description: "add.product.imgcontent.three",
        },
      ],
    },
    paragraphs: [
      {
        id: 1,
        description: "add.product.para.1.description",
      },
    ],
    MoreHeading: {
      items: [
        {
          id: 1,
          description: "add.product.more.heading.title",
        },
      ],
    },
    AdditionalParagraph: {
      items: [
        {
          id: 1,
          description: "add.product.additional.para",
        },
      ],
    },
    postedOn: "1 Jun, 2022",
    timeToRead: "11 min",
  },
  {
    slug: "how-to-customize-footer",
    id: 25,
    description: "how.to.customize.footer.description",
    moreDescriptions: [
      {
        id: 1,
        description: "how.to.customize.footer.more.descriptions.1",
      },
    ],
    ImageForContent: footerImg,
    title: "how.to.customize.footer.title",
    image: customizeFooter,
    bannerImage: customizeFooterBanner,
    paragraphs: [
      {
        id: 1,
        description: "how.to.customize.para.1.description",
      },
      { id: 2, description: "how.to.customize.para.2.description" },
      { id: 3, description: "how.to.customize.para.3.description" },
      { id: 4, description: "how.to.customize.para.4.description" },
      { id: 5, description: "how.to.customize.para.5.description" },
    ],
    postedOn: "7 Jun, 2022",
    timeToRead: "2 min",
  },
  {
    slug: "how-to-add-logo-and-favicon-to-your-online-store",
    id: 26,
    title: "how.to.add.logo.favicon.title",
    description: "how.to.add.logo.favicon.description",
    image: addLogoAndFavicon,
    bannerImage: addLogoAndFaviconBanner,
    heading: "how.to.add.logo.favicon.heading.title",
    paragraphs: [
      {
        id: 1,
        description: "how.to.add.logo.favicon.para.1.description",
      },
    ],
    MoreHeading: {
      items: [
        {
          id: 1,
          description: "how.to.add.logo.favicon.more.heading.title",
        },
      ],
    },
    ImageForContent: addLogoFaviconImgForContent,
    ImageRelatedPoints: {
      items: [
        {
          id: 1,
          description: "how.to.add.logo.favicon.imgcontent.one",
        },
        {
          id: 2,
          description: "how.to.add.logo.favicon.imgcontent.two",
        },
        {
          id: 3,
          description: "how.to.add.logo.favicon.imgcontent.three",
        },
        {
          id: 4,
          description: "how.to.add.logo.favicon.imgcontent.four",
        },
        {
          id: 5,
          description: "how.to.add.logo.favicon.imgcontent.five",
        },
      ],
    },
    postedOn: "9 Jun, 2022",
    timeToRead: "2 min",
  },
  {
    slug: "add-products-faqs",
    id: 27,
    ImageForContent: exploreFaqsImg,
    title: "add.products.faqs.title",
    image: addProductsFaqs,
    bannerImage: addProductsFaqsBanner,
    postedOn: "9 Jun, 2022",
    timeToRead: "2 min ",
  },
];

const SingleBlog = ({ blog, location }) => {
  const {
    title,
    description,
    postedOn,
    timeToRead,
    image,
    slug,
    metaDescription,
  } = blog;

  const updatedLocation = location
    .split("/")
    .filter((item) => item)
    .join("/");
  return (
    <div className="single-blog-container-wrapper">
      <a className="single-blog-container" href={`/${updatedLocation}/${slug}`}>
        <div className="single-blog-container__text-area-outer">
          <div className="single-blog-container__text-area">
            <span className="blog-title">
              <Message dictKey={title} />
            </span>
            <div className="blog-description">
              <Message dictKey={description || metaDescription} />
            </div>
            {blog.slug === "add-products-faqs" && (
              <div className="blog-description">
                <Message
                  dictKey={"add.products.faqs.question.one.para.description"}
                />
                <Message
                  dictKey={"add.products.faqs.answer.short.para.description"}
                />
              </div>
            )}
            <p className="blog-information">
              <Message dictKey="posted.on" />: {postedOn} . {timeToRead}
              <Message dictKey="read.text" />
            </p>
          </div>
          <hr className="eazy-blog__line" />
        </div>
        <img
          src={image}
          alt="blog-img"
          className="single-blog-container__image-area"
        />
      </a>
    </div>
  );
};

const Blog = (props) => {
  const { language, location, updateLanguage, pageContext, direction } = props;
  const sendBlogId =
    blogs.find((blog) => blog.slug === pageContext.slug)?.id || null;
  let canonicalUrl = `https://staging.zopping.com/blog`;
  if (typeof window !== "undefined") {
    if (window.location.host === "zopping.com") {
      canonicalUrl = `https://zopping.com/blog`;
    }
  }
  const isDirectionRTL = direction === "rtl";
  return (
    <>
      {!sendBlogId ? (
        <LayoutWrapper language={language} location={location}>
          <div className="eazy-page">
            <Helmet title="Zopping - Blog">
              <meta property="og:title" content="Zopping Blog" />
              <meta
                name="description"
                content="Explore our detailed guides that will help you build your online store using Zopping
"
              />
              <link rel="canonical" href={canonicalUrl} />
            </Helmet>
            <EazyHeader
              language={language}
              updateLanguage={updateLanguage}
              current="blog"
              isDirectionRTL={isDirectionRTL}
            />
            <div className="eazy-blog-main-container">
              <div className="eazy-blog">
                <header className="eazy-blog__heading">
                  <Message dictKey="blog.text" />
                </header>
                <div className="eazy-blog-line-box">
                  <hr className="eazy-blog__line-top-section" />
                </div>
                <div
                  className={
                    blogs.length > 0 ? "eazy-blog-outer-container" : ""
                  }
                >
                  {blogs.map((blog) => (
                    <SingleBlog
                      blog={blog}
                      key={blog.slug}
                      location={location.pathname}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </LayoutWrapper>
      ) : (
        <DetailedBlog
          blog={blogs[sendBlogId - 1]}
          furtherReadingBlogs={[
            blogs[sendBlogId % blogs.length],
            blogs[(sendBlogId + 1) % blogs.length],
          ]}
          {...props}
        />
      )}
    </>
  );
};

export default WithProviders(Blog);
