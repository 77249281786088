import React from "react";

function AddProductSellingPrice(props) {
  return (
    <svg
      width="344.6px"
      height="280px"
      viewBox="0 0 542 289"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <filter
          x="-19.6%"
          y="-66.2%"
          width="139.2%"
          height="232.5%"
          filterUnits="objectBoundingBox"
          id="filter-1"
        >
          <feGaussianBlur
            stdDeviation="25.168952"
            in="SourceGraphic"
          ></feGaussianBlur>
        </filter>
      </defs>
      <g id="Blog" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Selling-price" transform="translate(77.352508, 9.042523)">
          <g
            id="Group-189-Copy-2"
            transform="translate(22.927925, 0.000000)"
            fill="#E9F0F4"
          >
            <ellipse
              id="Oval-Copy"
              transform="translate(66.061776, 213.800641) rotate(11.000000) translate(-66.061776, -213.800641) "
              cx="66.0617765"
              cy="213.800641"
              rx="56.3306789"
              ry="56.4231828"
            ></ellipse>
            <ellipse
              id="Oval"
              transform="translate(215.961362, 128.379571) rotate(11.000000) translate(-215.961362, -128.379571) "
              cx="215.961362"
              cy="128.379571"
              rx="109.347788"
              ry="109.527355"
            ></ellipse>
          </g>
          <g
            id="Group-36-Copy-2"
            transform="translate(193.647492, 127.292098) rotate(-1.000000) translate(-193.647492, -127.292098) translate(0.988737, 68.957477)"
          >
            <g id="Group-23">
              <rect
                id="Rectangle-Copy-17"
                fill="#000000"
                opacity="0.057274"
                filter="url(#filter-1)"
                x="0"
                y="2.66924043"
                width="385.31751"
                height="114"
                rx="10.0675812"
              ></rect>
              <rect
                id="Rectangle-Copy-15"
                fill="#FFFFFF"
                x="16.6587551"
                y="0"
                width="353"
                height="103"
                rx="10.0675812"
              ></rect>
            </g>
            <text
              id="Enter-barcode"
              fontFamily="OpenSans-Regular, Open Sans"
              fontSize="12"
              fontWeight="normal"
              fill="#B8B8B5"
            >
              <tspan x="50.6587551" y="58">
                Enter barcode
              </tspan>
            </text>
            <path
              d="M349.658755,47.5 C350.625253,47.5 351.500253,47.8917508 352.133629,48.5251263 C352.767004,49.1585017 353.158755,50.0335017 353.158755,51 L353.158755,51 L353.158755,83 C353.158755,83.9664983 352.767004,84.8414983 352.133629,85.4748737 C351.500253,86.1082492 350.625253,86.5 349.658755,86.5 L349.658755,86.5 L36.6587551,86.5 C35.6922568,86.5 34.8172568,86.1082492 34.1838814,85.4748737 C33.550506,84.8414983 33.1587551,83.9664983 33.1587551,83 L33.1587551,83 L33.1587551,51 C33.1587551,50.0335017 33.550506,49.1585017 34.1838814,48.5251263 C34.8172568,47.8917508 35.6922568,47.5 36.6587551,47.5 L36.6587551,47.5 Z"
              id="Rectangle-7-Copy-22"
              stroke="#4AB819"
              fill="#FFFFFF"
            ></path>
            <text
              id="Enter-original-Price"
              fontFamily="OpenSans-Regular, Open Sans"
              fontSize="12"
              fontWeight="normal"
              fill="#B8B8B5"
            >
              <tspan x="46.6396207" y="71.1567106">
                Enter original Price (Ex: 
              </tspan>
              <tspan x="178.088839" y="71.1567106" fontFamily="Helvetica">
                ₹
              </tspan>
              <tspan x="184.323214" y="71.1567106">
                25)
              </tspan>
            </text>
            <text
              id="Selling-price"
              fontFamily="OpenSans-SemiBold, Open Sans"
              fontSize="11"
              fontWeight="500"
              letterSpacing="-0.146666663"
              fill="#181912"
            >
              <tspan x="32.6587551" y="28">
                SELLING PRICE
              </tspan>
            </text>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default AddProductSellingPrice;
