import React from "react";

function AddProductVariantQuantity(props) {
  return (
    <svg
      width="344.6px"
      height="280px"
      viewBox="0 0 542 289"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <filter
          x="-19.6%"
          y="-66.2%"
          width="139.2%"
          height="232.5%"
          filterUnits="objectBoundingBox"
          id="filter-1"
        >
          <feGaussianBlur
            stdDeviation="25.168952"
            in="SourceGraphic"
          ></feGaussianBlur>
        </filter>
      </defs>
      <g id="Blog" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Variant-Quantity" transform="translate(77.352508, 9.042523)">
          <g
            id="Group-189-Copy-4"
            transform="translate(22.927925, 0.000000)"
            fill="#E9F0F4"
          >
            <ellipse
              id="Oval-Copy"
              transform="translate(66.061776, 213.800641) rotate(11.000000) translate(-66.061776, -213.800641) "
              cx="66.0617765"
              cy="213.800641"
              rx="56.3306789"
              ry="56.4231828"
            ></ellipse>
            <ellipse
              id="Oval"
              transform="translate(215.961362, 128.379571) rotate(11.000000) translate(-215.961362, -128.379571) "
              cx="215.961362"
              cy="128.379571"
              rx="109.347788"
              ry="109.527355"
            ></ellipse>
          </g>
          <g
            id="Group-36-Copy-4"
            transform="translate(193.647492, 127.292098) rotate(-1.000000) translate(-193.647492, -127.292098) translate(0.988737, 68.957477)"
          >
            <g id="Group-23" transform="translate(0.000000, 0.000000)">
              <rect
                id="Rectangle-Copy-17"
                fill="#000000"
                opacity="0.057274"
                filter="url(#filter-1)"
                x="-1.8189894e-12"
                y="2.66924043"
                width="385.31751"
                height="114"
                rx="10.0675812"
              ></rect>
              <rect
                id="Rectangle-Copy-15"
                fill="#FFFFFF"
                x="16.6587551"
                y="-7.27595761e-12"
                width="353"
                height="103"
                rx="10.0675812"
              ></rect>
              <g id="Group-20" transform="translate(56.283257, 21.361343)">
                <g id="Group-25" transform="translate(28.000000, 20.000000)">
                  <path
                    d="M107,0.5 C107.966498,0.5 108.841498,0.891750844 109.474874,1.52512627 C110.108249,2.15850169 110.5,3.03350169 110.5,4 L110.5,4 L110.5,36 C110.5,36.9664983 110.108249,37.8414983 109.474874,38.4748737 C108.841498,39.1082492 107.966498,39.5 107,39.5 L107,39.5 L4,39.5 C3.03350169,39.5 2.15850169,39.1082492 1.52512627,38.4748737 C0.891750844,37.8414983 0.5,36.9664983 0.5,36 L0.5,36 L0.5,4 C0.5,3.03350169 0.891750844,2.15850169 1.52512627,1.52512627 C2.15850169,0.891750844 3.03350169,0.5 4,0.5 L4,0.5 L107,0.5 Z"
                    id="Rectangle-7-Copy-25"
                    stroke="#DADEE0"
                    fill="#FFFFFF"
                  ></path>
                  <text
                    id="100-copy-3"
                    fontFamily="OpenSans-Regular, Open Sans"
                    fontSize="12"
                    fontWeight="normal"
                    fill="#181912"
                  >
                    <tspan x="15" y="25">
                      100
                    </tspan>
                  </text>
                </g>
                <text
                  id="Variant-Quantity"
                  fontFamily="OpenSans-SemiBold, Open Sans"
                  fontSize="11"
                  fontWeight="500"
                  letterSpacing="-0.146666663"
                  fill="#181912"
                >
                  <tspan x="0" y="12">
                    VARIANT QUANTITY
                  </tspan>
                </text>
                <text
                  id="Unlimited-Copy-3"
                  fontFamily="OpenSans-Regular, Open Sans"
                  fontSize="12"
                  fontWeight="normal"
                  fill="#181912"
                >
                  <tspan x="217" y="45">
                    Unlimited
                  </tspan>
                </text>
                <g
                  id="Group-20-Copy-2"
                  transform="translate(0.000000, 28.000000)"
                >
                  <rect
                    id="Rectangle-29-Copy-3"
                    stroke="#979797"
                    fill="#D8D8D8"
                    opacity="0"
                    x="0.5"
                    y="0.5"
                    width="23"
                    height="23"
                  ></rect>
                  <rect
                    id="Rectangle-2-Copy-4"
                    stroke="#DADEE0"
                    fill="#FFFFFF"
                    x="2.5"
                    y="2.5"
                    width="19"
                    height="19"
                    rx="9.5"
                  ></rect>
                </g>
                <g id="Group-7" transform="translate(189.000000, 28.000000)">
                  <rect
                    id="Rectangle-29-Copy-4"
                    stroke="#979797"
                    fill="#D8D8D8"
                    opacity="0"
                    x="0.5"
                    y="0.5"
                    width="23"
                    height="23"
                  ></rect>
                  <rect
                    id="Rectangle-2-Copy-5"
                    stroke="#4AB819"
                    fill="#FFFFFF"
                    x="2.5"
                    y="2.5"
                    width="19"
                    height="19"
                    rx="9.5"
                  ></rect>
                  <rect
                    id="Rectangle-2-Copy-6"
                    fill="#4AB819"
                    x="7"
                    y="7"
                    width="10"
                    height="10"
                    rx="5"
                  ></rect>
                </g>
                <path
                  d="M103,21 L135,21 C136.656854,21 138,22.3431458 138,24 L138,56 C138,57.6568542 136.656854,59 135,59 L103,59 L103,59 L103,21 Z"
                  id="Rectangle-7-Copy-16"
                  fill="#FAFAFA"
                ></path>
                <text
                  id="Units-Copy-3"
                  fontFamily="OpenSans-Regular, Open Sans"
                  fontSize="11"
                  fontWeight="normal"
                  fill="#181912"
                >
                  <tspan x="107" y="45">
                    Units
                  </tspan>
                </text>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default AddProductVariantQuantity;
