import React from "react";

function AddProductTax(props) {
  return (
    <svg
      width="344.6px"
      height="280px"
      viewBox="0 0 572 347"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <filter
          x="-19.6%"
          y="-66.2%"
          width="139.2%"
          height="232.5%"
          filterUnits="objectBoundingBox"
          id="filter-1"
        >
          <feGaussianBlur
            stdDeviation="25.168952"
            in="SourceGraphic"
          ></feGaussianBlur>
        </filter>
      </defs>
      <g id="Blog" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Tax" transform="translate(86.587649, 45.042523)">
          <g
            id="Group-189-Copy-3"
            transform="translate(28.692785, 0.000000)"
            fill="#E9F0F4"
          >
            <ellipse
              id="Oval-Copy"
              transform="translate(66.061776, 213.800641) rotate(11.000000) translate(-66.061776, -213.800641) "
              cx="66.0617765"
              cy="213.800641"
              rx="56.3306789"
              ry="56.4231828"
            ></ellipse>
            <ellipse
              id="Oval"
              transform="translate(215.961362, 128.379571) rotate(11.000000) translate(-215.961362, -128.379571) "
              cx="215.961362"
              cy="128.379571"
              rx="109.347788"
              ry="109.527355"
            ></ellipse>
          </g>
          <g
            id="Group-36-Copy-3"
            transform="translate(199.412351, 127.292098) rotate(9.000000) translate(-199.412351, -127.292098) translate(6.753596, 68.957477)"
          >
            <g id="Group-23" transform="translate(0.000000, -0.000000)">
              <rect
                id="Rectangle-Copy-17"
                fill="#000000"
                opacity="0.057274"
                filter="url(#filter-1)"
                x="0"
                y="2.66924043"
                width="385.31751"
                height="114"
                rx="10.0675812"
              ></rect>
              <rect
                id="Rectangle-Copy-15"
                fill="#FFFFFF"
                x="16.6587551"
                y="0"
                width="353"
                height="103"
                rx="10.0675812"
              ></rect>
            </g>
            <g id="Group-12" transform="translate(44.804977, 22.690589)">
              <path
                d="M56,21.5 C56.9664983,21.5 57.8414983,21.8917508 58.4748737,22.5251263 C59.1082492,23.1585017 59.5,24.0335017 59.5,25 L59.5,25 L59.5,57 C59.5,57.9664983 59.1082492,58.8414983 58.4748737,59.4748737 C57.8414983,60.1082492 56.9664983,60.5 56,60.5 L56,60.5 L4,60.5 C3.03350169,60.5 2.15850169,60.1082492 1.52512627,59.4748737 C0.891750844,58.8414983 0.5,57.9664983 0.5,57 L0.5,57 L0.5,25 C0.5,24.0335017 0.891750844,23.1585017 1.52512627,22.5251263 C2.15850169,21.8917508 3.03350169,21.5 4,21.5 L4,21.5 Z"
                id="Rectangle-7-Copy-12"
                stroke="#DADEE0"
                fill="#FFFFFF"
              ></path>
              <path
                d="M40,22 L56,22 C57.6568542,22 59,23.3431458 59,25 L59,57 C59,58.6568542 57.6568542,60 56,60 L40,60 L40,60 L40,22 Z"
                id="Rectangle-7-Copy-12"
                fill="#FAFAFA"
              ></path>
              <text
                id="100"
                fontFamily="OpenSans-Regular, Open Sans"
                fontSize="12"
                fontWeight="normal"
                fill="#B8B8B5"
              >
                <tspan x="15" y="45">
                  100
                </tspan>
              </text>
              <text
                id="%-copy"
                fontFamily="OpenSans-Regular, Open Sans"
                fontSize="12"
                fontWeight="normal"
                fill="#181912"
              >
                <tspan x="44" y="45">
                  %
                </tspan>
              </text>
              <path
                d="M134,21.5 C134.966498,21.5 135.841498,21.8917508 136.474874,22.5251263 C137.108249,23.1585017 137.5,24.0335017 137.5,25 L137.5,25 L137.5,57 C137.5,57.9664983 137.108249,58.8414983 136.474874,59.4748737 C135.841498,60.1082492 134.966498,60.5 134,60.5 L134,60.5 L82,60.5 C81.0335017,60.5 80.1585017,60.1082492 79.5251263,59.4748737 C78.8917508,58.8414983 78.5,57.9664983 78.5,57 L78.5,57 L78.5,25 C78.5,24.0335017 78.8917508,23.1585017 79.5251263,22.5251263 C80.1585017,21.8917508 81.0335017,21.5 82,21.5 L82,21.5 Z"
                id="Rectangle-7-Copy-12"
                stroke="#DADEE0"
                fill="#FFFFFF"
              ></path>
              <path
                d="M118,22 L134,22 C135.656854,22 137,23.3431458 137,25 L137,57 C137,58.6568542 135.656854,60 134,60 L118,60 L118,60 L118,22 Z"
                id="Rectangle-7-Copy-12"
                fill="#FAFAFA"
              ></path>
              <text
                id="100"
                fontFamily="OpenSans-Regular, Open Sans"
                fontSize="12"
                fontWeight="normal"
                fill="#B8B8B5"
              >
                <tspan x="93" y="45">
                  100
                </tspan>
              </text>
              <text
                id="%-copy"
                fontFamily="OpenSans-Regular, Open Sans"
                fontSize="12"
                fontWeight="normal"
                fill="#181912"
              >
                <tspan x="122" y="45">
                  %
                </tspan>
              </text>
              <path
                d="M212,21.5 C212.966498,21.5 213.841498,21.8917508 214.474874,22.5251263 C215.108249,23.1585017 215.5,24.0335017 215.5,25 L215.5,25 L215.5,57 C215.5,57.9664983 215.108249,58.8414983 214.474874,59.4748737 C213.841498,60.1082492 212.966498,60.5 212,60.5 L212,60.5 L160,60.5 C159.033502,60.5 158.158502,60.1082492 157.525126,59.4748737 C156.891751,58.8414983 156.5,57.9664983 156.5,57 L156.5,57 L156.5,25 C156.5,24.0335017 156.891751,23.1585017 157.525126,22.5251263 C158.158502,21.8917508 159.033502,21.5 160,21.5 L160,21.5 Z"
                id="Rectangle-7-Copy-12"
                stroke="#DADEE0"
                fill="#FFFFFF"
              ></path>
              <path
                d="M196,22 L212,22 C213.656854,22 215,23.3431458 215,25 L215,57 C215,58.6568542 213.656854,60 212,60 L196,60 L196,60 L196,22 Z"
                id="Rectangle-7-Copy-12"
                fill="#FAFAFA"
              ></path>
              <text
                id="100"
                fontFamily="OpenSans-Regular, Open Sans"
                fontSize="12"
                fontWeight="normal"
                fill="#B8B8B5"
              >
                <tspan x="171" y="45">
                  100
                </tspan>
              </text>
              <text
                id="%-copy"
                fontFamily="OpenSans-Regular, Open Sans"
                fontSize="12"
                fontWeight="normal"
                fill="#181912"
              >
                <tspan x="200" y="45">
                  %
                </tspan>
              </text>
              <path
                d="M290,21.5 C290.966498,21.5 291.841498,21.8917508 292.474874,22.5251263 C293.108249,23.1585017 293.5,24.0335017 293.5,25 L293.5,25 L293.5,57 C293.5,57.9664983 293.108249,58.8414983 292.474874,59.4748737 C291.841498,60.1082492 290.966498,60.5 290,60.5 L290,60.5 L238,60.5 C237.033502,60.5 236.158502,60.1082492 235.525126,59.4748737 C234.891751,58.8414983 234.5,57.9664983 234.5,57 L234.5,57 L234.5,25 C234.5,24.0335017 234.891751,23.1585017 235.525126,22.5251263 C236.158502,21.8917508 237.033502,21.5 238,21.5 L238,21.5 Z"
                id="Rectangle-7-Copy-12"
                stroke="#DADEE0"
                fill="#FFFFFF"
              ></path>
              <path
                d="M274,22 L290,22 C291.656854,22 293,23.3431458 293,25 L293,57 C293,58.6568542 291.656854,60 290,60 L274,60 L274,60 L274,22 Z"
                id="Rectangle-7-Copy-12"
                fill="#FAFAFA"
              ></path>
              <text
                id="100"
                fontFamily="Cabin-Regular, Cabin"
                fontSize="12"
                fontWeight="normal"
                fill="#B8B8B5"
              >
                <tspan x="249" y="45">
                  100
                </tspan>
              </text>
              <text
                id="%-copy"
                fontFamily="OpenSans-Regular, Open Sans"
                fontSize="12"
                fontWeight="normal"
                fill="#181912"
              >
                <tspan x="278" y="45">
                  %
                </tspan>
              </text>
              <text
                id="CGST"
                fontFamily="OpenSans-Regular, Open Sans"
                fontSize="12"
                fontWeight="normal"
                fill="#181912"
              >
                <tspan x="0" y="13">
                  CGST
                </tspan>
              </text>
              <text
                id="SGST"
                fontFamily="OpenSans-Regular, Open Sans"
                fontSize="12"
                fontWeight="normal"
                fill="#181912"
              >
                <tspan x="78" y="13">
                  SGST
                </tspan>
              </text>
              <text
                id="IGST"
                fontFamily="OpenSans-Regular, Open Sans"
                fontSize="12"
                fontWeight="normal"
                fill="#181912"
              >
                <tspan x="156" y="13">
                  IGST
                </tspan>
              </text>
              <text
                id="CESS"
                fontFamily="OpenSans-Regular, Open Sans"
                fontSize="12"
                fontWeight="normal"
                fill="#181912"
              >
                <tspan x="234" y="13">
                  CESS
                </tspan>
              </text>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default AddProductTax;
