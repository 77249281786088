import React from "react";

function AddProductBarcode(props) {
  return (
    <svg
      width="344.6px"
      height="280px"
      viewBox="0 0 554 303"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <filter
          x="-19.6%"
          y="-66.2%"
          width="139.2%"
          height="232.5%"
          filterUnits="objectBoundingBox"
          id="filter-1"
        >
          <feGaussianBlur
            stdDeviation="25.168952"
            in="SourceGraphic"
          ></feGaussianBlur>
        </filter>
      </defs>
      <g id="Blog" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Barcode" transform="translate(80.741335, 23.042523)">
          <ellipse
            id="Oval-Copy"
            fill="#E9F0F4"
            transform="translate(95.200786, 213.800641) rotate(11.000000) translate(-95.200786, -213.800641) "
            cx="95.2007858"
            cy="213.800641"
            rx="56.3306789"
            ry="56.4231828"
          ></ellipse>
          <ellipse
            id="Oval"
            fill="#E9F0F4"
            transform="translate(245.100372, 128.379571) rotate(11.000000) translate(-245.100372, -128.379571) "
            cx="245.100372"
            cy="128.379571"
            rx="109.347788"
            ry="109.527355"
          ></ellipse>
          <rect
            id="Rectangle-Copy-17"
            fill="#000000"
            opacity="0.057274"
            filter="url(#filter-1)"
            transform="translate(196.165567, 128.326372) rotate(4.000000) translate(-196.165567, -128.326372) "
            x="3.50681196"
            y="71.3263715"
            width="385.31751"
            height="114"
            rx="10.0675812"
          ></rect>
          <rect
            id="Rectangle-Copy-15"
            fill="#FFFFFF"
            transform="translate(197.234207, 120.211909) rotate(4.000000) translate(-197.234207, -120.211909) "
            x="20.7342065"
            y="68.7119092"
            width="353"
            height="103"
            rx="10.0675812"
          ></rect>
          <text
            id="Enter-barcode"
            transform="translate(94.844378, 115.056999) rotate(4.000000) translate(-94.844378, -115.056999) "
            fontFamily="OpenSans-Regular, Open Sans"
            fontSize="12"
            fontWeight="normal"
            fill="#B8B8B5"
          >
            <tspan x="54.8443784" y="119.556999">
              Enter barcode
            </tspan>
          </text>
          <path
            d="M352.652981,116.174152 C353.619479,116.174152 354.494479,116.565903 355.127855,117.199278 C355.76123,117.832654 356.152981,118.707654 356.152981,119.674152 L356.152981,119.674152 L356.152981,151.674152 C356.152981,152.64065 355.76123,153.51565 355.127855,154.149026 C354.494479,154.782401 353.619479,155.174152 352.652981,155.174152 L352.652981,155.174152 L39.6529812,155.174152 C38.6864829,155.174152 37.8114829,154.782401 37.1781074,154.149026 C36.544732,153.51565 36.1529812,152.64065 36.1529812,151.674152 L36.1529812,151.674152 L36.1529812,119.674152 C36.1529812,118.707654 36.544732,117.832654 37.1781074,117.199278 C37.8114829,116.565903 38.6864829,116.174152 39.6529812,116.174152 L39.6529812,116.174152 Z"
            id="Rectangle-7-Copy-22"
            stroke="#4AB819"
            fill="#FFFFFF"
            transform="translate(196.152981, 135.674152) rotate(4.000000) translate(-196.152981, -135.674152) "
          ></path>
          <text
            id="Enter-barcode"
            transform="translate(90.754158, 128.227031) rotate(4.000000) translate(-90.754158, -128.227031) "
            fontFamily="OpenSans-Regular, Open Sans"
            fontSize="12"
            fontWeight="normal"
            fill="#B8B8B5"
          >
            <tspan x="50.7541575" y="132.727031">
              Enter barcode
            </tspan>
          </text>
          <text
            transform="translate(64.516241, 82.862992) rotate(4.000000) translate(-64.516241, -82.862992) "
            fontFamily="OpenSans-SemiBold, Open Sans"
            fontSize="11"
            fontWeight="500"
            letterSpacing="-0.146666663"
            fill="#181912"
          >
            <tspan x="39.016241" y="87.3629918">
              BARCODE
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  );
}

export default AddProductBarcode;
